import { Injectable } from '@angular/core';

import { ConsoleService } from "../console/console-service";

@Injectable({
    providedIn: 'root',
})
export class FileService {

    downloading: boolean = false;
    opening: boolean = false;

	constructor(
        public consoleService: ConsoleService
    ) {
        this.consoleService.log("FileService", "constructor");
    }

    public open(file: any, ftype?: string): void {
    }

    public download(file: any): void {
    }

    public downloadsafe(file: any): void {
    }

    public openEmailComposerWithAttachment(filePath?: string, file?: any) {
    }

    public selectProfilePhoto(): any {
    }

    public uploadAvatar(file_uris) {
    }
}
