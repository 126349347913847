export class StorageUtils {

    static getItem(itemName: string): any {
        let itemValue: any = localStorage.getItem(itemName);
		if (!itemValue) {
			itemValue = null;
		}
		return itemValue;
    }

    static setItem(itemName: string, itemValue: any): void {
        localStorage.setItem(itemName, itemValue);
    }

    static removeItem(itemName: string): void {
        localStorage.removeItem(itemName);
    }
}
