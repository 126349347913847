import { NgModule, ErrorHandler } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { BrowserModule } from '@angular/platform-browser';
import { RouteReuseStrategy } from '@angular/router';
import { HttpClient, HttpClientModule, HTTP_INTERCEPTORS, HttpHeaders  } from '@angular/common/http';
//import { HttpModule, Response, Http, XHRBackend, RequestOptions} from '@angular/http';

import { IonApp, IonicModule, IonicRouteStrategy, IonNav } from '@ionic/angular';
//import { LongPressModule } from 'ionic-long-press';

import { AppComponent } from './app.component';
import { AppRoutingModule } from './app-routing.module';

// IMPORT CLASSES FROM PAGES

import {ListActionsPage} from './pages/list-actions/list-actions';
import {ListMyDocsPage} from './pages/list-mydocs/list-mydocs';
import {ListDocumentsPage} from './pages/list-documents/list-documents';
import {HomePage} from './pages/home/home';
import {MenuPage} from './pages/menu/menu';
import {LoginPage} from './pages/login/login';
import {InfoPage} from './pages/info/info';
import {ActionPage} from './pages/action/action';
import {ChronologicalschemePage} from './pages/chronologicalscheme/chronologicalscheme';
import {ActionSchedulePage} from './pages/action-schedule/action-schedule';
import {ActionConfigfilterPage} from './pages/action-configfilter/action-configfilter';
import {VipListPage} from './pages/vip/vip';
import {MarkPage} from './pages/mark/mark';
import {UserCommentlistPage} from './pages/user-commentlist/user-commentlist';
import {UserCommentPage} from './pages/user-comment/user-comment';
import {UserAbsencelistPage, SelectUsersWithModal} from './pages/user-absencelist/user-absencelist';
import {UserAbsencePage} from './pages/user-absence/user-absence';
import {UserProfilePage} from './pages/user-profile/user-profile';
import {Document} from './pages/document/document';
import {ListDocumentsAssocPage} from './pages/list-documents-assoc/list-documents-assoc';
import {ListEmailsAssocPage} from './pages/list-emails-assoc/list-emails-assoc';
import {ErrorPage} from './pages/error/error';
import {LinkDocumentsPage} from './pages/link-documents/link-documents';
import {ListActionsDocumentPage} from './pages/list-actions-document/list-actions-document';
import {AboutPage} from './pages/about/about';
import {ListEmailsPage} from './pages/list-emails/list-emails';
import {AdvancedSearchPage, AdvancedSelectWithModal, AdvancedSearchAndSelectWithModal} from './pages/advanced-search/advanced-search';
import {AccountFormPage} from './pages/account-form/account-form';
import {ConsoleService} from './services/console/console-service';
import {AccountService} from './services/account-service';
import {Events} from './services/event-service';

import { StatusBar } from '@capacitor/status-bar';
import { SplashScreen } from '@capacitor/splash-screen';

import {DragulaModule} from 'ng2-dragula';
import { HammerModule } from '@angular/platform-browser';


// SERVICES
//import {HttpService} from '../services/http-service';
//import {HttpService} from './services/http/http';

import {TranslateModule, TranslateLoader} from '@ngx-translate/core';
import {TranslateHttpLoader} from '@ngx-translate/http-loader';
//import { TruncatePipe }   from '../services/truncate-pipe-service';
import {Nl2brPipe} from './pipes/nl2br-pipe';
import {Time2datePipe} from './pipes/time2date-pipe';

import { ClickOutsideModule } from 'ng-click-outside';


import { SESSION_STORAGE  } from 'ngx-webstorage-service';
import { WEB_STORAGE_SERVICE, WebStorageService } from './services/webstorage-service';

import { JwtModule, JwtHelperService } from '@auth0/angular-jwt';
import { AuthInterceptor } from './Interceptors/auth-interceptor';

import { ComponentsModule } from '././components/components.module';
import { CommonModule } from '@angular/common';
import { AuthGuardService } from './Interceptors/auth-guard';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';

//ngx-translate loader
const createTranslateLoader = (http: HttpClient) => new TranslateHttpLoader(http, 'assets/i18n', '.json');

const getConfig = () => {
  const ionConfig = {

  };
  return ionConfig;
};

/* const ngxConfig = () => ({
  prefix: '__app',
  allowNull: true,
});*/


@NgModule({
  declarations: [
    AppComponent,
    HomePage,
    ActionPage,
    ActionSchedulePage,
    MarkPage,
    ChronologicalschemePage,
    InfoPage,
    ListActionsPage,
    ListDocumentsPage,
    LoginPage,
    MenuPage,
    ActionConfigfilterPage,
    VipListPage,
    UserCommentlistPage,
    UserCommentPage,
    UserAbsencelistPage,
    UserAbsencePage,
    UserProfilePage,
    Document,
    ListDocumentsAssocPage,
    ListEmailsAssocPage,
    ErrorPage,
    LinkDocumentsPage,
	  ListActionsDocumentPage,
    Nl2brPipe,
    Time2datePipe,
    SelectUsersWithModal,
  	AboutPage,
	  ListEmailsPage,
	  AdvancedSearchPage,
	  AdvancedSelectWithModal,
    AdvancedSearchAndSelectWithModal,
    AccountFormPage,
    ListMyDocsPage,
  ],
  imports: [
    BrowserModule,
    FormsModule,
    ReactiveFormsModule,
    HttpClientModule,
    DragulaModule,
	  ClickOutsideModule,
    IonicModule.forRoot({
      backButtonText: ''
    }),
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: createTranslateLoader,
        deps: [HttpClient]
    }}),
    ComponentsModule,
    AppRoutingModule,
    JwtModule.forRoot({
      config: {
        //throwNoTokenError: true,
        skipWhenExpired: true,
        tokenGetter: () => localStorage.getItem('access_token')
      }
    }),
    CommonModule,
    HammerModule,
    NgbModule
  ],
  providers: [
    {
      provide: RouteReuseStrategy,
      useClass: IonicRouteStrategy
    },
    ClickOutsideModule,
    {
      provide: WEB_STORAGE_SERVICE,
      useExisting: SESSION_STORAGE
    },
    WebStorageService,
    ConsoleService,
    AccountService,
    {
      provide: HTTP_INTERCEPTORS,
      useClass: AuthInterceptor,
      multi: true
    },
    JwtHelperService,
    Events,
    AuthGuardService
  ],
  bootstrap: [
    AppComponent
  ],
  entryComponents: [
    AppComponent,
    ActionPage,
    ActionSchedulePage,
    MarkPage,
    ChronologicalschemePage,
    HomePage,
    InfoPage,
    ListActionsPage,
    ListDocumentsPage,
    LoginPage,
    MenuPage,
    ActionConfigfilterPage,
    VipListPage,
    UserCommentlistPage,
    UserCommentPage,
    UserAbsencelistPage,
    UserAbsencePage,
    UserProfilePage,
    Document,
    ListDocumentsAssocPage,
    ListEmailsAssocPage,
    ErrorPage,
    LinkDocumentsPage,
	  ListActionsDocumentPage,
    SelectUsersWithModal,
    AboutPage,
    ListEmailsPage,
    AdvancedSearchPage,
    AdvancedSelectWithModal,
    AdvancedSearchAndSelectWithModal,
    AccountFormPage,
    ListMyDocsPage
  ],
})
export class AppModule {}
