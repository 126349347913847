import { Component, OnInit } from '@angular/core';
import { NavController, NavParams, ModalController, IonNav } from '@ionic/angular';

import { DocumentService } from '../../services/document-service';
import { UserService } from '../../services/user-service';
import { FileService } from '../../services/file/file-service';
import { Document } from '../document/document';
import { StorageUtils } from '../../utils/storage-utils';
import { ActionSheet } from '../../controllers/actionsheet-controller';
import { Events } from '../../services/event-service';
import { timer } from 'rxjs';


@Component({
	selector: 'app-page-info',
	templateUrl: 'info.html',
	providers: [DocumentService],
	styleUrls: ['info.scss']
})

export class InfoPage {
	title: string;
	items: any[];
	pagesDontPush: string[] = ['actionChronologicalscheme'];
	finding = false;

	document: any;


	constructor(
				public doc: Document,
				public navCtrl: NavController,
				public navParams: NavParams,
				public viewCtrl: ModalController,
				public nav: IonNav,
				public documentService: DocumentService,
				public userService: UserService,
				public fileService: FileService,
				public actionSheet: ActionSheet,
				public events: Events) {

		//console.log(doc.pages);
		if (this.navParams.get('params')) {

			const params = this.navParams.get('params');

			if (typeof params.document === 'object' && typeof params.document.iddoc === 'number' ) {

				this.document = params.document;

				this.info({
					id: this.document.iddoc
				});

			}
		}
	}

	public info(params) {

		this.finding = true;

		this.documentService.info(params).subscribe({
			next: (data: any) => {

				this.finding = false;
				this.items = [];
				for (const a of data.list) {
					this.items.push(a);
				}
				this.title = data.title;
				this.document = Object.assign(this.document, data.document);

				//console.log(this.document);
			},
			error: () => {
				this.finding = false;
			}
		});
	}

	goToChronologicalScheme(){
		this.nav.getActive().then((view) => {
			this.events.publish('doc:pushPage', {
				page: 'ChronologicalschemePage',
				params: {
					document: this.document,
					frompage: 'InfoPage'
				}
			});
			timer(50).forEach(() => {
				this.nav.removeIndex(view.nav.views.length - 2);
			});
		});
	}

	goToMark() {
		this.nav.getActive().then((view) => {
			this.events.publish('doc:pushPage', {
				page: 'MarkPage',
				params: {
					document: this.document,
					frompage: 'InfoPage'
				}
			});
			timer(50).forEach(() => {
				this.nav.removeIndex(view.nav.views.length - 2);
			});
		});
	}

	download(item: any): any {
		//console.log(item);

		const user = this.userService.getUser();
		if ('0' == StorageUtils.getItem('platform_cordova')) {
			this.documentService.downloadbytoken({iddoc: item.iddoc, chronschemasort: user.defaultchronschemasort});
		} else {
			this.fileService.download(item);

		}
	}

	openActionSheetFor(){
		this.actionSheet.show('InfoPage', 'more', {
			item: this.document
		});
	}
}
