import {Component} from '@angular/core';
import {NavController, NavParams} from '@ionic/angular';
import {Loading} from '../../controllers/message-controller';
import {ActionService} from '../../services/action-service';
import {UserService} from '../../services/user-service';

@Component({
	selector: 'app-page-list-actions-document',
	templateUrl: 'list-actions-document.html',
	providers: [ActionService, UserService, Loading],
	styleUrls: ['list-actions-document.scss']
})
export class ListActionsDocumentPage {

	finding = false;
	hasActions = false;
	actions: any;
	priorityflags: any;
	userid: number;
	reactivationdatemin: any;
	reactivationdatemax: any;
	backSave = false;

	constructor(public navCtrl: NavController,
				public navParams: NavParams,
				public actionService: ActionService,
				public userService: UserService,
				public loading: Loading) {
		this.reactivationdatemin = (new Date(new Date().setFullYear(new Date().getFullYear() - 10))).toISOString().slice(0, 10);
		this.reactivationdatemax = (new Date(new Date().setFullYear(new Date().getFullYear() + 10))).toISOString().slice(0, 10);

		const user = this.userService.getUser();
		this.userid = user.id;

		if (this.navParams.get('params')) {

			const params = this.navParams.get('params');

			if ('object' === typeof params.document) {
				this.finding = true;
				this.actionService.findactionsdocument({'docid': params.document.iddoc}).subscribe({
					next: data => {
						this.finding = false;
						this.actions = data.list;
						if ('object' === typeof this.actions && this.actions.length > 0) {
							this.hasActions = true;
						} else {
							this.hasActions = false;
						}
						this.priorityflags = data.priorityflags;
						for (let i = 0; i < this.actions.length; i++) {
							for (let j = 0; j < this.priorityflags.length; j++) {
								if (this.actions[i].priorityflagid == this.priorityflags[j].idpriorityflag) {
									this.actions[i].priorityflagcolor = this.priorityflags[j].color
								}
							}
							if (!this.actions[i].pendente) { // set today as the default date 
								this.actions[i].datareact = new Date().toISOString().slice(0, 10);
							}
						}

						//console.log('actions', this.actions);


					},
					error: () => {
						this.finding = false;
					},
					complete: () => {
						this.finding = false;
					}
				});
			}
		}
	}
	backSaveEnable(){
		this.backSave = true;
	}

	save(){
		//console.log('save');

		let actions : Array<any> = [];
		for (let i = 0; i < this.actions.length; i++){
			if (this.actions[i].ownerusers.utilizador == this.userid){
				let obj = { 'idaccaorev': this.actions[i].idaccaorev, 
							'pendente': this.actions[i].pendente, 
							'datareact': this.actions[i].datareact };
				actions.push(obj);
			}
		}
		this.actionService.savedatareact({actions}).subscribe({});
	}

	ionViewWillEnter() {
		//console.log('ionViewWillEnter ListActionsDocumentPage');
	}

	ionViewWillLeave(){
		if (this.backSave){
			this.save();
		}
	}
}
