import { Component } from '@angular/core';
import { NavController, Platform } from '@ionic/angular';

import { Loading } from '../../controllers/message-controller';
import { ConfigService } from '../../services/config-service';
import { UserService } from '../../services/user-service';
import {FileService} from '../../services/file/file-service';

@Component({
	selector: 'app-page-user-profile',
	templateUrl: 'user-profile.html',
	providers: [Loading],
	styleUrls: ['user-profile.scss']
})

export class UserProfilePage {

	img: string;
	user: any;
	pages: Array<{name: string; value: any}> = [];
	chronschemas: Array<{name: string; value: any}> = [];
	defaultpage: any;
	defaultchronschema: any;
	defaultchronschemasort: string;
	defaultchronschemaexpanded: any;
	showPhotoIcon = false;

	constructor(
				public userService: UserService,
				public configService: ConfigService,
				public platform: Platform,
				public fileService: FileService,
				) {

		if(platform.is('cordova')){

			this.showPhotoIcon = true;
		} else {
			//console.log("not show");

		}

		this.user = userService.getUser();
		if ('undefined' != typeof this.user.defaultpage && '' != this.user.defaultpage) {
			this.defaultpage = this.user.defaultpage;
		} else {
			this.user.defaultpage = this.defaultpage = 'Home';
		}

		if ('undefined' != typeof this.user.defaultchronschema && '' != this.user.defaultchronschema) {
			this.defaultchronschema = this.user.defaultchronschema;
		} else {
			this.user.defaultchronschema = this.defaultchronschema = 'esquema_cronologico_info';
		}

		if ('undefined' != typeof this.user.defaultchronschemasort && '' != this.user.defaultchronschemasort) {
			this.defaultchronschemasort = this.user.defaultchronschemasort;
		} else {
			this.user.defaultchronschemasort = this.defaultchronschemasort = 'asc';
		}

		if ('undefined' != typeof this.user.defaultchronschemaexpanded && '' != this.user.defaultchronschemaexpanded) {
			this.defaultchronschemaexpanded = this.user.defaultchronschemaexpanded;
		} else {
			this.user.defaultchronschemaexpanded = this.defaultchronschemaexpanded = '1';
		}

		this.userService.setUser(JSON.stringify(this.user));

		this.pages.push({name: "Home" , value: "Home"});
		this.pages.push({name: "Actions", value: "ListActionsPage"});

		this.chronschemas.push({name: "Detailed" , value: "esquema_cronologico_info"});
		this.chronschemas.push({name: "Brief" , value: "short_chron_scene"});
	}

	ionViewWillLeave() {
		this.save();
	}

	save(): void {
		let user: any = {};
		if ("undefined" !== this.user.defaultpage) {
			user = Object.assign(user, {defaultpage: this.user.defaultpage});
		}
		if ("undefined" !== this.user.defaultchronschema) {
			user = Object.assign(user, {defaultchronschema: this.user.defaultchronschema});
		}
		if ("undefined" !== this.user.defaultchronschemasort) {
			user = Object.assign(user, {defaultchronschemasort: this.user.defaultchronschemasort});
		}
		if ("undefined" !== this.user.defaultchronschemaexpanded) {
			user = Object.assign(user, {defaultchronschemaexpanded: this.user.defaultchronschemaexpanded});
		}

		this.userService.saveuser({user: user}).subscribe(
			data => {
			},
			err => {
			},
			() => {
			}
		);
	}

	onDefaultPageChange(defaultpage: string): void {
		this.user.defaultpage = defaultpage;
		this.userService.setUser(JSON.stringify(this.user));
	}

	onDefaultChronschemaChange(defaultchronschema: string): void {
		this.user.defaultchronschema = defaultchronschema;
		this.userService.setUser(JSON.stringify(this.user));
	}

	onDefaultChronschemasortChange(sort: string): void {
		this.user.defaultchronschemasort = sort;
		this.userService.setUser(JSON.stringify(this.user));
	}

	onDefaultChronschemaexpandedChange(expanded: string): void {
		this.user.defaultchronschemaexpanded = expanded;
		this.userService.setUser(JSON.stringify(this.user));
	}
}
