import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable, of } from 'rxjs';
import { share, map } from 'rxjs/operators';
import { JwtHelperService } from '@auth0/angular-jwt';
import { WebStorageService } from './webstorage-service';

@Injectable({
  providedIn: 'root'
})
export class AuthService {
  constructor(
    private http: HttpClient,
    private decoder: JwtHelperService,
    private storage: WebStorageService
  ) {}

  refreshToken(): Observable<string> {
    //console.log('Refreshing inst it?');
    let url = '/api/user/refresh/';
    if (null !== this.storage.get('server_url')) {
      url = this.storage.get('server_url') + '/api/user/refresh/';
    } else if (null !== localStorage.get('server_url')) {
      url = localStorage.get('server_url') + '/api/user/refresh/';
    } else {
      return null;
    }

    //console.log('Refresh Token = ' + url);
    // append refresh token if you have one
    const refreshToken = this.storage.get('refresh_token');
    const expiredToken = this.storage.get('access_token');

    return this.http
      .get(url, {
        headers: new HttpHeaders()
          .set('refreshToken', refreshToken)
          .set('token', expiredToken),
        observe: 'response'
      })
      .pipe(
        share(), // <========== YOU HAVE TO SHARE THIS OBSERVABLE TO AVOID MULTIPLE REQUEST BEING SENT SIMULTANEOUSLY
        map((res: any) => {
          //console.log('Map this');
          //console.log(res.body.access_token);
          const token = res.body.access_token;
          const newRefreshToken = res.body.refresh_token;

          // store the new tokens
          this.storage.set('refresh_token', newRefreshToken);
          localStorage.setItem('refresh_token', newRefreshToken);
          this.storage.set('access_token', token);
          localStorage.setItem('access_token', token);

          return token;
        })
      );
  }

  getToken(): Observable<string> {
    const token = this.storage.get('access_token');
    const isTokenExpired = this.decoder.isTokenExpired(token);
    //console.log('Get Token = ' + token);
    if (!isTokenExpired) {
      return of(token);
    }

    return this.refreshToken();
  }

  // other auth methods
}
