import { Component } from '@angular/core';
import { NavController, NavParams, ModalController, AlertController, IonFab, IonItemSliding, IonNav } from '@ionic/angular';

import { TranslateService } from '@ngx-translate/core';
import {Observable, timer} from 'rxjs';

import { DocumentService } from '../../services/document-service';
import { ConfigService } from '../../services/config-service';
import { UserService } from '../../services/user-service';
import { FileService } from '../../services/file/file-service';
import { StorageUtils } from '../../utils/storage-utils';
import { ArrayUtils } from '../../utils/array-utils';
import { Loading, Toast } from '../../controllers/message-controller';
import { ActionSheet } from '../../controllers/actionsheet-controller';
import { AdvancedSearchPage } from '../advanced-search/advanced-search';
import { Events } from 'src/app/services/event-service';

@Component({
	selector: 'app-page-list-emails',
	templateUrl: 'list-emails.html',
	providers: [DocumentService],
	styleUrls: ['list-emails.scss']
})
export class ListEmailsPage {

	finding = false;
	hasEmails = false;
	emails: any;
	paramsOfPreviousSearch: any;

	index: number;
	countItemsChecked = 0;
	itemsIdsSelected: Array<any> = [];
	editEnable = false;

	fab: any;

	searchParams: any = {findemails:true, limit: 20};

	constructor(public navCtrl: NavController,
				public navParams: NavParams,
				public nav: IonNav,
				public documentService: DocumentService,
				public userService: UserService,
				public modalCtrl: ModalController,
				public translateService: TranslateService,
				public alertCtrl: AlertController,
				public configService: ConfigService,
				public actionSheet: ActionSheet,
				public loading: Loading,
				public toast: Toast,
				public events: Events,
				public fileService: FileService
				) {

		//console.log(this.navCtrl)

		events.subscribe('functionCall:ListEmailsPage', data => {

			//console.log('functionCall:ListEmailsPage', data);
			if (undefined != data.function) {
				switch (data.function) {
					case 'associatewitheachother':
						this.associate('associatewitheachother');
						break;
					default:
						break;
				}

			}
		});
	}

	ionViewDidLoad() {

	}

	ionViewWillEnter() {

		this.hasEmails = false;

		// check if it has docs, form, searchvars saved in config

		//console.log('ionViewWillEnter', this.configService.getAllVar());

		let list_emails_vars = this.configService.getVar('list_emails');

		if (typeof list_emails_vars != 'undefined') {

			//console.log('list_emails_vars defined');

			if (typeof list_emails_vars.emails !== 'undefined' && list_emails_vars.emails.length > 0) {
				//console.log('emails defined', list_emails_vars.emails);
				this.emails = list_emails_vars.emails;
				this.hasEmails = true;
			} else {
				//console.log('emails = []');
				this.emails = [];
				this.hasEmails = false;
			}

			if (typeof list_emails_vars.form !== 'undefined') {
				this.paramsOfPreviousSearch = {form: list_emails_vars.form};
			}

			if (typeof list_emails_vars.searchvars !== 'undefined') {
				this.searchParams = Object.assign(this.searchParams, list_emails_vars.searchvars);
			}
		}

		if (!this.hasEmails) {

			this.goToAdvancedSearch();
		}

	}


	ionViewWillLeave() {

		if (this.editEnable) {
			this.editEnable = false;
			this.selectedClean();
		}
		if (this.fab != undefined) {
			this.fab.close();
		}

		if (this.loading.isOn) {
			this.loading.dismiss()
		}
	}

	find(searchParams: any): void {
		//console.log('params to search', searchParams);
		this.finding = true;
		this.documentService.find(searchParams).subscribe({
			next: (data: any) => {
				this.finding = false;
				if (data.list != 'undefined') {
					this.emails = [];
					this.emails = data.list;
					if (this.emails.length >= 1) {
						this.hasEmails = true;

					} else {
						this.hasEmails = false;
					}
				}

				for (let email of this.emails) {
					email = Object.assign(email, {checked: false});
					//assuming email_date return something like 2017-10-04 12:18:24.570276
					email.mailarchive.email_date = email.mailarchive.email_date.substr(0, email.mailarchive.email_date.lastIndexOf(':'));
				}

				if (this.hasEmails) {
					//console.log('hasemails');

					const emails = [];
					for (let i = 0; i < this.emails.length; i++) {
						if (i < 20) {
							emails.push(this.emails[i]);
						} else {
							break
						}
					}
					// set emails in config
					if (this.configService.getVar('list_emails')) {
						//console.log('setVar assign');
						let list_emails_vars = this.configService.getVar('list_emails');
						list_emails_vars = Object.assign(list_emails_vars, {'emails': emails});
						this.configService.setVar('list_emails', list_emails_vars);

					} else {
						//console.log('setVar');
						this.configService.setVar('list_emails', {'emails': emails});

						//console.log(this.configService.getAllVar());
					}
				} else {
					// clean emails from config
					if (this.configService.getVar('list_emails')) {
						//console.log('setVar [] assign');
						let list_emails_vars = this.configService.getVar('list_emails');
						list_emails_vars = Object.assign(list_emails_vars, {'emails': []});
						this.configService.setVar('list_emails', list_emails_vars);

					} else {
						//console.log('setVar');
						this.configService.setVar('list_emails', {'emails': []});

						//console.log(this.configService.getAllVar());
					}
				}


			},
			error: () => {
				this.finding = false;
			}
		});
	}

	doInfinite(infiniteScroll: any): void {

		//console.log(infiniteScroll);
		this.searchParams.limit  = this.searchParams.limit + 20;

		this.documentService.find(this.searchParams).subscribe({
			next: (data: any) => {

				//console.log(data);
				this.emails = [];
				for (let email of data.list) {
					email = Object.assign(email, {checked: false});
					//assuming email_date return something like 2017-10-04 12:18:24.570276
					email.mailarchive.email_date = email.mailarchive.email_date.substr(0, email.mailarchive.email_date.lastIndexOf(':'));
					this.emails.push(email);

				}

				if (data.list.length < this.searchParams.limit - 20) {
					this.searchParams.limit = this.searchParams.limit - 20;
				}

			},
			error: () => {

			},
			complete:() =>{
				infiniteScroll.complete();
			}
		});

	}

	associate(atype?: string, item?: any, slidingItem?: IonItemSliding): void {


		//console.log('this.itemsIdsSelected', this.itemsIdsSelected);

		if (atype == 'associatewitheachother') {

			//console.log('this.itemsIdsSelected before', this.itemsIdsSelected)
			this.itemsIdsSelected = ArrayUtils.unique(this.itemsIdsSelected);
			//console.log('this.itemsIdsSelected after', this.itemsIdsSelected);

			this.loading.show({});

			this.documentService.associate({iddocs: this.itemsIdsSelected}).subscribe({
				next: (data: any) => {


					for (let i = 0; i < this.emails.length; i++) {
						if (this.itemsIdsSelected.indexOf(this.emails[i].iddoc) > -1) {
							this.emails[i].associateddocuments.push({});
						}
					}

					this.loading.dismiss();

					this.toast.show({message: 'Documents associated successfully', cssClass: 'success', dismissOnPageChange: false});

					if ('associatewitheachother' !== atype) {
						this.navCtrl.pop();

					}

					if (this.editEnable) {
						this.editEnable = false;
						this.selectedClean();
					}

					if (this.fab != undefined) {
						this.fab.close();
					}
				},
				error: () => {
					this.loading.dismiss();

					this.toast.show({message: 'An error occured while associating documents', cssClass: 'error', dismissOnPageChange: false});

				}
			});


		} else {
			//console.log(item);

			this.events.publish('doc:pushPage', {
				page: 'ListDocumentsPage',
				params: {
					associate: true,
					documents: [item],
					frompage: 'ListEmailsPage'
				}
			});

			if (undefined != slidingItem) {
				slidingItem.close();
			}

		}



	}


	edit(fab?: IonFab){
		if (this.editEnable) {
			this.editEnable = false;
		} else {
			this.editEnable = true;
		}

		if (fab != undefined) {
			this.fab = fab;
		}
	}

	selectedClean(): void {
		this.countItemsChecked = 0;
		this.itemsIdsSelected = [];

		for (let i = 0; i < this.emails.length; i++) {
			this.emails[i].checked = false;
		}
	}

	selected(items: any, index: number): void {

		//console.log(items, index);

		this.index = index;
		items[index].checked = !items[index].checked;

		this.countItemsChecked = 0;

		const indexOfIddoc = this.itemsIdsSelected.indexOf(items[index].iddoc);
		if (true === items[index].checked) {
			if (-1 === indexOfIddoc) {
				this.itemsIdsSelected.push(items[index].iddoc);
			}
		} else {
			this.itemsIdsSelected.splice(indexOfIddoc, 1);
		}

		for (let i = 0; i < this.emails.length; i++) {
			if (this.emails[i].checked == true) {
				this.countItemsChecked++;
			}
		}


	}


	goToAdvancedSearch(params?: any){

		let advancedSearchModal;

		if(typeof params == 'undefined'){

			////console.log('undefined');
			const advancedSearchInputs = [];
			const advancedSearchDates = [];
			const advancedSearchRadios = [];
			const advancedSearchRadioOptions = [];

			const dateSinceValue = (new Date()).toISOString().slice(0, 10);
			const dateUpToValue = (new Date()).toISOString().slice(0, 10);

			const dateSinceMin = (new Date(new Date().setFullYear(new Date().getFullYear() - 10))).toISOString().slice(0, 10);
			const dateSinceMax = (new Date(new Date().setFullYear(new Date().getFullYear() + 10))).toISOString().slice(0, 10);

			const dateUpToMin = (new Date(new Date().setFullYear(new Date().getFullYear() - 10))).toISOString().slice(0, 10);
			const dateUpToMax = (new Date(new Date().setFullYear(new Date().getFullYear() + 10))).toISOString().slice(0, 10);

			// campos para a pesquisa de email: subject, from, to, date, body
			advancedSearchInputs.push({name: 'Subject', idModel: 'subject', value: ''});
			advancedSearchInputs.push({name: 'From', idModel: 'from', value: ''});
			advancedSearchInputs.push({name: 'To', idModel: 'to', value: ''});
			advancedSearchInputs.push({name: 'Body', idModel: 'body', value: ''});

			advancedSearchDates.push({	name: 'Date',
										idModelSince: 'datainicio',
										idModelUpTo: 'datafim',
										show: false,
										sinceValue: dateSinceValue,
										sinceMin: dateSinceMin,
										sinceMax: dateSinceMax,
										upToValue: dateUpToValue,
										upToMin: dateUpToMin,
										upToMax: dateUpToMax});

			advancedSearchRadioOptions.push({name: 'External', idModel: 'external', value: 'external'});
			advancedSearchRadioOptions.push({name: 'Internal', idModel: 'internal', value: 'internal'});
			advancedSearchRadioOptions.push({name: 'All', idModel: 'all', value: 'all'});
			advancedSearchRadios.push({name: 'More search criteria', idModel: 'type', value: 'all', options: advancedSearchRadioOptions});

			advancedSearchModal = this.modalCtrl.create({
				component: AdvancedSearchPage,
				componentProps: {	title: 'Search emails',
									inputs: advancedSearchInputs,
									dates: advancedSearchDates,
									radios: advancedSearchRadios

			}}).then((modal) => {
					modal.onDidDismiss()
						.then((value: any) => {
							const data = value.data;
							if(typeof data !== 'undefined'){
								//console.log(data);
								this.paramsOfPreviousSearch = data;
								this.searchParams = Object.assign(this.searchParams, {searchvars: data.searchvars});
								if (this.configService.getVar('list_emails')) {
									let list_emails_vars = this.configService.getVar('list_emails');
									list_emails_vars = Object.assign(list_emails_vars, {searchvars: data.searchvars, form: data.form});
									this.configService.setVar('list_emails', list_emails_vars);
								} else {
									this.configService.setVar('list_emails', {searchvars: data.searchvars, form: data.form});
								}
								let name = '';
								for (name in data.searchvars) {
									//console.log(name +' = ' + data.searchvars[name]);
									this.searchParams[name] = data.searchvars[name];
								}
								this.find(this.searchParams);
							}
						});
					modal.present();
				});
		} else {
			//console.log('this.paramsOfPreviousSearch', params);
			this.modalCtrl.create({component: AdvancedSearchPage, componentProps: {form: params.form}})
				.then((modal) => {
					modal.onDidDismiss()
						.then((value: any) => {
							const data = value.data;
							if(typeof data !== 'undefined'){
								////console.log(data);
								this.paramsOfPreviousSearch = data;
								this.searchParams = Object.assign(this.searchParams, {searchvars: data.searchvars});
								if (this.configService.getVar('list_emails')) {
									let list_emails_vars = this.configService.getVar('list_emails');
									list_emails_vars = Object.assign(list_emails_vars, {searchvars: data.searchvars, form: data.form});
									this.configService.setVar('list_emails', list_emails_vars);
								} else {
									this.configService.setVar('list_emails', {searchvars: data.searchvars, form: data.form});
								}
								let name = '';
								for (name in data.searchvars) {
									//console.log(name +' = ' + data.searchvars[name]);
									this.searchParams[name] = data.searchvars[name];
								}
								this.find(this.searchParams);
							}
						});
					modal.present();
				});
		}
	}

	goToInfo(document: any, slidingItem: IonItemSliding): void {

		//console.log(document);
		this.nav.getActive().then((view) => {
			this.events.publish('doc:pushPage', {
				page: 'InfoPage',
				params: {
					document: document,
					frompage: 'ListEmailsPage'
				}
			});
		});


		if (slidingItem != undefined) {
			slidingItem.close();
		}
	}

	goToHome(){
		this.events.publish('doc:setRootPage', {
			page: 'HomePage',
			params: {
				emails: undefined,
				frompage: 'ListEmailsPage'
			}
		});
	}

	goToListActions(){
		this.nav.getActive().then((view) => {
			this.events.publish('doc:pushPage', {
				page: 'ListActionsPage',
				params: {
					actions: undefined,
					frompage: 'ListEmailsPage'
				}
			});
			timer(50).forEach(() => {
				this.nav.removeIndex(view.nav.views.length - 2);
			});
		});
	}

	goToListDocuments(){
		this.nav.getActive().then((view) => {
			this.events.publish('doc:pushPage', {
				page: 'ListDocumentsPage',
				params: {
					documents: undefined,
					frompage: 'ListEmailsPage'
				}
			});
			timer(50).forEach(() => {
				this.nav.removeIndex(view.nav.views.length - 2);
			});
		});
	}

	goToMenu(){
		this.nav.getActive().then((view) => {
			this.events.publish('doc:pushPage', {
				page: 'MenuPage',
				params: {
					frompage: 'ListEmailsPage'
				}
			});
		});
	}

	goToListEmails(){
		//Maybe
		/*this.nav.getActive().then((view) => {
			this.events.publish('doc:pushPage', {
				page: 'ListEmailsPage',
				params: {
					frompage: 'ListEmailsPage'
				}
			});
			timer(50).forEach(() => {
				this.nav.removeIndex(view.state - 1);
			});
		});*/
	}

	openActionSheetFor(option: string, item?: any, slidingItem?: IonItemSliding) {

		//console.log('openActionSheetFor', option, item, slidingItem);

		if ('more' == option) {

			if ('undefined' != typeof item) {
				this.actionSheet.show('ListEmailsPage', 'more', {
					item: item
				});
			} else {
				//console.log('Error: openActionSheetFor did not received item(email)')
			}


		} else if (option == 'selectedmore') {

			let itemsSelected = [];

			for (let i = 0; i < this.emails.length; i++) {

				//console.log('itemsids', this.itemsIdsSelected);
				//console.log('items', this.emails);

				if (this.itemsIdsSelected.indexOf(this.emails[i].iddoc) > -1) {
					itemsSelected.push(this.emails[i]);
				}

				//console.log('itemsSelected', itemsSelected);
			}

			this.actionSheet.show('ListEmailsPage', 'selectedmore', {
				items: itemsSelected
			});
		}

		if (slidingItem != undefined) {
			slidingItem.close();
		}
	}

	download(item: any, attachname?: any): void {

		let user = this.userService.getUser();

		if ('0' == StorageUtils.getItem('platform_cordova')) {

			this.documentService.downloadbytoken({
				iddoc: item.iddoc,
				chronschemasort: user.defaultchronschemasort
			});

		} else {

			item = Object.assign(item, {
				'emltopdf': true,
				chronschemasort: user.chronschemasort
			});

			if('undefined' != typeof attachname){
				item = Object.assign(item, {'attachnametodownload': attachname});
			} else{
				item = Object.assign(item, {'attachnametodownload': ''});
			}

			this.fileService.download(item);

		}

}
}
