import {
    HttpErrorResponse,
    HttpEvent,
    HttpHandler,
    HttpInterceptor,
    HttpRequest
  } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Observable } from 'rxjs';
import { tap } from 'rxjs/operators';
import { WebStorageService } from '../services/webstorage-service';
import { LoadingController } from '@ionic/angular';
import { Events } from '../services/event-service';
import { ModalController } from '@ionic/angular';

@Injectable({
  providedIn: 'root',
  })
export class AuthInterceptor implements HttpInterceptor {
  inflightAuthRequest = null;

  blacklist: object = [
    /(((https?):\/\/|www\.)theinfogrid.com\/auth\/)/,
    'some-other-url-pattern',
    'some-other-pattern'
  ];

  constructor(
    private router: Router,
    private storage: WebStorageService,
    private loading: LoadingController,
    private events: Events,
    private modal: ModalController
  ) {}

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    const token = this.storage.get('access_token');
    const refresh = this.storage.get('refresh_token');
    return next.handle(req).pipe(
      tap({
        error: (err: any) => {
          if (err instanceof HttpErrorResponse) {
            if (err.status === 401) {
              //console.log(err.status);
              this.storage.remove('access_token');
              this.dismisserOfLoadings();
              this.events.publish('doc:setRootPage', {page: 'LoginPage'});
              this.dismisserOfModals();
            }
          }
        }
      })
    );
  }

  dismisserOfModals(): void {
    this.modal.getTop().then((data) => {
      if (data == undefined) {
        return;
      } else {
        this.modal.dismiss(data.id).then(() => {
          this.dismisserOfModals();
        });
      }
    });
  }

  dismisserOfLoadings(): void {
    this.loading.getTop().then((data) => {
      if (data == undefined) {
        return;
      } else {
        this.loading.dismiss(data.id).then(() => {
          this.dismisserOfLoadings();
        });
      }
    });
  }

  /*
  intercept(
    req: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
    // exempt some paths from authentication
    if (req.headers.get('authExempt') === 'true') {
      return next.handle(req);
    }

    const authService = this.injector.get(AuthService);

    if (!this.inflightAuthRequest) {
      this.inflightAuthRequest = authService.getToken();
    }

    return this.inflightAuthRequest.pipe(
      switchMap((newToken: string) => {
        // unset request inflight
        this.inflightAuthRequest = null;

        // use the newly returned token
        const authReq = req.clone({
          headers: req.headers.set('token', newToken ? newToken : '')
        });

        return next.handle(authReq);
      }),
      catchError(error => {
        // checks if a url is to an admin api or not
        if (error.status === 401) {
          // check if the response is from the token refresh end point
          const isFromRefreshTokenEndpoint = !!error.headers.get(
            'unableToRefreshToken'
          );

          if (isFromRefreshTokenEndpoint) {
            localStorage.clear();
            this.router.navigate(['/sign-page']);
            return throwError(error);
          }

          if (!this.inflightAuthRequest) {
            this.inflightAuthRequest = authService.refreshToken();

            if (!this.inflightAuthRequest) {
              // remove existing tokens
              localStorage.clear();
              this.router.navigate(['/sign-page']);
              return throwError(error);
            }
          }

          return this.inflightAuthRequest.pipe(
            switchMap((newToken: string) => {
              // unset inflight request
              this.inflightAuthRequest = null;

              // clone the original request
              const authReqRepeat = req.clone({
                headers: req.headers.set('token', newToken)
              });

              // resend the request
              return next.handle(authReqRepeat);
            })
          );
        } else {
          return throwError(error);
        }
      })
    );
  }

  blacklistCheckup($url: string): boolean {
    let returnValue = false;

    for (const i of Object.keys(this.blacklist)) {
      if (this.blacklist[i].exec($url) !== null) {
        returnValue = true;
        break;
      }
    }

    return returnValue;
  }
  */
}
