import { Component } from '@angular/core';
import { NavController, NavParams, AlertController, IonItemSliding } from '@ionic/angular';

import { TranslateService } from '@ngx-translate/core';

import { DocumentService } from '../../services/document-service';
import { UserService } from '../../services/user-service';
import { FileService } from '../../services/file/file-service';
import { ConsoleService } from '../../services/console/console-service';
import { ActionSheet } from '../../controllers/actionsheet-controller';
import { StorageUtils } from '../../utils/storage-utils';
import { Events } from '../../services/event-service';

@Component({
	selector: 'app-page-list-documents-assoc',
	templateUrl: 'list-documents-assoc.html',
	providers: [DocumentService],
	styleUrls: ['list-documents-assoc.scss']
})
export class ListDocumentsAssocPage {

	finding = false;
	hasItems = false;
	document: any;
	documentsassoc: Array<any> = [];

	constructor(
		public navCtrl: NavController,
		public navParams: NavParams,
		public documentService: DocumentService,
		public userService: UserService,
		public consoleService: ConsoleService,
		public alertCtrl: AlertController,
		public events: Events,
		public translateService: TranslateService,
		public actionSheet: ActionSheet,
		public fileService: FileService) {

		this.consoleService.log('ListDocumentsAssocPage', 'constructor');

		if (this.navParams.get('params')) {
			const params = this.navParams.get('params');

			if ('undefined' != typeof params.document) {
				this.document = params.document;

				if ('object' === typeof this.document.associateddocuments) {
					if ( this.document.associateddocuments.length > 0) {
						this.find();
					}
				}
			}

		}

	}

	ionViewDidLoad() {
	}

	ionViewWillEnter() {
		//StorageUtils.setItem('tab_selected', 'documentsassoc');
	}

	ionViewWillLeave() {

	}

	processResults(data: any): void {
		this.documentsassoc = data.list;

		if ('object' === typeof this.documentsassoc) {
			if (this.documentsassoc.length > 0) {
				this.hasItems = true;
			} else {
				//console.log('Error: this.items length <= 0')
				this.hasItems = false;
			}
		} else {
			//console.log('Error: this.items is not an object (array)');
			this.hasItems = false;
		}

	}

	find(): void {

		this.finding = true;

		this.documentService.findassoc({iddoc: this.document.iddoc}).subscribe({
			next: data => {
				this.finding = false;
				this.documentsassoc = [];
				this.processResults(data);
			},
			error: () => {
				this.finding = false;
			},
			complete: () => {
				this.finding = false;
			}
		});
	}





	goToInfo(document: any, slidingItem: IonItemSliding): void {

		if ('object' === typeof document) {
			this.events.publish('doc:pushPage', {
				page: 'InfoPage',
				params: {
					document,
					frompage: 'ListDocumentsPage'
				}
			});

			if (slidingItem != undefined) {
				slidingItem.close();
			}
		} else {

			//console.log('Error: goToPage doc is not of type object')
		}

	}

	goToChronologicalScheme(document: any, slidingItem: IonItemSliding): any {

		if ('object' === typeof document) {
			this.events.publish('doc:pushPage', {
				page: 'ChronologicalschemePage',
				params: {
					document,
					frompage: 'ListDocumentsPage'
				}
			});

			if (undefined != slidingItem) {

				slidingItem.close();

			} else {

				//console.log('slidingItem undefined')
			}

		} else {

			//console.log('Error: goToPage doc is not of type object')
		}

	}

	openActionSheetFor(option: string, document?: any, slidingItem?: IonItemSliding): void{

		if ('string' === typeof option) {

			if ('more' === option && 'object' === typeof document) {
				this.actionSheet.show('ListDocumentsAssocPage', 'more', {
					item: document
				});

				if (undefined != slidingItem) {
					slidingItem.close();
				}
			}

		}
	}

	download(item: any): void {
		//console.log(item);

		const user = this.userService.getUser();

		if ('0' == StorageUtils.getItem('platform_cordova')) {

			this.documentService.downloadbytoken({
				iddoc: item.iddoc,
				chronschemasort: user.defaultchronschemasort
			}, item.revision);


		} else {

			item = Object.assign(item, {
				'emltopdf': true, 								// IN CASE EML IT IS TO CONVERT IN PDF
				chronschemasort: user.defaultchronschemasort
		});
			this.fileService.download(item);
		}
	}

}
