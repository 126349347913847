import { Component, ViewChild } from '@angular/core';
import { NavController, NavParams, AlertController, IonContent, ActionSheetController, IonFab , IonNav } from '@ionic/angular';
import { Events } from '../../services/event-service';

import { TranslateService } from '@ngx-translate/core';
import { Observable, interval, timer } from 'rxjs';
import { take, map } from 'rxjs/operators';

import { Document } from '../document/document';
import { DocumentService } from '../../services/document-service';
import { ActionService } from '../../services/action-service';
import { ConfigService } from '../../services/config-service';
import { UserService } from '../../services/user-service';
import { FileService } from '../../services/file/file-service';
import { ConsoleService } from '../../services/console/console-service';
import { ActionSheet } from '../../controllers/actionsheet-controller';
import { Loading, Toast, Alert } from '../../controllers/message-controller';
import { ArrayUtils } from '../../utils/array-utils';
import { StringUtils } from '../../utils/string-utils';
import { StorageUtils } from '../../utils/storage-utils';
import { ComponentsModule } from '../../components/components.module';
import { arrayBuffer } from 'stream/consumers';
import { Button } from 'protractor';
import { Router } from '@angular/router';

@Component({
	selector: 'app-page-action',
	templateUrl: 'action.html',
	providers: [ActionService, DocumentService],
	styleUrls: ['action.scss']
})

export class ActionPage {
	@ViewChild(IonContent) content: IonContent;

	alertTranslations = { userAlert:'', userOrGroupAlert:''};

	idcodigo: any;
	document: any = {tituloformated: ''};
	documents: any;
	index: number;
	items: any;
	finding = false;
	sizeOfResults: any;
	hasSubActions = false;
	actionMandatoryComponents: any;
	executeAll = false;
	forwardAll = false;
	message = ' ';
	results: any;
	resultButton: Array<{result: string; type: string; id: string}> = [];
	subactions: any = [];
	subaction: Array<{name: string}> = [];
	searchParams = {searchString: '', idaction: ''}; //search criteria
	components: any = {};
	priorityflags: any;


	// ------------------ACTION COMPONENTS -------------------------------------

	linkChronologicalScheme = {check: false, warningMessage: '', showWarningClass: false};

	linkReadDocument = {check: false, warningMessage: '', showWarningClass: false};

	fillInComment = {isActionComponent: false, value: '', warningMessage: '', showWarningClass: false};

	//action comments
	predefinedComments: Array<any> = [];
	showPredefinedComment = false;
	//user comments
	userPredefinedComments: Array<any> = [];
	showUserPredefinedComments = false;

	fillInSubject = {isActionComponent: false, value: '', warningMessage: '', showWarningClass: false};

	showLastcomment = false;
	showLastcommentForm = false;

	fillInEmail = {isActionComponent: false, value: '', warningMessage: '', showWarningClass: false};
	fillInEmailCc = {isActionComponent: false, value: '', warningMessage: '', showWarningClass: false};
	fillInEmailBcc = {isActionComponent: false, value: '', warningMessage: '', showWarningClass: false};

	showEmailIdentity = false;
	emailIdentity = '';

	showNote = false;
	showNoteForm = false;
	note = '';

	showNotifyusers = false;
	showNotifyusersSearhcbar = false;
	showNotifyusersForm = false;
	fNotifyusersParticipated = false;
	fNotifyusersAdhocaction = false;
	allNotifyUsers: any;

	showAssociateddocs = false;
	showAssociateddocsSearchbar = false;
	showAssociateddocsForm = true;
	associateddocs: any = [];
	associateddocsall: any = [];

	readingactions: Array<any> = [];

	// -----------------FILTERS CHECKBOXES--------------------------------------

	commentAccessUsers: any;
	showCommentAccess = false;
	showCommentAccessList = false;

	filterGroups: any;
	showFilterGroups = false;
	showFilterGroupsList = false;

	userSearchModel = '';
	allFilteredUsers: any;
	filteredUsers: any;
	users: any;
	showSendTo = false;
	showSendToSearchbar = false;
	showSendToList = true;

	absence_action_color: string;

	showSendToGroups = false;
	showSendToGroupsList = true;
	sendToGroupsWarning: any;
	sendToGroups: any;

	sendToOne = false;
	sendToGroupsOne = false;
	hasDocs = false;

	//------------------CHECKBOXES AND DATE------------------------------

	beNotified = false;
	showCheckboxBeNotified = false;

	sendEmail = false;
	showCheckboxSendEmail = false;

	actionLimitDate: any;
	actionLimitDateWarning: any;
	showActionLimitDate = false;
	factionLimitDate = false;
	minActionLimitDate: any;
	maxActionLimitDate: any;

	returnReceipt = false;
	showCheckboxReturnReceipt = false;

	// -----------------------WARNINGS (TO ACTIVATE CSS CLASS)-----------------------

	actionLimitDateWarningClass = false;
	usersWarningClass = false;
	sendToGroupsWarningClass = false;
	usersOrGroupWarningClass = false;

	constructor(
		public doc: Document,
		public navCtrl: NavController,
		public navParams: NavParams,
		public events: Events,
		public translateService: TranslateService,
		public alertCtrl: AlertController,
		public actionService: ActionService,
		public documentService: DocumentService,
		public userService: UserService,
		public configService: ConfigService,
		public fileService: FileService,
		public consoleService: ConsoleService,
		public loading: Loading,
		public toast: Toast,
		public alert: Alert,
		public actionSheetCtrl: ActionSheetController,
		public actionSheet: ActionSheet,
		public nav: IonNav
	) {

		this.consoleService.log('ActionPage',this.configService.getAllVar());

		if (this.navParams.get('params')) {

			this.consoleService.log('ActionPage','params');

			const params = this.navParams.get('params');

			if ('object' === typeof params.actions) {

				this.items = params.actions;

				this.consoleService.log('ActionPage','Received params with actions', this.items);

				if ('number' === typeof params.index) {						//simple operation

					this.consoleService.log('ActionPage','index', params.index);
					this.index = params.index;
					this.document = this.items[this.index].document;
					this.forwardAll = false;
					this.executeAll = false;

					this.note = this.items[this.index].note;
					if (!this.note) {
						this.note = '';
					}

					this.consoleService.log('ActionPage','document simple op', this.document);

					if ('number' === typeof params.idcodigo) {

						this.consoleService.log('ActionPage','idcodigo');

						if (71 == params.idcodigo || 73 == params.idcodigo) { // forward action
							this.idcodigo = params.idcodigo;

						} else {
							this.consoleService.log('ActionPage','Accessing action');
						}
					}


				} else {													//massive operation


					this.document = undefined;

					this.consoleService.log('ActionPage','params.idcodigo', params.idcodigo);
					this.consoleService.log('ActionPage','typeof params.idcodigo', typeof params.idcodigo);

					if ('number' === typeof params.idcodigo) {

						if (71 == params.idcodigo || 73 == params.idcodigo) { // forward actions
							this.idcodigo = params.idcodigo;
							this.consoleService.log('ActionPage','foward all');
							this.forwardAll = true;
						} else {

							this.consoleService.log('ActionPage','Error: idcodigo is not 71 or 73');
						}

					} else {												// execute actions
						this.consoleService.log('ActionPage','idcodigo not defined so execute all');
						this.executeAll = true;
						this.items = params.actions;
						this.index = 0;

					}
				}

			} else if ('object' === typeof params.document) {

				this.document = params.document;
				this.items = undefined;
				this.index = undefined;

				this.consoleService.log('ActionPage','Received params with document', this.document);

				this.forwardAll = false;
				this.executeAll = false;

				if ('number' === typeof params.idcodigo) {

					if (71 == params.idcodigo || 73 == params.idcodigo) { // forward document
						this.idcodigo = params.idcodigo;
						this.consoleService.log('ActionPage','idcodigo', this.idcodigo);
					} else {

						this.consoleService.log('ActionPage','Error: idcodigo is not 71 or 73');
					}

				} else {
					this.consoleService.log('ActionPage','Error: expected params.idcodigo defined');
				}

			} else if ('object' === typeof params.documents) {

				this.documents = params.documents;
				this.document = undefined;
				this.consoleService.log('ActionPage','Received params with documents', this.documents);

				this.items = undefined;
				this.index = undefined;
				this.executeAll = false;

				if ('number' === typeof params.idcodigo) {

					if (71 == params.idcodigo || 73 == params.idcodigo) { // forward documents
						this.consoleService.log('ActionPage','forward all');
						this.idcodigo = params.idcodigo;
						this.forwardAll = true;

					} else {

						this.consoleService.log('ActionPage','Error: idcodigo is not 71 or 73');
					}

				} else {
					this.consoleService.log('ActionPage','Error: expected params.idcodigo defined');
				}

			} else {
				this.consoleService.log('ActionPage','Error: did not receive params with actions, document or documents');
			}

			this.actionForm();

		} else {
			this.consoleService.log('ActionPage','Error: did not receive params');
		}
	}

	ionViewWillLeave() {

		if (this.loading.isOn) {
			this.loading.dismiss();
		}

	}


	actionForm(): any {

		let idaccaorev: undefined;

		this.consoleService.log('ActionPage','actionform');

		if (undefined != this.items && undefined != this.index) {
			if ('object' == typeof this.items[this.index]) {
				if ('number' === typeof this.items[this.index].idaccaorev) {
					idaccaorev = this.items[this.index].idaccaorev;

				}
			}
		} else {
			this.consoleService.log('ActionPage','items undefined');
		}


		this.finding = true;

		this.consoleService.log('ActionPage','actionform service');
		this.actionService.actionForm({
			idaccaorev,
			idcodigo: this.idcodigo,
			iddoc: (this.document === undefined) ? undefined: this.document.iddoc
		}).subscribe({
			next: (data: any) => {

				this.processActionComponents(data);

				this.finding = false;
			},
			error: err => {
				this.finding = false;
			},
			complete: () => {
			}
		});
	}

	async processActionComponents(data: any) {

		this.consoleService.log('ActionPage','processActionComponents', data);

		this.content.scrollToTop();

		this.cleanActionComponents();
		this.cleanWarningClasses();
		this.cleanWarningMessages();

		let date = new Date();
		date.setDate(date.getDate() + 1);
		this.minActionLimitDate = date.toISOString().slice(0, 10);
		this.maxActionLimitDate = new Date(new Date().setFullYear(new Date().getFullYear() + 10)).toISOString().slice(0, 10);

		date = new Date();
		date.setDate(date.getDate() + 30);
		this.actionLimitDate = date.toISOString().slice(0, 10);


		if (data.config) {

			for (const r of data.results) {
				this.results.push(r);
			}

			if (data.priorityflags) {
				//this.optionsForPopover = Object.assign(this.optionsForPopover, {priorityflags: data.priorityflags});
				this.priorityflags = data.priorityflags;
			}

			//action results
			for (let i = 0; i < this.sizeOf(this.results); i++) {
				this.resultButton.push({
					result: this.results[i].resultado,
					type: this.results[i].idaction_assignment_type,
					id: this.results[i].idmultiplos
				});
			}


			this.readingactions = data.readingactions;

			if (data.var_action_config) {
				this.absence_action_color = data.var_action_config.absence_action_color;
			}

			//document info
			if (data.document) {

				this.consoleService.log('ActionPage','data.document exists');

				if ('object' === typeof data.document && !this.executeAll && !this.forwardAll) {
					this.document = Object.assign(this.document, data.document[0]);
					this.consoleService.log('ActionPage','iddoc', this.document.iddoc, 'docid', data.document[0].docid);
					if (this.document.iddoc != data.document[0].docid) {
						this.document.iddoc = data.document[0].docid;
						this.consoleService.log('ActionPage','entao guarda docid no iddoc');
					}

				}

			}


			//user predefined comments
			this.userPredefinedComments = data.userpredefinedcomments;
			if (this.sizeOf(this.userPredefinedComments)) {
				this.showUserPredefinedComments = true;
			}


			//action components
			for (let a of data.config) {

				this.components[a.nome] = a;

				if (a.nome == 'esquema_cronologico') {
					if (a.link_mandatory == 't') {
						this.actionMandatoryComponents.push(a);
						this.linkChronologicalScheme.warningMessage = a.customwarning;
					}
				} else if (a.nome == 'documento') {

					if (a.link_mandatory == 't') {
						this.actionMandatoryComponents.push(a);

						this.linkReadDocument.check = false;
						this.linkReadDocument.warningMessage = a.customwarning;

					}
				}

				else if (a.nome == 'predefined_comment') {
					for (const b of a.vars) {
						if ('predefinedcomments' == b.var_name) {
							const comments = b.var_value.split('\n');
							for (const c of comments) {
								this.predefinedComments.push({comment: c});
							}
							/*
							for (let c = 0; c < comments.length; c++) {
								this.predefinedComments.push({comment: comments[c]});
							}
							*/
						}
					}
					if (this.sizeOf(this.predefinedComments)) {
						this.showPredefinedComment = true;
					}

					if (a.link_mandatory == 't') {
						this.actionMandatoryComponents.push(a);
					}


				} else if (a.nome == 'comentario') {
					this.fillInComment.isActionComponent = true;

					if (undefined != this.items && undefined != this.index) {
						this.items[this.index].comentario = '';
					}

					this.fillInComment.value = '';

					if (a.link_mandatory == 't') {
						this.actionMandatoryComponents.push(a);
						this.fillInComment.warningMessage = a.customwarning;
					}

					for (const b of a.vars) {
						if ('texto_coment' == b.var_name) {
							this.fillInComment.value = b.var_value;
						}
					}

					if (undefined != this.items && undefined != this.index) {
						this.fillInComment.value = this.items[this.index].comentario;
					}

					if ('undefined' != typeof a.comentario) { //comment from action config
						this.fillInComment.value = a.comentario;
					}
				} else if (a.nome == 'docs_associados') {
					this.showAssociateddocs = true;
					this.associateddocs = a.associateddocuments;

					if ('object' === typeof this.associateddocs) {
						if (this.associateddocs.length > 5) {
							this.showAssociateddocsSearchbar = true;
						}
					}

					this.associateddocsall = this.associateddocs;
					if (0 == this.associateddocs.length) {
						this.hasDocs = true;
						this.showAssociateddocsForm = false;
					} else {
						this.hasDocs = false;
					}
				} else if (a.nome == 'comments_show_note_field') {
					this.showNote = true;

					if (undefined != this.items && undefined != this.index) {
						this.note = this.items[this.index].note;
					}

					this.showNoteForm = false;
					if (null != this.note && '' != this.note) {
						this.showNoteForm = true;
					}
				} else if (a.nome == 'notificar_utilizadores') {
					this.showNotifyusers = true;
					this.allNotifyUsers = a.users;

					if (this.sizeOf(this.allNotifyUsers) > 5) {
						this.showNotifyusersSearhcbar = true;
					}

					for (let u = 0; u < a.users.length; u++) {
						a.users[u] = Object.assign(a.users[u], {checked: false});
					}
				} else if (a.nome == 'assunto_email') {
					this.fillInSubject.isActionComponent = true;

					if ('undefined' != typeof this.document) {

						this.translateService.get('Read document').subscribe(
							value => {
								this.fillInSubject.value = this.document.titulo + ' -> ' + value;
							}
						)

					}

					if (a.link_mandatory == 't') {
						this.actionMandatoryComponents.push(a);
						this.fillInSubject.warningMessage = a.customwarning;
					}

					for (const b of a.vars) {
						if ('enc31email_subject' == b.var_name) {
							if ('' != b.var_value) {
								this.fillInSubject.value = b.var_value;
							}
						}
					}
				} else if (a.nome == 'showlastcomment') {
					this.showLastcomment = true;

					for (let b of a.vars) {
						if ('expand_last_comment_option' == b.var_name) {
							if (1 == b.var_value) {
								this.showLastcommentForm = true;
							}
						}
					}
				} else if (a.nome == 'checkbox_envio_emails') {
					this.showCheckboxSendEmail = true;
					this.sendEmail = true;
				} else if (a.nome == 'done_action_notification') {

					this.showCheckboxBeNotified = true;

				} else if (a.nome == 'return_receipt') {

					this.showCheckboxReturnReceipt = true;

				} else if (a.nome == 'tempo_limite_accoes') {
					this.showActionLimitDate = true;
					if (a.link_mandatory == 't') {
						this.actionMandatoryComponents.push(a);
						this.actionLimitDateWarning = a.customwarning;
					}
				} else if (a.nome == 'define_adhoc_action_time_limit') {
					this.showActionLimitDate = true;
					if (a.link_mandatory == 't') {
						this.actionMandatoryComponents.push(a);
						this.actionLimitDateWarning = a.customwarning;
					}
				} else if (a.nome == 'acesso') {
					this.showCommentAccess = true;
					this.commentAccessUsers = a.groups;
					for (let i = 0; i < this.commentAccessUsers.length; i++) {
						if (this.commentAccessUsers[i].checked) {

						} else {
							this.commentAccessUsers[i] = Object.assign(this.commentAccessUsers[i], {checked: false});
						}
					}

					if (a.link_mandatory == 't') {
						this.actionMandatoryComponents.push(a);
					}
				} else if (a.nome == 'filtro_grupos') {
					this.showFilterGroups = true;
					this.filterGroups = a.groups;
					for (let i = 0; i < this.filterGroups.length; i++) {
						if (this.filterGroups[i].checked) {

						} else {
							this.filterGroups[i] = Object.assign(this.filterGroups[i], {checked: false});
						}

					}

					if (a.link_mandatory == 't') {
						this.actionMandatoryComponents.push(a);
					}
				} else if (a.nome == 'filtro_grupos_maximizado') {
					this.showFilterGroupsList = true;
				} else if (a.nome == 'encaminhar') {
					this.showSendTo = true;
					this.users = a.users;

					if (this.users.length > 5) {
						this.showSendToSearchbar = true;
					}

					for (let i = 0; i < this.users.length; i++) {
						if (this.users[i].checked) {

						} else {
							this.users[i] = Object.assign(this.users[i], {checked: false});
						}
					}

					this.filteredUsers = this.users;
					this.allFilteredUsers = this.users;

					if (a.link_mandatory == 't') {
						this.actionMandatoryComponents.push(a);
					}

					for (const b of a.vars) {
						if ('onlyoneuser' == b.var_name) {
							if (1 == b.var_value) {
								this.sendToOne = true;
							}
						}
					}

				} else if (a.nome == 'encaminhar_exterior') {

					this.fillInEmail.isActionComponent = true;
					if (a.link_mandatory == 't') {
						this.actionMandatoryComponents.push(a);
						this.fillInEmail.warningMessage = a.customwarning;
					}

				} else if (a.nome == 'encaminhar_cc') {

					this.fillInEmailCc.isActionComponent = true;
					if (a.link_mandatory == 't') {
						this.actionMandatoryComponents.push(a);
						this.fillInEmailCc.warningMessage = a.customwarning;
					}

				} else if (a.nome == 'encaminhar_bcc') {

					this.fillInEmailBcc.isActionComponent = true;
					if (a.link_mandatory == 't') {
						this.actionMandatoryComponents.push(a);
						this.fillInEmailBcc.warningMessage = a.customwarning;
					}
				} else if (a.nome == 'from_identity') {
					this.showEmailIdentity = true;
				} else if (a.nome == 'forward_group_selection') {
					this.showSendToGroups = true;
					this.sendToGroups = a.groups;
					for (let i = 0; i < this.sendToGroups.length; i++) {
						if (this.sendToGroups[i].checked) {

						} else {
							this.sendToGroups[i] = Object.assign(this.sendToGroups[i], {checked: false});
						}

					}
					if (a.link_mandatory == 't') {
						this.actionMandatoryComponents.push(a);
						this.sendToGroupsWarning = a.customwarning;
					}

					for (const b of a.vars) {
						if ('onlyonegroup' == b.var_name) {
							if (1 == b.var_value) {
								this.sendToGroupsOne = true;
							}
						}
					}
				}
				// ---------------------SUB ACTIONS--------------------
				else if (a.tipo == 'SUBACCAO') {

					if (!this.executeAll && !this.forwardAll) {
						switch (a.nome) {
							case 'sub_action_read_document':
								this.subactions.push(a);
								if (a.link_mandatory == 't') {
									this.actionMandatoryComponents.push(a);
									a = Object.assign(a, {checked: false});
								}
								break;
							case 'subaction_forward_doc':
							case 'subaction_forward_doc_pending_action':
								a = Object.assign(a, {checked: true});
								if (a.link_mandatory == 't') {
									a = Object.assign(a, {checked: false});
								}
								this.subactions.push(a);
								break;
							case 'ligar_doc':
								this.subactions.push(a);
								if (a.link_mandatory == 't') {
									a = Object.assign(a, {checked: false});
									this.actionMandatoryComponents.push(a);
								}
								break;
							case 'associar_doc':
								this.subactions.push(a);
								if (a.link_mandatory == 't') {
									a = Object.assign(a, {checked: false});
									this.actionMandatoryComponents.push(a);
								}
								break;
						}
					}
				}
			}

			if (this.subactions.length > 0) {
				this.hasSubActions = true;
			}

			this.consoleService.log('ActionPage',this.components);


			if (data.userexecutingsameaction) {
				const translation = {title: '', ok: '', cancel: ''};
				// Next line of Code was too long - tslint fix
				const strToLong =  'The user {{value}} is performing the same action.';
				this.translateService.get(strToLong, {value: data.userexecutingsameaction.nome}).subscribe(
					value => {
						translation.title = value;
					}
				);
				this.translateService.get('Do you want to proceed?').subscribe(
					value => {
						translation.title += ' ' + value;
					}
				);
				this.translateService.get(translation.title).subscribe(
					value => {
						translation.title = value;
					}
				);
				this.translateService.get('Cancel').subscribe(
					value => {
						translation.cancel = value;
					}
				);
				this.translateService.get('ok').subscribe(
					value => {
						translation.ok = value;
					}
				);
				const confirm = await this.alertCtrl.create({
					header: translation.title,
					buttons: [
						{
							text: translation.cancel,
							handler: () => {
								this.navCtrl.pop();
							}
						},
						{
							text: translation.ok.toUpperCase(),
							handler: () => {
							}
						}
					]
				});
				await confirm.present();
			}
		} else {
			const translation = {title: '', ok: ''};
			this.translateService.get('The action has already been executed.').subscribe(
				value => {
					translation.title = value;
				}
			);
			this.translateService.get('ok').subscribe(
				value => {
					translation.ok = value;
				}
			);
			const confirm = await this.alertCtrl.create({
				header: translation.title,
				buttons: [
					{
						text: translation.ok.toUpperCase(),
						handler: () => {
							this.navCtrl.pop();
						}
					}
				]
			});
			await confirm.present();
		}
	}

	// -------------------- REUSABLE FUNTIONS--------------------------------

	isEmail(email: any): boolean{

			if(typeof email !== 'undefined'){

				if (email.match(/^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z0-9]{2,25}(,\s?[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z0-9]{2,25})*$/)){
					return true;

				} else {
						return false;
				}

			} else {
					return false;
			}

	}

	validateMultipleEmails(emails) {

    if (typeof emails != 'undefined') {

		emails = emails.replace(/\s/g, '');
		emails = emails.replace(';', ',');

        let email = emails.split(',');
		for (const i of email) {
			if (i != '') {
				if (!this.isEmail(i)) {
					return false;
				}
			}
		}
		/*
        for (var i = 0; i < email.length; i++) {
            if (email[i] != '') {
                if (!this.isEmail(email[i])) {
                    return false;
                }
            }
        }
		*/
    }

    return true;
}

	sizeOf(item: any) {

		let size = 0;
		let key;
			for ( key in item) {

				if (item.hasOwnProperty(key)) {size++;}

			}
		return size;
	}

	giveMeIdCheckedOf(item){

		const itemChecked = [];
		for ( let i = 0; i<this.sizeOf(item); i++){
			if (typeof item[i].checked !== 'undefined') {
				if(item[i].checked) {
					if (typeof item[i].idgroups !== 'undefined') {
						itemChecked.push(item[i].idgroups);
					}
					else if ((typeof item[i].utilizador !== 'undefined')){
						itemChecked.push(item[i].utilizador);
					}

					else if ((typeof item[i].idaccaorev !== 'undefined')){
						itemChecked.push(item[i].idaccaorev);
					}

					else if ((typeof item[i].iddoc !== 'undefined')){
						itemChecked.push(item[i].iddoc);
					}
				}
			}
		}
		return itemChecked;

	}

	giveMeNameCheckedOf(item){

		const itemChecked = [];
		for ( let i = 0; i<this.sizeOf(item); i++){
			if (typeof item[i].checked !== 'undefined') {
				if(item[i].checked) {
					if (typeof item[i].name !== 'undefined') {
						itemChecked.push(item[i].name);
					} else if (typeof item[i].nome !== 'undefined') {
						itemChecked.push(item[i].nome);
					}
				}
			}
		}
		return itemChecked;

	}

	cleanActionComponents(){

		this.actionMandatoryComponents = [];
		this.results = [];
		this.resultButton = [];
		this.subactions = [];
		this.hasSubActions = false;
		this.readingactions = [];
		this.absence_action_color = '';
		this.userPredefinedComments = [];
		this.linkChronologicalScheme.check = false;
		this.linkReadDocument.check = false;
		this.predefinedComments = [];
		this.showPredefinedComment = false;

		this.associateddocs = [];
		this.associateddocsall = [];
		this.showAssociateddocs = false;

		this.showNote = false;
		this.note = '';
		this.showNoteForm = false;

		this.showNotifyusers = false;
		this.allNotifyUsers = [];

		this.showLastcomment = false;
		this.showLastcommentForm = false;

		this.showCheckboxSendEmail = false;
		this.showCheckboxBeNotified = false;
		this.showCheckboxReturnReceipt = false;

		this.showActionLimitDate = false;
		this.actionLimitDate = '';

		this.commentAccessUsers = [];

		this.filterGroups = [];
		this.showFilterGroups = false;
		this.showFilterGroupsList = false;
		this.showSendTo = false;
		this.users = [];
		this.filteredUsers = [];
		this.allFilteredUsers = [];
		this.filterGroups = [];
		this.sendToOne = false;
		this.showSendToGroups = false;
		this.sendToGroupsOne = false;

		this.fillInComment.isActionComponent = false;
		this.fillInComment.value = '';

		this.fillInSubject.isActionComponent = false;
		this.fillInSubject.value = '';

		this.fillInEmail.isActionComponent = false;
		this.fillInEmail.value = '';
		this.fillInEmailCc.isActionComponent = false;
		this.fillInEmailCc.value = '';
		this.fillInEmailBcc.isActionComponent = false;
		this.fillInEmailBcc.value = '';
		this.showEmailIdentity = false;

	}

	cleanWarningClasses(){

		this.fillInComment.showWarningClass = false;
		this.fillInSubject.showWarningClass = false;
		this.actionLimitDateWarningClass = false;
		this.fillInEmail.showWarningClass = false;
		this.fillInEmailCc.showWarningClass = false;
		this.fillInEmailBcc.showWarningClass = false;
		this.usersWarningClass = false;
		this.usersOrGroupWarningClass = false;
		this.sendToGroupsWarningClass = false;
		this.linkChronologicalScheme.showWarningClass = false;
		this.linkReadDocument.showWarningClass = false;

	}

	cleanWarningMessages(){

		this.fillInComment.warningMessage = '';
		this.fillInSubject.warningMessage = '';
		this.actionLimitDateWarning = '';
		this.fillInEmail.warningMessage = '';
		this.fillInEmailCc.warningMessage = '';
		this.fillInEmailBcc.warningMessage = '';
		this.sendToGroupsWarning = '';
		this.linkChronologicalScheme.warningMessage = '';
		this.linkReadDocument.warningMessage = '';

	}

	actionLimitDateOnChange(){
		this.factionLimitDate = true;
	}

	cancelActionLimitDate() {
		this.factionLimitDate = false;
	}


	showAbsenceInfo(absence: any, username: string){

		this.consoleService.log('ActionPage','absence', absence);

		const translation = {isabsentuntil: 'is absent until', reason: 'Reason', ok: 'ok'};
		let message = '';

		this.translateService.get('is absent until').subscribe(
			value => {
				translation.isabsentuntil = value;
			}
		);

		this.translateService.get('Reason').subscribe(
			value => {
				translation.reason = value;
			}
		);

		this.translateService.get('ok').subscribe(
			value => {
				translation.ok = value ;
			}
		);

		// MESSAGE IS SOMETHING LIKE: ' username is absent until YYYY-MM-DD'
		message = username + ' ' + translation.isabsentuntil + ' ' + absence.dtend;

		// IF REASON IS SET MESSAGE IS SOMETHING LIKE: 'username is absent until YYYY-MM-DD Reason xxxx'
		if(absence.treson){
			message = message + '. ' + translation.reason + ': ' + absence.treson;
		}

		this.toast.show({
			message,
			cssClass: 'info',
			showCloseButton: true,
			closeButtonText: translation.ok.toUpperCase(),
			duration: '7000',
			dismissOnPageChange: false,
			bodyOutputType: 'trustedHtml'
		});

	}

	deleteActionConfig(){
		if (this.configService.getVar('actionConfigToSave')) {
			const actionConfigToSave = this.configService.getVar('actionConfigToSave');

			if (actionConfigToSave.idaccaorev > 0 ) {
				if ((actionConfigToSave.idaccaorev == this.items[this.index].idaccaorev)) {
					this.consoleService.log('ActionPage','deleted');
					this.configService.deleteVar('actionConfigToSave');
				}
			}

			this.consoleService.log('ActionPage',this.configService.getAllVar());
		}
	}

	//------------------- SEARCH INSIDE FILTERS -------------------------------

	getFilteredUsers(ev: any) {
		this.filteredUsers = this.allFilteredUsers;
		// set val to the value of the ev target
		const val = ev.target.value;

		this.userSearchModel = val;

		// if the value is an empty string don't filter the items
		if (val && val != '' && val.trim() != '') {
			this.filteredUsers = this.filteredUsers.filter((item) => (item.nome.toLowerCase().indexOf(val.toLowerCase()) > -1));
		}
	}

	getFilteredNotifyusers(ev: any): any {
		this.components.notificar_utilizadores.users = this.allNotifyUsers;
		// set val to the value of the ev target
		const val = ev.target.value;

		// if the value is an empty string don't filter the items
		if (val && val != '' && val.trim() != '') {
			this.components.notificar_utilizadores.users = this.components.notificar_utilizadores.users
			.filter((item) => (item.nome.toLowerCase().indexOf(val.toLowerCase()) > -1));
		}
	}

	getAssociateddocs(ev: any) {
		// Reset items back to all of the items
		this.associateddocs = this.associateddocsall;

		// set val to the value of the searchbar
		let val = StringUtils.replaceSpecialChars(ev.target.value);

		// if the value is an empty string don't filter the items
		if (val && val.trim() != '') {
			this.associateddocs = this.associateddocs
			.filter(
				(associateddoc) => (StringUtils.replaceSpecialChars(associateddoc.titulo).toLowerCase().indexOf(val.toLowerCase()) > -1)
			);
		}
	}


	// ---------------------------DROPDOWNS LOGIC----------------------------

	toggleCommentAccessFilter() {

		if (this.showCommentAccessList) {
			this.showCommentAccessList = false;
		} else {
			this.showCommentAccessList = true;
		}
	}

	toggleNote(): void {
		this.showNoteForm = !this.showNoteForm;
	}

	toggleNotifyusersParticipated(): void {
		this.fNotifyusersParticipated = !this.fNotifyusersParticipated;

		let utp = 0;
		let u = 0;
		if (this.fNotifyusersParticipated){ // IF NOTIFY PARTICIPANTS IN WORKFLOW IS SELECTED SET USERS THAT PARTICIPATED TRUE
			this.consoleService.log('ActionPage','fNotifyusersParticipated');
			this.consoleService.log('ActionPage','','',this.fNotifyusersParticipated);
			this.consoleService.log('ActionPage','this.components.notificar_utilizadores.usersthatparticipated');
			this.consoleService.log('ActionPage',this.components.notificar_utilizadores.usersthatparticipated);
			this.consoleService.log('ActionPage','this.components.notificar_utilizadores.users');
			this.consoleService.log('ActionPage',this.components.notificar_utilizadores.users);
			for(utp = 0; utp<this.components.notificar_utilizadores.usersthatparticipated.length; utp++ ){

				for (u = 0; u<this.components.notificar_utilizadores.users.length; u++ ){

					if (this.components.notificar_utilizadores.usersthatparticipated[utp].utilizador == this.components.notificar_utilizadores.users[u].utilizador){

						this.components.notificar_utilizadores.users[u].checked = true;

					}
				}

			}

		} else { // IF NOTIFY PARTICIPANTS IN WORKFLOW IS NOT SELECTED SET USERS THAT PARTICIPATED FALSE

			for(utp = 0; utp<this.components.notificar_utilizadores.usersthatparticipated.length; utp++){

				for (u = 0; u<this.components.notificar_utilizadores.users.length; u++){

					if (this.components.notificar_utilizadores.usersthatparticipated[utp].utilizador == this.components.notificar_utilizadores.users[u].utilizador){

						this.components.notificar_utilizadores.users[u].checked = false;

					}
				}

			}

		}
	}


	toggleNotifyusersAdhocaction(): void {
		this.fNotifyusersAdhocaction = !this.fNotifyusersAdhocaction;
	}

	toggleAssociateddocs(): void {
		if (this.associateddocs.length > 0){
			this.showAssociateddocsForm = !this.showAssociateddocsForm;
		}

	}

	// ------------------ CHECKBOXES LOGIC----------------------

	beNotifiedToogle() {

		this.beNotified = !this.beNotified;
	}

	sendEmailToogle() {

		this.sendEmail = !this.sendEmail;
	}

	returnReceiptToogle(){
		this.returnReceipt = !this.returnReceipt;
	}

	// --------- CHECK USERS RESTRICTION TO UNSET CSS WARNING CLASS ------------

	checkUsersRestriction(): boolean {

		for ( let i = 0; i<this.sizeOf(this.filteredUsers); i++){
			if (typeof this.filteredUsers[i].checked !== 'undefined') {
				if(this.filteredUsers[i].checked) {
					return true;
				}
			}
		}

		return false;
	}

	checkSendToGroupsRestriction(): boolean {

		for ( let i = 0; i<this.sizeOf(this.sendToGroups); i++){
			if (typeof this.sendToGroups[i].checked !== 'undefined') {
				if(this.sendToGroups[i].checked) {
					return true;
				}
			}
		}

		return false;
	}

	// ------- FILTER GROUPS LOGIC ----

	showUser(user: any): boolean {

		this.consoleService.log('ActionPage',user);
		if( !this.giveMeIdCheckedOf(this.filterGroups).length  || (this.contains(this.giveMeIdCheckedOf(this.filterGroups) , user.groups)) ){
			return true;
		} else {
			return false;
		}
	}

	refreshSendToUser(filterGroups: any){

		if( this.sizeOf(this.giveMeIdCheckedOf(filterGroups))  ) {
			this.filteredUsers = [];
			this.allFilteredUsers = [];

			for(let id=0; id<this.sizeOf(filterGroups); id++){

				if(filterGroups[id].checked){
					for (let user=0; user<this.sizeOf(filterGroups[id].users); user++){

						for (let iduser=0; iduser<this.sizeOf(this.users); iduser++){

							if ( (filterGroups[id].users[user] == this.users[iduser].utilizador) ) {

								if(this.filteredUsers.indexOf(this.users[iduser]) == -1){
									this.filteredUsers.push(this.users[iduser]);
									this.allFilteredUsers.push(this.users[iduser]);
								}

							}
						}

					}
				}
			}

		} else { //IF NONE OF THE GROUPS ARE SELECTED SHOW ALL USERS
			this.filteredUsers = this.users;
			this.allFilteredUsers = this.users;
		}

	}

	contains(array: any, array2: any) { // TO CHECK IF AT LEAST ONE NUMBER OF ARRAY2 BELONGS TO ARRAY

		for ( let i = 0; i<this.sizeOf(array); i++){
			for ( let j = 0; j<this.sizeOf(array2); j++){
				if(array[i]==array2[j]){
					return true;
				}
			}
		}
		return false;
	}

	userChange(data: any): void {
		data.checked = !data.checked;

		if (true === this.sendToOne) {
			for (let i = 0; i < this.filteredUsers.length; i++) {
				if (data.utilizador != this.filteredUsers[i].utilizador) {
					this.filteredUsers[i].checked = false;
				}
			}

			this.showSendToList = false;
			timer(1).subscribe(
				() => {
					this.showSendToList = true;
				}
			);
		}
	}

	groupChange(data: any): void {
		data.checked = !data.checked;

		if (true === this.sendToGroupsOne) {
			for (let i = 0; i < this.sendToGroups.length; i++) {
				if (data.idgroups != this.sendToGroups[i].idgroups) {
					this.sendToGroups[i].checked = false;
				}
			}

			this.showSendToGroups = false;
			timer(1).subscribe(
				() => {
					this.showSendToGroups = true;
				}
			);
		}

	}

	notifyuserChange(data: any): void {
		data.checked = !data.checked;
		this.consoleService.log('ActionPage',data);
	}

	// save and remove comments, note

	saveComment(): void {
		let params = {idaccaorev: this.items[this.index].idaccaorev, comment: this.fillInComment.value};
		this.actionService.savecomment(params).subscribe(
			data => {
			},
			err => {
			},
			() => {
			}
		);
	}

	removeComment(): void {
		this.fillInComment.value = '';

		let params = {idaccaorev: this.items[this.index].idaccaorev};
		this.actionService.removecomment(params).subscribe(
			data => {
			},
			err => {
			},
			() => {
			}
		);
	}

	saveNote(): void {
		let params = {idaccaorev: this.items[this.index].idaccaorev, note: this.note};
		this.actionService.savenote(params).subscribe(
			data => {
			},
			err => {
			},
			() => {
			}
		);
	}

	removeNote(): void {
		this.note = '';

		let params = {idaccaorev: this.items[this.index].idaccaorev};
		this.actionService.removenote(params).subscribe(
			data => {
			},
			err => {
			},
			() => {
			}
		);
	}

	onPredefinedCommentChange(comment: string): void {
		this.fillInComment.value = (this.fillInComment.value + '\n' + comment).trim();
	}

	goToPredefinedcommentsChoise() {
		let translation = {cancel: '', ok: '', comment: ''};
		this.translateService.get('Cancel').subscribe(
			value => {
				translation.cancel = value;
			}
		);
		this.translateService.get('ok').subscribe(
			value => {
				translation.ok = value;
			}
		);
		this.translateService.get('Comment').subscribe(
			value => {
				translation.comment = value;
			}
		);

		let comments;
		let arr: Array<any> = [];

		const title = translation.comment;
		
		for (const comment of this.userPredefinedComments) {
			const inputs = {
				type: 'radio',
				label: comment.comment,
				value: comment.comment,
			};
			arr.push(inputs);
		}
		const button = [translation.cancel, {
			text: translation.ok.toUpperCase(),
			handler: data => {
				this.fillInComment.value = (this.fillInComment.value + '\n' + data).trim();
			}
		}];
		this.alertCtrl.create({
			header: title,
			inputs: arr,
			buttons: button
		}).then(evt => evt.present());
	}

	// ---------------

	checkWarnings(result: any): boolean {

		this.consoleService.log('ActionPage','check', result);

		let valid = true;
		let message = '';
		for (let el of this.subactions) {
			if ('t' === el.link_mandatory) {
				if (el.checked) {

				} else {
					valid = false;
					message += '<br>' + el.customwarning;
				}
			}
		}

		if(		// IF  TO SEE chronologicalscheme ISN'T MANDATORY OR (TO SEE chronologicalscheme IS MANDATORY AND chronologicalscheme WAS SEEN) OR (TO SEE chron scheme IS MANDATORY BUT IT IS A MASSIVE OPERATION )
			((!this.linkChronologicalScheme.warningMessage) || (this.linkChronologicalScheme.warningMessage && this.linkChronologicalScheme.check) || (this.linkChronologicalScheme.warningMessage && (this.executeAll || this.forwardAll))	)

				// IF  TO SEE document comment ISN'T MANDATORY OR (TO SEE document IS MANDATORY AND WAS SEEN ) OR (TO SEE document IS MANDATORY BUT IT IS A MASSIVE OPERATION )
			&& ((!this.linkReadDocument.warningMessage) || (this.linkReadDocument.warningMessage && this.linkReadDocument.check) || (this.linkReadDocument.warningMessage && (this.executeAll || this.forwardAll))	)

				// IF  TO FILL comment ISN'T MANDATORY OR (TO FILL comment IS MANDATORY AND comment IS FILLED)
			&& ((!this.fillInComment.warningMessage) || (this.fillInComment.warningMessage && this.fillInComment.value)	)

				// IF  TO INSERT subject ISN'T MANDATORY OR (TO INSERT subject IS MANDATORY AND subject IS INSERTED)
			&& ((!this.fillInSubject.warningMessage) || (this.fillInSubject.warningMessage && this.fillInSubject.value)	)

				// IF  TO INSERT entity ISN'T MANDATORY OR (TO INSERT entity IS MANDATORY AND entity IS INSERTED)
			//&&	(	(!this.entityWarning) ||	(this.entityWarning && this.entity)	)

				// IF  TO INSERT entities ISN'T MANDATORY OR (TO INSERT entities IS MANDATORY AND entities IS INSERTED)
			//&&	(	(!this.entitiesWarning) ||	(this.entitiesWarning && this.entities)	)

				// IF  TO INSERT email ISN'T MANDATORY OR (TO INSERT email IS MANDATORY AND email IS INSERTED)
			&& ((!this.fillInEmail.warningMessage) || (this.fillInEmail.warningMessage && this.fillInEmail.value )	)

				// IF  TO INSERT email CC ISN'T MANDATORY OR (TO INSERT email CC IS MANDATORY AND email CC IS INSERTED)
			&& ((!this.fillInEmailCc.warningMessage) || (this.fillInEmailCc.warningMessage && this.fillInEmailCc.value )	)

				// IF  TO INSERT email BCC ISN'T MANDATORY OR (TO INSERT email BCC IS MANDATORY AND email BCC IS INSERTED)	VALIDATE
			&& ((!this.fillInEmailBcc.warningMessage) || (this.fillInEmailBcc.warningMessage && this.fillInEmailBcc.value  )	)

				// IF AT LEAST ONE USER OR GROUP IS SELECTED AND IT ITS A FORWARD ACTION OR IT ISN'T A FORWARD ACTION
			&&	(   ( (this.checkUsersRestriction() || this.checkSendToGroupsRestriction()) && (this.idcodigo==71 || this.idcodigo==73)) || !this.idcodigo)

				//IF IT IS A FORWARD RESULT ACTION AT LEAST ONE USER OR GROUP MUST BE SELECTED
			&& (result.type!=2 || (result.type==2 && (this.checkUsersRestriction() || (this.checkSendToGroupsRestriction()) )) )

				// IF TO SELECT AT LEAST ONE GROUP ISN'T MANDATORY OR IT IS MANDATORY AND AT LEAST ONE IS SELECTED
			&&  (   (!this.sendToGroupsWarning) || (this.sendToGroupsWarning && this.checkSendToGroupsRestriction()  ) )

			&& (true === valid)

		) {
				return true;

		} else {

				//SHOW WARNINGS
				this.consoleService.log('ActionPage','WARNING');
				this.consoleService.log('ActionPage','result', result);

				//IF IT IS A FORWARD RESULT (ACTION) AND NONE OF USERS ARE SELECTED
				if (2 == result.type){

					if (this.showSendTo && !this.showSendToGroups) {
						if (!this.checkUsersRestriction()) {
							this.translateService.get('Please select at least one of the users to forward to').subscribe(
								value => {
								this.alertTranslations.userAlert = value;
								}
							);

							this.message+= '<br>' + this.alertTranslations.userAlert;
							this.usersWarningClass = true;
						}
					} else if (this.showSendTo && this.showSendToGroups) {

						if (!this.checkUsersRestriction() && !this.checkSendToGroupsRestriction()) {
							this.translateService.get('Please select at least one of the users or groups to forward to').subscribe(
								value => {
								this.alertTranslations.userOrGroupAlert = value;
								}
							);

							this.message+= '<br>' + this.alertTranslations.userOrGroupAlert;
							this.usersOrGroupWarningClass = true;

						}


					}

				}

				//IF CRON SCHEME WASN'T SEEN
				if (!this.linkChronologicalScheme.check && this.linkChronologicalScheme.warningMessage){

					this.message += '<br>' + this.linkChronologicalScheme.warningMessage;
					this.linkChronologicalScheme.showWarningClass = true;

				}

				//IF (LINK) DOCUMENT WASN'T SEEN
				if (!this.linkReadDocument.check && this.linkReadDocument.warningMessage){

					this.message += '<br>' + this.linkReadDocument.warningMessage;
					this.linkReadDocument.showWarningClass = true;

				}

				// IF COMMENT ISN'T FILLED
				if ((!this.fillInComment.value) && this.fillInComment.warningMessage){

					this.message += '<br>' + this.fillInComment.warningMessage;
					this.fillInComment.showWarningClass = true;

				}

				// IF SUBJECT ISN'T INSERTED
				if ((!this.fillInSubject.value) && this.fillInSubject.warningMessage){

					this.message += '<br>' + this.fillInSubject.warningMessage;
					this.fillInSubject.showWarningClass = true;
				}

				// IF LIMIT DATE ISN'T INSERTED
				if( (!this.actionLimitDate) && this.actionLimitDateWarning){

					this.message+= '<br>' + this.actionLimitDateWarning;
					this.actionLimitDateWarningClass = true;
				}

				// IF ENTITY ISN'T INSERTED
				/*if( (!this.entity) && this.entityWarning){

					this.message+= '<br>' + this.entityWarning;

				}
				// IF ENTITIES ISN'T INSERTED
				if( (!this.entities) && this.entitiesWarning){

					this.message+= '<br>' + this.entitiesWarning;

				}*/

				// IF EMAIL ISN'T INSERTED
				if ((!this.fillInEmail.value) && this.fillInEmail.warningMessage){

					this.message += '<br>' + this.fillInEmail.warningMessage;
					this.fillInEmail.showWarningClass = true;
				}

				// IF CC ISN'T INSERTED
				if ((!this.fillInEmailCc.value) && this.fillInEmailCc.warningMessage){

					this.message += '<br>' + this.fillInEmailCc.warningMessage;
					this.fillInEmailCc.showWarningClass = true;
				}

				// IF BCC ISN'T INSERTED
				if ((!this.fillInEmailBcc.value) && this.fillInEmailBcc.warningMessage){

					this.message += '<br>' + this.fillInEmailBcc.warningMessage;
					this.fillInEmailBcc.showWarningClass = true;

				}

				// IF NONE OF THE GROUPS IS SELECTED
				if( (!this.checkSendToGroupsRestriction()  ) && this.sendToGroupsWarning){

					this.message+= '<br>' + this.sendToGroupsWarning;
				}

				this.message += message;

				this.alert.show({title: this.message});
				// RESET MESSAGE
				this.message = '';
				return false;
		}
	}

	execute(result: any): void {

		this.cleanWarningClasses();

		if (this.checkWarnings(result)) {

			let idaccaorev = [];
			let idsdoc = [];

			if (71 == this.idcodigo || 73 == this.idcodigo) {

				if (undefined != this.document) {
					this.consoleService.log('ActionPage','execute () forward');
					idsdoc = this.document.iddoc;

				} else if (this.forwardAll && undefined != this.documents) {
					this.consoleService.log('ActionPage','execute () forward all');
					for (let document of this.documents) {
						idsdoc.push(document.iddoc);
					}
				}

			} else if (undefined != this.items && undefined != this.index) {

				if (this.executeAll) {
					this.consoleService.log('ActionPage','execute () exec all');
					for (let action of this.items) {
						idaccaorev.push(action.idaccaorev)
					}

				} else {
					this.consoleService.log('ActionPage','execute () simple exec');
					idaccaorev = this.items[this.index].idaccaorev;
					idsdoc = [this.items[this.index].docid];
				}

			}

			let notifyUsers: any = '';
			if ('undefined' !== typeof this.components.notificar_utilizadores && 'undefined' !== typeof this.components.notificar_utilizadores.users) {
				notifyUsers = this.giveMeIdCheckedOf(this.components.notificar_utilizadores.users);
			}

			if (!this.showActionLimitDate || !this.factionLimitDate) {
				this.actionLimitDate = '';
			}



			let params = {
				idaccaorev: idaccaorev,
				idaccaorevreading: ArrayUtils.unique(ArrayUtils.contert2simple(this.readingactions, 'idaccaorev')),
				actionResult: result,
				subject: this.fillInSubject.value,
				comment: this.fillInComment.value,
				commentAccess: this.giveMeIdCheckedOf(this.commentAccessUsers), // SEND ONLY THE CHECKED ONES
				createAdHocAction: this.fNotifyusersAdhocaction,
				notifyUsers: notifyUsers,
				sendToUsers: this.giveMeIdCheckedOf(this.filteredUsers),		// SEND ONLY THE CHECKED ONES
				sendToGroups: this.giveMeIdCheckedOf(this.sendToGroups),		// SEND ONLY THE CHECKED ONES
				email: this.fillInEmail.value,
				emailCc: this.fillInEmailCc.value,
				emailBcc: this.fillInEmailBcc.value,
				emailIdentity: this.emailIdentity,
				returnReceipt: this.returnReceipt,

				//ad-hoc forward
				iddoc           : idsdoc,
				idcodigo        : this.idcodigo,
				benotified      : this.beNotified,
				sendemail       : this.sendEmail,
				limitdate       : this.actionLimitDate,
				commentaccess   : this.giveMeIdCheckedOf(this.commentAccessUsers),
				forwardtousers  : this.giveMeIdCheckedOf(this.users),
				forwardtogroups : this.giveMeIdCheckedOf(this.sendToGroups)
			};

			//IF WAS MADE SOME ACTION CONFIGURATION (ex: signal the next action) ON POPOVER (MORE) ADD IT TO THE PARAMS
//			if(typeof this.configService.getVar('popoverConfigActionToSave') != 'undefined'){
//				let popoverConfigActionToSave = this.configService.getVar('popoverConfigActionToSave');
//				if(typeof popoverConfigActionToSave.idaccaorev != 'undefined'){
//					if(popoverConfigActionToSave.idaccaorev == this.items[this.index].idaccaorev){
//						params = Object.assign(params, this.configService.getVar('popoverConfigActionToSave'));
//					}
//				}
//
//
//			}

			if(this.configService.getVar('actionConfigToSave')){
				let actionConfigToSave = this.configService.getVar('actionConfigToSave');
				if(actionConfigToSave.idaccaorev > 0){
					if(actionConfigToSave.idaccaorev == idaccaorev){
						params = Object.assign(params, this.configService.getVar('actionConfigToSave'));
					}
				}

			}



			this.consoleService.log('ActionPage','','',params);

			this.loading.show({});

			this.actionService.execute(params).subscribe({
				next: (data: any) => {

					let error = data.error;
					this.consoleService.log('ActionPage','data');
					this.consoleService.log('ActionPage',error.code);

					if ('0' == error.code) {
						if (71 == this.idcodigo || 73 == this.idcodigo) {
							this.toast.show({message: 'Document successfully forwarded', cssClass: 'success', dismissOnPageChange: false});
						} else {
							this.toast.show({message: 'Action successfully performed', cssClass: 'success', dismissOnPageChange: false});
						}
						//IF ACTION WAS SUCCESSFULLY EXECUTED DELETE ACTION CONFIGURATION (ex: signal the next action)
						if (this.configService.getVar('actionConfigToSave')) {
							this.configService.deleteVar('actionConfigToSave');
						}



						let hasFollowingActions = data.hasFollowingActions;
						if ( true === hasFollowingActions ) {

							this.consoleService.log('ActionPage','hasFollowingActions');
							let followingAction = data.followingListActions.list[0];
							if(followingAction != 'undefined'){
								this.consoleService.log('ActionPage','hasAction');
								this.items = [];
								this.index = 0;
								this.items[this.index] = followingAction;

								this.loading.dismiss();

								this.processActionComponents(data.followingAction);
							}
						} else {
							this.navCtrl.pop();
						}


					} else {
						this.toast.show({message: 'An error ocurred when performing the action', cssClass: 'error'});
					}

					this.loading.dismiss();

				},
				error: err => {
					this.loading.dismiss();
				},
				complete: () => {
					this.loading.dismiss();
					this.goBack();
				}
			});
		}
	}

	goToSubaction(compoment: any): void {
		compoment = Object.assign(compoment, {checked: true});

		let idcodigo: number = 0;
		switch (compoment.nome) {
			case 'subaction_forward_doc':
				idcodigo = 71;
				break;
			case 'subaction_forward_doc_pending_action':
				idcodigo = 73;
				break;
			case 'ligar_doc':
				if (this.configService.getVar('link_documents')) {
					let link_documents_vars = this.configService.getVar('link_documents');
					link_documents_vars = Object.assign(link_documents_vars, {countPagesToRemove: 0});
					this.configService.setVar('link_documents', link_documents_vars);
				} else {
					this.configService.setVar('link_documents', {countPagesToRemove: 0});
				}

				this.events.publish('doc:pushPage', {
					page: 'LinkDocumentsPage',
					params: {
						document: this.document,
						frompage: 'ActionPage'
					}
				});

				break;

			case 'associar_doc':
				this.events.publish('doc:pushPage', {
					page: 'ListDocumentsPage',
					params: {
						document: this.document,
						associate: true,
						frompage: 'ActionPage'
					}
				});
				break;

			case 'sub_action_read_document':
				this.download(this.document)

		}

		if (idcodigo > 0) {

			this.events.publish('doc:pushPage', {
				page: 'ActionPage',
				params: {
					document: this.document,
					idcodigo: idcodigo,
					frompage: 'ActionPage'
				}
			});

		}
	}

	goToInfo(doc?: any): void{

		let document = {};

		if ('object' === typeof doc) {
			document = doc;
		} else {
			document = this.document;
		}

		this.events.publish('doc:pushPage', {
			page: 'InfoPage',
			params: {
				document: document,
				frompage: 'ActionPage'
			}
		});
	}

	goToChronologicalScheme(doc?: any): void {

		let document = {};

		if ('object' === typeof doc) {
			document = doc;
		} else {
			document = this.document;
		}

		this.events.publish('doc:pushPage', {
			page: 'ChronologicalschemePage',
			params: {
				document: document,
				frompage: 'ActionPage'
			}
		});

		this.linkChronologicalScheme.check = true;

	}

	openFabList(fab?: IonFab){

		if ('object' === typeof this.results  && !this.hasSubActions) {

			this.openActionSheetFor('executeaction');
			if (fab != undefined) {
				this.consoleService.log('ActionPage','close');
				fab.close();
			}

		}
	}

	async openActionSheetFor(option: string, doc?: any, fab?: IonFab) {

		if (option == 'forwardchoice') {

				if('undefined' != typeof doc){	//more associated doc

					this.actionSheet.show('ActionPage', 'forwardchoice', {
						item: doc,
						frompage: 'ActionPage'
					});

				} else {						//forward the document

					this.actionSheet.show('ActionPage', 'forwardchoice', {
						item: this.document,
						frompage: 'ActionPage'
					});

				}

		} else if (option == 'more') {

			if (undefined != this.items && undefined != this.index) { //actionsheet for action
				this.actionSheet.show('ActionPage', 'more', {
					item: this.items[this.index],
					priorityflags: this.priorityflags
				});


			} else { //when forwarding
				this.actionSheet.show('ActionPage', 'more', {		//actionsheet for document
					item: this.document
				});
			}



		} else if (option == 'executeaction') {

			this.consoleService.log('ActionPage','fabbb', '',fab);

			if (fab != undefined) {
				this.consoleService.log('ActionPage','close');
				fab.close();
			}

			let buttons = [];

			for (let result of this.resultButton) {
				buttons.push({text: result.result, handler: () => {this.execute(result)}});
			}

			buttons.push({text: 'Cancel', role: 'cancel', handler: () => {this.consoleService.log('actionPage','cancel');}});

			let actionSheet = await this.actionSheetCtrl.create({
				buttons: buttons
			});
			await actionSheet.present();

		} else if (option == 'executesubaction') {

			if (fab != undefined) {
				fab.close();
			}

			let buttons = [];

			for (let subaction of this.subactions) {
				buttons.push({text: subaction.customtext, handler: () => {this.goToSubaction(subaction)}});
			}

			buttons.push({text: 'Cancel', role: 'cancel', handler: () => {this.consoleService.log('actionPage','cancel');}});

			let actionSheet = await this.actionSheetCtrl.create({
				buttons: buttons
			});
			await actionSheet.present();
		}
	}

	nextAction(){

		if (this.index < this.items.length - 1) {

			this.index = this.index + 1;
			this.actionForm();

			this.cleanWarningClasses();
			this.cleanWarningMessages();

			this.deleteActionConfig();
		}



	}

	previousAction(){

		if (this.index > 0) {

			this.index = this.index - 1;

			this.cleanWarningClasses();
			this.cleanWarningMessages();

			this.actionForm();

			this.deleteActionConfig();
		}


	}

	download(item: any): any {

		this.consoleService.log('ActionPage', 'download', 'item', item);

		if (!this.linkReadDocument.check){
			this.linkReadDocument.check = true
		}

		for (let i = 0; i < this.subactions.length; i++){
			if (this.subactions[i].nome == 'sub_action_read_document'){
				this.subactions[i].checked = true
			}
		}

		let user = this.userService.getUser();
		if ('0' == StorageUtils.getItem('platform_cordova')) {
			this.documentService.downloadbytoken({iddoc: item.iddoc, chronschemasort: user.defaultchronschemasort}, item.revision);

		} else {
			item = Object.assign(item, {
				emltopdf: true,
				chronschemasort: user.defaultchronschemasort
			});
			this.fileService.download(item);
		}
	}

	goBack(){
		this.nav.getActive().then((view) => this.nav.removeIndex(view.nav.views.length - 1));
	}

}
