import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import { Observable, timeout, retry } from 'rxjs';
import { JwtHelperService } from '@auth0/angular-jwt';

import { ConfigService } from './config-service';


@Injectable({
	providedIn: 'root',
  })
export class SiteService {

	constructor(
		public configService: ConfigService,
		public jwtHttp: JwtHelperService,
		public http: HttpClient
	) {
	}

	public ping() {
		return this.httpRequest('ping').pipe(timeout(10000));
	}

	public refreshLogo() {
		return this.http
			.get(this.configService.getVar('server_url')  + '/site/refreshlogo');
	}

	private httpRequest(method, params?): Observable<any> {
		return this.http
			.post(this.configService.getVar('server_url')  + '/site/' + method, params)
			.pipe(retry(this.configService.getHttp().retry));
	}
}
