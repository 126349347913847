import { Injectable } from '@angular/core';

import { Account } from '../models/Account';
import { ConsoleService } from './console/console-service';
import { UserService } from './user-service';
import { WebStorageService } from './webstorage-service';

@Injectable({
  providedIn: 'root',
})
export class AccountService {

    constructor(
      private localStorageService: WebStorageService,
      private consoleService: ConsoleService,
      private userService: UserService
    ) {}

    /**
     * Get current account
     *
     * @return Account
     */
    public getCurrentAccount(): Account {
      const accounts = this.getAccounts();
      const i = this.existsAccountIndex(this.localStorageService.get('server_url'), this.localStorageService.get('id'));
      if (i > -1) {
        return accounts[i];
      }

      return null;
    }

    public isActiveAccount(account: Account): boolean {
      const current: Account = this.getCurrentAccount();
      if (current.getServerurl() === account.getServerurl() && current.getUser().getId() === account.getUser().getId()) {
        return true;
      }

      return false;
    }

    /**
     * Get user accounts
     *
     * @returns Returns users accounts
     */
    public getAccounts(): Array<any> {
      const ret = [];
      const accounts = this.localStorageService.get('accounts');
      if (accounts) {
        let accounts = JSON.parse(this.localStorageService.get('accounts'));
        for (let i = 0; i < accounts.length; i++) {
          ret.push(new Account(
                                accounts[i],
                                this.localStorageService.get('server_url', '__app'),
                                this.localStorageService.get('id', '__app')
                              ));
        }

        return ret;
      }
      return [];
    }

    /**
     * Save account configuration
     *
     * @param account
     */
    public saveAccount(account: Account): void {
      const accounts = this.getAccounts();
      const i = this.existsAccountIndex(account.server_url, account.id);
      accounts[i].access_token  = account.access_token;
      accounts[i].refresh_token = account.refresh_token;
      accounts[i].__app_access_token  = account.access_token;
      accounts[i].__app_refresh_token = account.refresh_token;
      accounts[i].user = account.user;
      accounts[i].username = account.username;
      accounts[i].id = account.id;
      accounts[i].name = account.name;
      accounts[i].link = account.link;
      this.localStorageService.set('accounts', JSON.stringify(accounts));
    }

    /**
     * Save a new account
     * @param user login information
     */
    public setAccount(user: any, fromstorage: number = 0): void {
      let creatorAcc = this.getCurrentAccount();
      let account = {
        user: JSON.stringify(user),
        server_url: user.server_url,
        access_token: user.access_token,
        __app_server_url: user.server_url,
        __app_access_token: user.access_token,
        __app_refresh_token: user.refresh_token,
        __app_username: user.username,
        __app_id: user.id,
        __app_name: user.name,
        __app_link: creatorAcc.link,
        id: user.id
      };

      let accountItem = 0;
      const accounts = this.getAccounts();
      for (let i = 0; i < accounts.length; i++) {
        if (this.existsAccount(user.server_url, user.id)) {
	        this.deleteAccount(accounts[i]);
          accountItem = i;
          accounts[i].__app_link = creatorAcc.link;
          accounts[i].link = creatorAcc.link;
        }
      }
      accounts.push(account);
      this.localStorageService.remove('accounts');
      this.localStorageService.set('accounts', JSON.stringify(accounts));

      this.removeDuplicatedAccounts();
    }

    /**
     * Check if exists url
     * @param url Server url
     * @param username User name
     * @returns Returns index if url already exists. Otherwise returns -1
     */
    public existsAccountIndex(url: string, id: any): number {
      this.consoleService.log('AccountService', 'existsAccountIndex', 'existsAccountIndex(' + url + ', ' + id + ')');

      const accounts = this.getAccounts();
      for (let i = 0; i < accounts.length; i++) {

        this.consoleService.log(
          'AccountService', 'existsAccountIndex', 'existsAccount => ',
        i + ' => ' + accounts[i].server_url + ' === ' + url + ' | ' + accounts[i].id + ' === ' + id
        );
        if (accounts[i].server_url === url && accounts[i].id == id) {
          return i;
        }
      }

      return -1;
    }

    /**
     * Check if exists url
     * @param url Server url
     * @param username User name
     * @returns Returns true if url already exists. Otherwise returns false.
     */
    public existsAccount(url: string, id: number): boolean {
      if (this.existsAccountIndex(url, id) > -1) {
        return true;
      }
      return false;
    }

    public checkAccounts(type: number = 0): void {
      let accounts = this.getAccounts();
      //console.log('Check Accounts');
      if (0 === accounts.length || 1 === type) {
        const account: object = {};
        for (let i = 0; i < localStorage.length; i++) {
          account[localStorage.key(i)] = localStorage.getItem(localStorage.key(i));
        }

        const user = JSON.parse(localStorage.getItem('user'));
        account['__app_username'] = user.username;
        account['__app_id'] = user.id;
        account['__app_name'] = user.name;
        if (1 === type && user.link === undefined) {
            account['__app_link'] = user.id + '_' + user.username;
        } else {
            account['__app_link'] = user.link;
        }
        // account['username'] = user.username;
        accounts.push(account);
        //console.log('Test');
        //console.log(accounts);
        //console.log(JSON.stringify(accounts));
        this.localStorageService.set('accounts', JSON.stringify(accounts));
        this.localStorageService.set('username', account['__app_username']);
        this.localStorageService.set('id', account['__app_id']);
        this.localStorageService.set('name', account['__app_name']);
        this.localStorageService.set('link', account['__app_link']);

        accounts = this.getAccounts();
        this.localStorageService.remove('accounts');
        this.localStorageService.set('accounts', JSON.stringify(accounts));
      }

      if (1 === type) {

      }
      this.removeDuplicatedAccounts();
    }

    public activateAccount(current: Account, account: any): void {
      this.setAccount(current, 1);
      const arr = account.activate();
      this.localStorageService.set('server_url', arr[0], '__app');
      this.localStorageService.set('server_url', arr[0]);
      this.localStorageService.set('refresh_token', arr[1], '__app');
      this.localStorageService.set('refresh_token', arr[1]);
      localStorage.setItem('refresh_token', arr[1]);
      this.localStorageService.set('access_token',arr[2], '__app');
      this.localStorageService.set('access_token',arr[2]);
      localStorage.setItem('access_token', arr[2]);
      this.localStorageService.set('username', arr[3], '__app');
      this.localStorageService.set('username', arr[3]);
      this.localStorageService.set('id', arr[4], '__app');
      this.localStorageService.set('id', arr[4]);
      this.localStorageService.set('link', arr[5], '__app');
      this.localStorageService.set('link', arr[5]);
      localStorage.setItem('user',arr[6]);
      this.localStorageService.set('user', arr[6]);
      localStorage.setItem('server_url', arr[7]);
      this.userService.setUser(arr[6]);
    }

    public login(): void {
      this.checkAccounts(1);
    }

    public deleteAccount(account: Account): Array<Account> {
      const newaccounts: Array<Account> = [];
      const item = this.existsAccountIndex(account.getServerurl(), account.getUser().getId());

      const accounts = this.getAccounts();
      for (let i = 0; i < accounts.length; i++) {
        if (i !== item) {
          newaccounts.push(accounts[i]);
        }
      }
      this.localStorageService.set('accounts', JSON.stringify(newaccounts));

      return this.getAccounts();
    }

    private removeDuplicatedAccounts(): void {
      const uniques = [];
      const uniques2 = [];
      const accounts = this.getAccounts();
      for (let i = 0; i < accounts.length; i++) {
          if (-1 === uniques.indexOf(accounts[i].server_url + '|' + accounts[i].id)) {
            uniques2.push(accounts[i]);
          }
          uniques.push(accounts[i].server_url + '|' + accounts[i].id);
      }

      this.localStorageService.set('accounts', JSON.stringify(uniques2));
    }
}
