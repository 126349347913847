export class ArrayUtils {

	static contert2simple(input, field): any {
		if ('undefined' == typeof input) {
			return [];
		}

		const output = [];
		for (var i = 0; i < input.length; ++i) {
			output.push(input[i][field]);
		}

		return output;
	}

	static unique(input): any {
		const output = [];

		for (var i = 0; i < input.length; ++i) {
			if (-1 == output.indexOf(input[i])) {
				output.push(input[i]);
			}
		}

		return output;
	}

	static convert2associative(input: any, name: string, value: any){

		if ('undefined' == typeof input) {
			return [];
		}

		const output = [];

		for (var i = 0; i < input.length; ++i) {
			output[input[i][name]] = input[i][value]
		}

		return output;

	}
}
