import { NgModule } from '@angular/core';

import { DocumentTitleComponent } from './document-title/document-title';

@NgModule({
	declarations: [DocumentTitleComponent],
	imports: [],
	exports: [DocumentTitleComponent]
})
export class ComponentsModule {}
