import { Component, ViewChild, OnInit, OnDestroy } from '@angular/core';

import { fromEvent, Observable, Subscription } from 'rxjs';
import { debounceTime, distinctUntilChanged } from 'rxjs/operators';
import { NavController, NavParams, ModalController, IonSearchbar } from '@ionic/angular';

import { StringUtils } from '../../utils/string-utils';
import { DocumentService } from '../../services/document-service';
import { UserService } from '../../services/user-service';
import { ConfigService } from '../../services/config-service';
import { EntityService } from '../../services/entity-service';
import { ActionService } from '../../services/action-service';
import { Command } from 'protractor';

@Component({
	selector: 'app-page-advanced-search',
	templateUrl: 'advanced-search.html',
	styleUrls: ['advanced-search.scss']
})
export class AdvancedSearchPage {

	inputs: any;
	dates: any;
	radios: any;
	toggles: any;
	selectsWithModal: any;
	searchAndSelectsWithModal: any;
	title: string;
	searchBar: any;
	form: any = {descr: '', els: []};

	constructor(public navCtrl: NavController,
				public navParams: NavParams,
				public modalCtrl: ModalController
				) {

				if (typeof navParams != 'undefined'){
					if (navParams.get('form')) {
						//console.log('AdvancedSearchPage form');
						//console.log(navParams.get('form'));
						this.form = Object.assign(this.form, navParams.get('form'));

						if (this.form.descr) {
							this.title = this.form.descr;
						}
					} else {
						this.searchBar = navParams.get('searchBar');
						this.title = navParams.get('title');

						if (this.title) {
							this.form.descr = this.title;
						}

						if (navParams.get('searchBar')) {
							this.pushElementForm('searchbar', navParams.get('searchBar'));
						}
						if (navParams.get('inputs')) {
							this.pushElementForm('input', navParams.get('inputs'));
						}
						if (navParams.get('dates')) {
							this.pushElementForm('date', navParams.get('dates'));
						}
						if (navParams.get('radios')) {
							this.pushElementForm('radio', navParams.get('radios'));
						}
						if (navParams.get('toggles')) {
							this.pushElementForm('toggle', navParams.get('toggles'));
						}
						if (navParams.get('selectsWithModal')) {
							this.pushElementForm('selectwithmodel', navParams.get('selectsWithModal'));
						}
						if (navParams.get('searchAndSelectsWithModal')) {
							this.pushElementForm('searchselectswithmodal', navParams.get('searchAndSelectsWithModal'));
						}
					}

					for (let i = 0; i < this.form.els.length; i++) {
						this.form.els[i] = Object.assign({visible: true}, this.form.els[i]);

						switch (this.form.els[i].eltype) {
							case 'date':
								let dateSinceValue = '';
								if (this.form.els[i].sinceValue && '' != this.form.els[i].sinceValue) {
									dateSinceValue = this.form.els[i].sinceValue;
								} else {
									dateSinceValue = (new Date()).toISOString().slice(0, 10);
								}

								let dateUpToValue = '';
								if (this.form.els[i].upToValue && '' != this.form.els[i].upToValue) {
									dateUpToValue = this.form.els[i].upToValue;
								} else {
									dateUpToValue = (new Date()).toISOString().slice(0, 10);
								}


								let dateSinceMin = (new Date(new Date().setFullYear(new Date().getFullYear() - 10))).toISOString().slice(0, 10);
								let dateSinceMax = (new Date(new Date().setFullYear(new Date().getFullYear() + 10))).toISOString().slice(0, 10);

								let dateUpToMin = (new Date(new Date().setFullYear(new Date().getFullYear() - 10))).toISOString().slice(0, 10);
								let dateUpToMax = (new Date(new Date().setFullYear(new Date().getFullYear() + 10))).toISOString().slice(0, 10);

								this.form.els[i] = Object.assign(this.form.els[i], {
									idModelSince: this.form.els[i].idModel ?
										this.form.els[i].idModel + '_start' :
										this.form.els[i].idModelSince,
									idModelUpTo:  this.form.els[i].idModel ?
										this.form.els[i].idModel + '_end' :
										this.form.els[i].idModelUpTo,
									show: this.form.els[i].show ?
										this.form.els[i].show :
										false,
									sinceValue: dateSinceValue, //start date
									sinceMin: dateSinceMin,
									sinceMax: dateSinceMax,
									upToValue: dateUpToValue, //end date
									upToMin: dateUpToMin,
									upToMax: dateUpToMax
								});
								break;
						}
					}

					for (let i = 0; i < this.form.els.length; i++) {
						this.changeToogle(this.form.els[i]);
					}
				}
				//console.log('navParams');
				//console.log(navParams);

	}

	ionViewWillEnter() {

	}


	ionViewWillLeave() {

	}

	changeToogle(el): void {
		for (let i = 0; i < this.form.els.length; i++) {
			if (this.form.els[i].parentid) {
				if (el.id == this.form.els[i].parentid) {
					this.form.els[i].visible = el.value;
				}
			}
		}
	}

	allowDateSelection(date: any){
		//console.log('alow');
		if(typeof date.show != 'undefined'){
			date.show = true;
		}
	}

	cancelDate(date: any){
		if(typeof date.show != 'undefined'){
			date.show = false;
		}
	}

	dateSinceOnChange(date: any){
		if(	typeof date.sinceValue != 'undefined' && typeof date.upToValue != 'undefined' &&
			typeof date.sinceMin != 'undefined' && typeof date.upToMin != 'undefined' &&
			typeof date.sinceMax != 'undefined' && typeof date.upToMax != 'undefined'){

			date.upToMin = date.sinceValue;
			if(date.upToValue < date.upToMin){
				//console.log('menor');
				date.upToValue = date.upToMin;
			}
		}
	}

	dateToOnChange(date: any){
		if(	typeof date.sinceValue != 'undefined' && typeof date.upToValue != 'undefined' &&
			typeof date.sinceMin != 'undefined' && typeof date.upToMin != 'undefined' &&
			typeof date.sinceMax != 'undefined' && typeof date.upToMax != 'undefined'){

			date.sinceMax = date.upToValue;

			if(date.sinceValue > date.sinceMax){
				//console.log('maior');
				date.sinceValue = date.sinceMax;
			}
		}
	}

	onCancelSearchBar(ev){
		if (typeof this.searchBar.value != 'undefined'){
			this.searchBar.value = '';
		}
	}

	bntDisabled(): boolean {
		let disable = true;

		for (let i = 0; i < this.form.els.length; i++) {
			switch(this.form.els[i].eltype) {
				case 'searchbar':
					if (this.form.els[i].value.length > 2) {
						disable = false;
					}
					break;
				case 'toogle':
					if (true != this.form.els[i].value) {
						this.form.els[i].value = false;
					}
					break;
				case 'input':
					if ('' != this.form.els[i].value) {
						disable = false;
					}
					break;
				case 'date':
					if (true == this.form.els[i].show) {
						disable = false;
					}
					break;
				case 'selectwithmodel':
					if (this.form.els[i].itemCheckedId) {
						if (this.form.els[i].itemCheckedId > 0) {
							disable = false;
							break;
						}
					}
					break;
				case 'searchselectswithmodal':
					//if (typeof this.form.els[i].itemsCheckedId != 'undefined') {
					if (true == Array.isArray(this.form.els[i].itemsCheckedId)) {
						for (let id of this.form.els[i].itemsCheckedId) {
							if (id > 0) {
								disable = false;
								break;
							}
						}
					}
					break;
			}

			if (false === disable) {
				break;
			}
		}

		if (disable) {
			return true;
		} else {
			return false;
		}
	}

	radioChecked(radio: any, option: any) {

	}

	search(): void {
		const vars: any = {};
		// eslint-disable-next-line @typescript-eslint/prefer-for-of
		for (let i = 0; i < this.form.els.length; i++) {
			switch(this.form.els[i].eltype) {
				case 'toogle':
					vars[this.form.els[i].idModel] = this.form.els[i].value;
					break;
				case 'searchbar':
				case 'input':
					if ('undefined' != typeof this.form.els[i].value) {
						vars[this.form.els[i].idModel] = this.form.els[i].value;
					}
					break;
				case 'date':
					if (this.form.els[i].show) {
						if ('undefined' != typeof this.form.els[i].sinceValue) {
							vars[this.form.els[i].idModelSince] = this.form.els[i].sinceValue;
						}
						if ('undefined' != typeof this.form.els[i].upToValue) {
							vars[this.form.els[i].idModelUpTo]  = this.form.els[i].upToValue;
						}
					}
					break;
				case 'selectwithmodel':
					if ('undefined' != typeof this.form.els[i].itemCheckedId) {
						vars[this.form.els[i].idModel] = this.form.els[i].itemCheckedId;
					}
					break;
				case 'searchselectswithmodal':
					if ('undefined' != typeof this.form.els[i].itemsCheckedId) {
						vars[this.form.els[i].idModel] = this.form.els[i].itemsCheckedId;
					}
					break;
				case 'radio':
					if ('undefined' != typeof this.form.els[i].options) {
						if ('undefined' != typeof this.form.els[i].value) {
							vars[this.form.els[i].idModel] = this.form.els[i].value;
						}
					}
					break;
			}
		}

		this.modalCtrl.dismiss({
			form: this.form,
			searchvars: vars
		});
	}

	dismiss(){
		this.modalCtrl.dismiss();
	}

	presentSelectWithModal(select: any){
		this.modalCtrl.create({
			component: AdvancedSelectWithModal,
			componentProps: {
				title: select.name,
				idModel: select.idModel,
				nameModel: select.nameModel,
				el: select
			}
		}).then((modal) => {
			modal.onDidDismiss().then((data) => {
					if(typeof data.data !== 'undefined'){
						const item = data.data.selectWithModel;
						if (typeof item.idModel !== 'undefined' && item.itemCheckedId !== 'undefined' ) {
							for (let selectData of this.getElementForm('selectwithmodel')) {
								if (selectData.idModel == item.idModel) {
									selectData = Object.assign(selectData, {
										itemCheckedName: item.itemCheckedName,
										itemCheckedId: item.itemCheckedId,
										itemChecked: item.itemChecked
									});
								}
							}
						} else if (typeof item.idModel !== 'undefined' && item.itemCheckedId == 'undefined') {
							//to clean checked ones
							for (let selectData of this.getElementForm('selectwithmodel')) {
								if (selectData.idModel == item.idModel) {
									selectData = Object.assign(selectData, {
										itemCheckedName: '',
										itemCheckedId: '',
										itemChecked: ''
									});
								}
							}
						}
					}
				});
			modal.present();
		});
	}

	presentSearchAndSelectWithModal(searchAndSelect: any){

		this.modalCtrl.create({
			component: AdvancedSearchAndSelectWithModal,
			componentProps: {
				title: searchAndSelect.name,
				idModel: searchAndSelect.idModel,
				nameModel:  searchAndSelect.nameModel,
				itemsChecked: searchAndSelect.itemsChecked
			}
		}).then((modal) => {
			modal.onDidDismiss()
				.then((data) => {
					if(typeof data !== 'undefined'){
						const item = data.data.searchAndSelectWithModal;
						if(typeof data.data !== 'undefined' && item.itemsCheckedId != 'undefined'){
							for (let select of this.getElementForm('searchselectswithmodal')) {
								select = Object.assign(select, {
									itemsCheckedName: item.itemsCheckedName,
									itemsCheckedId: item.itemsCheckedId,
									itemsChecked: item.itemsChecked
								});
							}
						} else if (typeof data.data !== 'undefined' && item.itemsCheckedId == undefined) {
							for (let select of this.getElementForm('searchselectswithmodal')) {
								select = Object.assign(select, {itemsCheckedName: '',
																itemsCheckedId: '',
																itemsChecked: ''});
							}
						}
					}
				});
			modal.present();
		});
	}

	pushElementForm(eltype: string, els: any): void {
		if (false == Array.isArray(els)) {
			els = [els];
		}

		for (let i = 0; i < els.length; i++) {
			els[i] = Object.assign({visible: true}, els[i]);

			this.form.els.push(Object.assign({eltype: eltype}, els[i]));
		}
	}

	getElementForm(eltype: string): Array<any> {
		let els: Array<any> = [];

		for (let i = 0; i < this.form.els.length; i++) {
			if (eltype == this.form.els[i].eltype) {
				els.push(this.form.els[i]);
			}
		}

		return els;
	}
}

@Component({
	 template: `
    <ion-header>
		<ion-toolbar class="default-background">
			<ion-title>{{title | translate}}</ion-title>
			<ion-buttons slot='secondary'>
				<ion-button (click)='dismiss()'>
					<ion-label><span style='color:white'>{{'Cancel' | translate}}</span></ion-label>
				</ion-button>
			</ion-buttons>
		</ion-toolbar>
		<ion-searchbar (ionInput)='getItems($event)' placeholder='{{"Search" | translate}}' name='searchModel' #searchbar></ion-searchbar>
	</ion-header>
	<ion-content>
		<ion-grid *ngIf='loading'>
			<ion-row>
				<ion-col style='text-align: center'>
					<ion-spinner></ion-spinner>
				</ion-col>
			</ion-row>
		</ion-grid>
		<ion-list *ngIf='!loading' lines="full" class="ion-no-padding ion-no-margin">
			<ion-item detail-none (click)='dismiss(allItem)' lines="full" class="ion-no-margin" style="padding: 0 !important;">
				<ion-label>{{allItem[nameModel] | translate}}</ion-label>
			</ion-item>
			<ion-item *ngFor='let item of items' detail-none (click)='dismiss(item)' lines="full" class="ion-no-margin" style="padding: 0 !important;">
				<ion-label>{{item[nameModel]}}</ion-label>
			</ion-item>
		</ion-list>
	</ion-content>
  `,
  providers: [DocumentService, UserService, ConfigService, ActionService]
})
export class AdvancedSelectWithModal {
	@ViewChild('searchbar', {static: false}) searchbar: IonSearchbar;
	el: any;
	items: any;
	allitems: any;
	title: string;
	loading = false;
	searchModel: string;
	idModel: string;
	nameModel: string;

	allItem = {};

	constructor(	params: NavParams,
					public modalCtrl: ModalController,
					public documentService: DocumentService,
					public userService: UserService,
					public actionService: ActionService) {

		//console.log(params);

		if(params.get('el') != 'undefined'){
			this.el = params.get('el');
		}

		if(params.get('title') != 'undefined'){
			this.title = params.get('title');
		}

		if(params.get('idModel') != 'undefined'){
			this.idModel = params.get('idModel');
		}

		if(params.get('nameModel') != 'undefined'){
			this.nameModel = params.get('nameModel');
		}

		if(params.get('idModel') == 'utilizador'){
			this.loading = true;
			this.userService.getallusers({}).subscribe({
				next: data => {
					this.items = data.users;
					this.allitems = this.items;
					//console.log(this.items);
					this.loading = false;
				},
				error: () => {
					this.loading = false;
				}
			});

		} else if (params.get('idModel') == 'idtipodoc') {
			this.loading = true;
			this.documentService.getalldocumenttypes({}).subscribe({
				next: data => {
					this.items = data.documenttypes;
					this.allitems = this.items;
					this.loading = false;
				},
				error: () => {
					this.loading = false;
				}
		});
		} else if (params.get('idModel') == 'idpriorityflag') {
			this.loading = true;
			this.actionService.findallpriorityflags({}).subscribe({
				next: data => {
					this.items = data.priorityflags;
					this.allitems = this.items;
					this.loading = false;
				},
				error: () => {
					this.loading = false;
				}
			});
		} else if (this.el.srvaction) {
			this.loading = true;

			const explode = this.el.srvaction.split('/');
			if ('action' === explode[0]) {
				this.actionService.findGeneric(explode[1], {}).subscribe({
					next: data => {
						//console.log(data.json());
						this.items = data.items;
						this.allitems = this.items;
						this.loading = false;
					},
					error: () => {
						this.loading = false;
					}
				});
			} else {
				this.documentService.findGeneric(explode[1], {}).subscribe({
					next: data => {
						this.items = data.items;
						this.allitems = this.items;
						this.loading = false;
					},
					error: () => {
						this.loading = false;
					}
				});
			}
		}

		//console.log('items', this.items);

		this.allItem = {[this.nameModel]: 'All', [this.idModel]: 0};

		//console.log(this.allItem);

	}

	ionViewDidEnter(): void {
		setTimeout(() => {
			this.searchbar.setFocus();
		}, 0);
	}

	dismiss(item?: any){

		//console.log(item);

		if(typeof item != 'undefined'){
			this.modalCtrl.dismiss({selectWithModel: {	idModel: this.idModel,
															itemCheckedName: item[this.nameModel],
															itemCheckedId: item[this.idModel],
															itemChecked: item}});
		} else {
			this.modalCtrl.dismiss({selectWithModel: {	idModel: this.idModel,
															itemCheckedName: '',
															itemCheckedId: '',
															itemChecked: ''
															}
										});
		}

	}

	getItems(ev) {

		if ('undefined' !== typeof this.allitems) {
			this.items = this.allitems;
			// set val to the value of the ev target
			const val = ev.target.value;

			// if the value is an empty string don't filter the items
			if (val && val != '' && val.trim() != '') {
				this.items = this.items.filter((item) => {
					if (typeof item[this.nameModel] !== 'undefined') {
						return (StringUtils.replaceSpecialChars(item[this.nameModel]).toLowerCase().indexOf(StringUtils.replaceSpecialChars(val).toLowerCase()) > -1);
					}

				})
			}
		}


	}
}

@Component({
	 template: `
    <ion-header>
		<ion-toolbar class="default-background">
			<ion-title>{{title | translate}}</ion-title>
			<ion-buttons slot='secondary'>
				<ion-button (click)='dismiss(false)'>
					<ion-label><span style='color:white'>{{'Cancel' | translate}}</span></ion-label>
				</ion-button>
			</ion-buttons>
			<ion-buttons slot='primary'>
				<ion-button (click)='dismiss(true)'>
					<ion-label><span style='color:white'>{{'OK' | translate}}</span></ion-label>
				</ion-button>
			</ion-buttons>
		</ion-toolbar>
		<ion-searchbar debounce="750" (ionInput)='searchItems($event)' placeholder='{{"Type to search" | translate}}' name='searchModel' #searchbar></ion-searchbar>
	</ion-header>
	<ion-content>
		<ion-grid *ngIf='loading'>
			<ion-row>
				<ion-col style='text-align: center'>
					<ion-spinner></ion-spinner>
				</ion-col>
			</ion-row>
		</ion-grid>
		<div *ngIf='searched'>
			<div *ngIf='found; then hasresults else hasnoresults'></div>
			<ng-template #hasresults>
				<ion-list lines="full" class="ion-no-padding ion-no-margin">
					<ion-item *ngFor='let item of items' lines="full" class="ion-no-margin" style="padding: 0 !important;">
						<ion-label>{{item[nameModel]}}</ion-label>
						<ion-checkbox (ionChange)='check(item)' checked='{{item.checked}}'></ion-checkbox>
					</ion-item>
				</ion-list>
			</ng-template>
			<ng-template #hasnoresults>
				<ion-item *ngIf='!loading' lines="full" class="ion-no-margin" style="padding: 0 !important;">
					<ion-grid>
						<ion-row>
							<ion-col>
								<ion-label>{{'No record found' | translate}}</ion-label>
							</ion-col>
						</ion-row>
					</ion-grid>
				</ion-item>
			</ng-template>
		</div>
		<div *ngIf='itemsChecked && !searched && !loading'>
			<ion-list lines="full" class="ion-no-padding ion-no-margin">
				<ion-item *ngFor='let itemChecked of itemsChecked' lines="full" class="ion-no-margin" style="padding: 0 !important;">
					<ion-label>{{itemChecked[nameModel]}}</ion-label>
					<ion-checkbox (ionChange)='check(itemChecked)' checked='{{itemChecked.checked}}'></ion-checkbox>
				</ion-item>
			</ion-list>
		</div>
	</ion-content>
  `,
	providers: [EntityService, ConfigService]
})
export class AdvancedSearchAndSelectWithModal implements OnInit, OnDestroy {
	@ViewChild('searchbar', {static: false}) searchbar: IonSearchbar;
	field: any;
	title: string;
	loading = false;
	found = false;
	itemsChecked: Array<any> = [];
	items: any;
	searched = false;
	idModel: string;
	nameModel: string;
	searchString = '';
	subscription: Subscription; //http subscription
	searchItemsObservable: any;

	constructor(	public params: NavParams,
					public modalCtrl: ModalController,
					public entityService: EntityService) {


			if (params.get('title') != undefined) {
				this.title = params.get('title');
			}

			if (params.get('idModel') != undefined) {

				this.idModel = params.get('idModel');
				//console.log('idModel');
				//console.log(this.idModel);
			}

			if (params.get('nameModel') != undefined) {

				this.nameModel = params.get('nameModel');
				//console.log('nameModel');
				//console.log(this.nameModel);
			}

			if (params.get('itemsChecked') != undefined) {

				this.itemsChecked = params.get('itemsChecked');

				//console.log('itemsChecked');
				//console.log(this.itemsChecked);
			}


	}

	ionViewDidEnter(): void {
		if (this.itemsChecked === undefined) {
			this.itemsChecked = [];
		}
		setTimeout(() => {
			this.searchbar.setFocus();
		}, 0);
	}

	dismiss(save: boolean){

		if(save){

		var itemsCheckedName = '';
		var itemsCheckedId = [];

		for (var i = 0; i < this.itemsChecked.length; i++) {
			if (this.itemsChecked[i][this.idModel] != 'undefined' && this.itemsChecked[i][this.nameModel] != 'undefined'){

				itemsCheckedId.push(this.itemsChecked[i][this.idModel]);

				if(i == 0){
					itemsCheckedName = this.itemsChecked[i][this.nameModel];
				} else {
					itemsCheckedName = itemsCheckedName.concat(', ' + this.itemsChecked[i][this.nameModel]);
				}
			}
		}

		this.modalCtrl.dismiss({searchAndSelectWithModal: {idModel: this.idModel,
																itemsCheckedId: itemsCheckedId,
																itemsCheckedName: itemsCheckedName,
																itemsChecked: this.itemsChecked}});
		} else {
			this.modalCtrl.dismiss({searchAndSelectWithModal: {idModel: this.idModel,
																itemsCheckedId: '',
																itemsCheckedName: '',
																itemsChecked: ''}});
		}

	}

	check(item: any){
		item.checked = !item.checked;

		if (item.checked) { //IF ITEM WAS CHECKED TRUE
			let exists = false;

			for (let i = 0; i < this.itemsChecked.length; i++) { //FIND IF EXISTS ALREADY ON ARRAY. IF NOT EXISTS ADD IT
				if (this.itemsChecked[i][this.idModel] == item[this.idModel]) {
					exists = true;
					break;
				}
			}

			if (!exists) {
				let obj = {[this.idModel]: item[this.idModel], [this.nameModel]: item[this.nameModel], checked: item.checked}
				this.itemsChecked.push(obj);
			}

		} else { //IF ITEM WAS CHECKED FALSE

			for (let i = 0; i < this.itemsChecked.length; i++) { // FIND ITEM WITH THAT idatributo AND REMOVE IT
				if (this.itemsChecked[i][this.idModel] == item[this.idModel]) {
					this.itemsChecked.splice(i, 1);
				}

			}
		}

		//console.log(this.itemsChecked);

	}

	public searchItems(evt): void {
		this.searchItemsTerm(evt.target.value);
	}

	public searchItemsTerm(val: string): void {
		//console.log('searchItemsTerm', val);
		this.searched = false;
		//console.log('Search Items Term');

		if (val.length > 2 && val !== this.searchString) {
			this.searchString = val;

			this.loading = true;

			if (this.idModel == 'idatributo'){
				if (this.subscription) {
					this.subscription.unsubscribe();
				}

				this.subscription = this.entityService.findentities({searchString: val}).subscribe({
					next: (data: any) => {
						this.searchString = '';

						this.searched = true;
						this.loading = false;

						//console.log('data', data);
						this.items = data.entities;

						if (this.items.length > 0) {
							this.found = true;
						} else {
							this.found = false;
						}

						for (let item of this.items) {

							item = Object.assign(item, {checked: false});

							for (const itemChecked of this.itemsChecked) { //FIND IF EXISTS ALREADY ON ARRAY. IF DOESN'T EXISTS ADD IT

								if( itemChecked[this.idModel] == item[this.idModel]){
									item = Object.assign(item, {checked: true});
								}

							}
						}
					},
					error: () => {
						this.searchString = '';

						this.searched = true;
						this.loading = false;
						this.found = false;

						//console.log('err', err);
					},
					complete: () => {
						this.searchString = '';
					}
				});
			}

		}

	}

	ngOnInit(): void {
		//Eia bem
		this.searchString = 'sadghakuyaguyadiaxiqyxuiaghxsxoakxgqyghyT$EDYTgughuyqwguyr5tfgSDASkugasuasS';

		this.searchItemsTerm('');
		/*
		this.searchItemsObservable = fromEvent(this.searchbar.getNativeElement(), 'keyup');
		this.searchItemsObservable
			.map(event => event.target.value) //transfrom the text from the user input
			.filter((text: string) => text.length > 2) // Only if the text is longer than 2
			.debounceTime(750) // make sure user’s can’t spam us
			.distinctUntilChanged() //Only if the value has changed
			.subscribe((text: string) => this.searchItemsTerm(text));
		*/
		//console.log('ngOnInit');
    }

	ngOnDestroy() {
		if (this.subscription) {
			this.subscription.unsubscribe();
		}
		//console.log('ngOnDestroy');
	}
}
