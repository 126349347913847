import {Component, ViewChild} from '@angular/core';
import {NavController, NavParams, IonToolbar, ModalController, IonNav} from '@ionic/angular';
import {FolderService} from '../../services/folder-service';
import {ConfigService} from '../../services/config-service';

import {Toast} from '../../controllers/message-controller';

import {StorageUtils} from '../../utils/storage-utils';
import { Events } from '../../services/event-service';

@Component({
	selector: 'app-page-link-documents',
	templateUrl: 'link-documents.html',
	providers: [FolderService, IonToolbar],
	styleUrls: ['link-documents.scss']
})

export class LinkDocumentsPage {
	@ViewChild(IonToolbar) toolbar: IonToolbar;
	documentsIds: Array<number> = [];
	idpai: number;
	selected = false;
	selectedIndex: number;
	fspath: string;
	folders: Array<any> = [];
	rootFolder: string;
	linkDisable = true;
	finding = false;
	foldersIds: Array<number> = [];
	linkingStatus = false;

	constructor(public folderService: FolderService,
				public navController: NavController,
				public navParams: NavParams,
				public nav: IonNav,
				public toast: Toast,
				public viewController: ModalController,
				public configService: ConfigService,
				public events: Events) {


		//console.log(this.configService.getAllVar());

		if (this.configService.getVar('link_documents')) {
			let link_documents_vars = this.configService.getVar('link_documents');
			if (link_documents_vars.countPagesToRemove != undefined) {
				link_documents_vars.countPagesToRemove++;
				this.configService.setVar('link_documents', link_documents_vars);
			} else {
				link_documents_vars = Object.assign(link_documents_vars, {countPagesToRemove: 1});
			}
		}

//		if(StorageUtils.getItem('page_link-documents_countPagesToRemove')){
//			let count = JSON.parse(StorageUtils.getItem('page_link-documents_countPagesToRemove'));
//			count = count + 1;
//			StorageUtils.setItem('page_link-documents_countPagesToRemove', JSON.stringify(count));
//		} else {
//			StorageUtils.setItem('page_link-documents_countPagesToRemove', JSON.stringify(1));
//		}

		if (this.navParams.get('params')) {

			const params = this.navParams.get('params');

			if (undefined != params.document) {

				this.documentsIds = params.document.iddoc;

			} else if (undefined != params.documents) {

				//console.log('params.documents', params.documents);

				for (const document of params.documents) {
					this.documentsIds.push(document.iddoc);
				}

				//console.log('this.documentsIds', this.documentsIds);

			} else if (undefined != params.documentsIds) {
				this.documentsIds = params.documentsIds;
			}

			if (undefined != params.idpai) {
				this.idpai = params.idpai;
			} else {
				StorageUtils.removeItem('page_link-documents_iddoc');
			}

			if (undefined != params.rootFolder) {
				this.rootFolder = params.rootFolder;

				if (undefined != params.fspath) {
					this.fspath = this.rootFolder + params.fspath;
				}

			} else {
				if (undefined != params.fspath) {
					this.fspath = params.fspath;
				}
			}



		}


		//the id of selected folders are saved on storage. necessary to do push and pop pages without loss ids
		this.foldersIds = JSON.parse(StorageUtils.getItem('page_link-documents_iddoc'));

		if (null == this.foldersIds) {
			this.foldersIds = [];
		}

		this.finding = true;
		this.folderService.find2link({idpai: this.idpai, iddoc: this.documentsIds}).subscribe({
			next: data => {
				//console.log(this.foldersIds);

				this.folders = data.folders;

				for (let i = 0; i < this.folders.length; i++) {

					if (this.folders[i].idsec == 0) {
						this.rootFolder = this.folders[i].nome;
						this.fspath = this.rootFolder;
					}

					this.folders[i].checkedorig = this.folders[i].checked;

					if (this.foldersIds.indexOf(this.folders[i].idsec) > -1) {
						//console.log('idsec = ' + this.folders[i].idsec);
						this.folders[i].checked = true;
					}

					if (!this.folders[i].checked) {
						this.folders[i] = Object.assign(this.folders[i], {checked: false});
					} else {
						this.folders[i] = Object.assign(this.folders[i], {checked: true});
					}

				}

				this.finding = false;
			},
			error: () => {
				this.finding = false;
			}
		});




	}

	ionViewWillEnter() {

		//console.log('ionViewWillEnter');

		this.foldersIds = JSON.parse(StorageUtils.getItem('page_link-documents_iddoc'));
		if (null == this.foldersIds) {
			this.foldersIds = [];
		}

		if (0 === this.foldersIds.length) {
			this.linkDisable = true;
		} else {
			this.linkDisable = false;
		}
	}

	backbutton() {
		//console.log('back button');
		//console.log(this.configService.getAllVar());
		if (this.configService.getVar('link_documents')) {
			const link_documents_vars = this.configService.getVar('link_documents');
			if (link_documents_vars.countPagesToRemove > 1) {
				link_documents_vars.countPagesToRemove--;
				//console.log('countPagesToRemove--');
				this.configService.setVar('link_documents', link_documents_vars);
			}
		}
		//console.log(this.configService.getAllVar());
	}

	goToFolder(folder: any): void {
		if (folder.cntchildren > 0) {

			this.events.publish('doc:pushPage', {
				page: 'LinkDocumentsPage',
				params: {
					documentsIds: this.documentsIds,
					idpai: folder.idsec,
					fspath: folder.fspath,
					rootFolder: this.rootFolder,
					folders: this.folders,
					frompage: 'LinkDocumentsPage'
				}
			});

		}
	}

	toogleSelect(index: number): void {
		this.linkDisable = true;

		for (let i = 0; i < this.folders.length; i++) {
			if (i == index) {
				if (!this.folders[i].checkedorig) {
					this.folders[i].checked = !this.folders[i].checked;

					if (this.folders[i].checked) {
						if (-1 == this.foldersIds.indexOf(this.folders[i].idsec)) {
							this.foldersIds.push(this.folders[i].idsec);
						}
					} else {
						this.foldersIds.splice(this.foldersIds.indexOf(this.folders[i].idsec), 1);
					}
				}
			}
		}

		if (this.foldersIds.length > 0) {
			this.linkDisable = false;
		}

		StorageUtils.setItem('page_link-documents_iddoc', JSON.stringify(this.foldersIds));
	}

	link(): void {
		this.linkingStatus = true;
		this.folderService.link({documentsIds: this.documentsIds, foldersIds: this.foldersIds}).subscribe({
			next: data => {
				const error = data.error;
				this.linkingStatus = false;
				if (error.code == 0) {
					this.toast.show({message: 'Documents successfully linked', cssClass: 'success', dismissOnPageChange: false});

					if (this.configService.getVar('link_documents')) {
						let link_documents_vars = this.configService.getVar('link_documents');
						if (link_documents_vars.countPagesToRemove != undefined) {

							for (let i = 0; i < link_documents_vars.countPagesToRemove; i++) {
								//if ((link_documents_vars.countPagesToRemove - i) > 1) {
									
									this.nav.getActive().then((view) => {
										this.nav.removeIndex(view.element.tabIndex - (i + 1));
										//console.log('newCountPagesToRemove', link_documents_vars.countPagesToRemove - i);
									});
								/*} else {
									//this.navController.pop();
									this.nav.getActive().then((view) => this.nav.removeIndex(view.nav.views.length - 1));
								}*/
							}
						}
					}

//					let countPagesToRemove = JSON.parse(StorageUtils.getItem('page_link-documents_countPagesToRemove'));
//					console.log('countPagesToRemove', countPagesToRemove);
//					for (let i = 0; i < countPagesToRemove; i++) {
//
//						if ((countPagesToRemove - i) > 1) {
//							this.navController.remove(this.viewController.index - (i + 1));
//							console.log('newCountPagesToRemove', countPagesToRemove - i);
//						} else {
//							this.navController.pop();
//
//						}
//
//					}

					let link_documents_vars = this.configService.getVar('link_documents');
					link_documents_vars = Object.assign(link_documents_vars, {countPagesToRemove: 0});
					this.configService.setVar('link_documents', link_documents_vars);

					//StorageUtils.removeItem('page_link-documents_countPagesToRemove')


				} else {
					this.toast.show({message: 'An error ocurred while linking documents', cssClass: 'error', dismissOnPageChange: false});
				}
			},
			error: () => {
				this.linkingStatus = false;
			}
		});
	}
}
