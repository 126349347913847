import { Injectable } from '@angular/core';

import { Observable, retry } from 'rxjs';
import { JwtHelperService } from '@auth0/angular-jwt';
import { HttpClient } from '@angular/common/http';

import { ConfigService } from './config-service';
import { StringUtils } from '../utils/string-utils';

@Injectable({
	providedIn: 'root',
  })
export class DocumentService {

    constructor(
		public configService: ConfigService,
		public jwtHttp: JwtHelperService,
		public http: HttpClient
    ) {
    }

	public info(params: any): any {
		return this.httpRequest('info', params);
	}

	public savemark(params: any): any {
		return this.httpRequest('savedocumenttag', params);
	}

	public documenttag(params: any): any {
		return this.httpRequest('documenttag', params);
	}

	public removedocumenttag(params: any): any {
		return this.httpRequest('removedocumenttag', params);
	}

	public find(params: any): any {
		return this.httpRequest('find', params);
	}

	public formfind(params: any): any {
		return this.httpRequest('formfind', params);
	}

	public associate(params: any): any {
		return this.httpRequest('associate', params);
	}

	public findassoc(params: any): any {
		return this.httpRequest('findassoc', params);
	}

	public findemailsassoc(params: any): any {
		return this.httpRequest('findemailsassoc', params);
	}

	public findemailsassocattachs(params: any): any {
		return this.httpRequest('findemailsassocattachs', params);
	}

	public finddocumentattachs(params: any): any {
		return this.httpRequest('finddocumentattachs', params);
	}

	public token2downlaod(params: any): any {
		return this.httpRequest('token2downlaod', params);
	}

	public downloadbytoken(params: any): any {
		this.token2downlaod(params).subscribe({
			next: (data: any) => {
				const params2url: Array<string> = [];
				params2url.push('token2downlaod=' + data.token2downlaod);
				if ('undefined' !== typeof params.attachname) {
					params2url.push('attachname=' + StringUtils.base64_encode(params.attachname));
				}
				if ('undefined' !== typeof params.chronschemasort) {
					params2url.push('chronschemasort=' + params.chronschemasort);
				}

				window.open('/api/document/downloadbytoken?' + params2url.join('&'));
			},
			error: err => {
			},
			complete: () => {

			}
		});
	}

	public findshortcuts(params: any): any {
		return this.httpRequest('findshortcuts', params);
	}

	public getalldocumenttypes(params: any): any {
		return this.httpRequest('getalldocumenttypes', params);
	}

	public findGeneric(method: string, params: any): any {
		return this.httpRequest(method, params);
	}

	private httpRequest(method: string, params: any): Observable<any> {
    	return this.http
			.post(this.configService.getVar('server_url')  + '/document/' + method, params)
			.pipe(retry(this.configService.getHttp().retry));
	}
}
