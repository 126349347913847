import {Pipe, PipeTransform} from '@angular/core';
import {DatePipe} from '@angular/common';

@Pipe({
	name: 'time2date'
})
export class Time2datePipe implements PipeTransform {
    transform(value: any, args: string[]): any {
		let formated = '';

		if (value) {
			let format;
			if ('string' === typeof args) {
				format = args;
			}

			try {
				const datePipe = new DatePipe('en-EN');
				formated = datePipe.transform(value * 1000, format);
			} catch (err) {
				//console.log('datePipe.transform catch: ' + err.message);

				const d = new Date();
				const localOffset = -1 * d.getTimezoneOffset() * 60000;

				switch (format) {
					case 'y-MM-dd':
						formated = new Date(value * 1000 + localOffset).toISOString().slice(0, 10);
						break;
					case 'y-MM-dd H:mm':
						formated = new Date(value * 1000 + localOffset).toISOString().slice(0, 16);
						break;
					default:
						formated = new Date(value * 1000 + localOffset).toISOString().slice(0, 16);
				}
				formated = formated.replace(/T/gi, ' ');
			}
		}

		return formated;
    }
}
