import {Component, OnInit} from '@angular/core';
import {NavController, NavParams, ModalController} from '@ionic/angular';
import {UserService} from '../../services/user-service';
import {ConfigService} from '../../services/config-service';

@Component({
	selector: 'app-page-user-absence',
	templateUrl: 'user-absence.html',
	providers: [UserService, ConfigService],
	styleUrls: ['user-absence.scss']
})
export class UserAbsencePage implements OnInit {
	userAbsenced: any = {id : '', name: ''};
	absenceUsersList: any;
	showAbsenceUserList = false;

	userabsence: any = {
		iduser: '',
		dtstart: '',
		dtend: '',
		iduserreplacementdefault: '',
		iduserreplacementforward: '',
		actiondtstart: '',
		owntypeuserreplacementdefault: 0,
		owntypeuserreplacementforward: 0,
		treson: ''};
	usersDefault: any = [];
	usersForward: any = [];
	groupsDefault: any = [];
	groupsForward: any = [];
	actions: any = [];
	workflows: any = [];
	profile: any;

	userAbsencedIndex: any;
	DefaultIndex: any;
	ForwardIndex: any;

	selectDefaultCondition = false;
	selectDefaultGroup = false;
	selectForwardGroup = false;
	selectGroupForAction = false;
	showDefaultList = false;
	showForwardList = false;
	showActions = false;
	countActions = 0;
	defaultHasNewPerformer = false;
	defaultNewPerformer: any;
	forwardHasNewPerformer = false;
	forwardNewPerformer: any;

	minStartDate: any;
	maxStartDate: any;
	minEndDate: any;
	maxEndDate: any;
	minPendingActionsSinceDate: any;
	maxPendingActionsSinceDate: any;

	constructor(
		public navCtrl: NavController,
		public navParams: NavParams,
		public viewCtrl: ModalController,
		public userService: UserService
		) {
		if ('' == this.userabsence.dtstart) {
			this.userabsence.dtstart = new Date().toISOString().slice(0, 10);
		}

		if ('' == this.userabsence.dtend) {
			this.userabsence.dtend = new Date(new Date().setDate(new Date().getDate() + 1)).toISOString().slice(0, 10);
		}

		this.minStartDate = new Date(new Date().setFullYear(new Date().getFullYear() - 10)).toISOString().slice(0, 10);
		this.maxStartDate = new Date(new Date().setFullYear(new Date().getFullYear() + 10)).toISOString().slice(0, 10);

		this.minEndDate = this.userabsence.dtstart;
		this.maxEndDate = new Date(new Date().setFullYear(new Date().getFullYear() + 10)).toISOString().slice(0, 10);

		this.minPendingActionsSinceDate = new Date(new Date().setFullYear(new Date().getFullYear() - 10)).toISOString().slice(0, 10);
		this.maxPendingActionsSinceDate = this.userabsence.actiondtstart;
		if ('' == this.userabsence.actiondtstart) {
			this.userabsence.actiondtstart = this.userabsence.dtstart;
			this.maxPendingActionsSinceDate = this.userabsence.dtstart;
		}


	}


	ngOnInit(): void {

		//previously on ionViewWillLoad
		this.profile = JSON.parse(JSON.stringify(this.navParams.data.profile));
		//

		this.absenceUsersList  = JSON.parse(JSON.stringify(this.navParams.data.users));
		this.userabsence	  = Object.assign(this.userabsence, this.navParams.data.absence);
		this.usersDefault     = JSON.parse(JSON.stringify(this.navParams.data.users));
		this.usersForward	  = JSON.parse(JSON.stringify(this.navParams.data.users));
		this.groupsDefault    = JSON.parse(JSON.stringify(this.navParams.data.groups));
		this.groupsForward	  = JSON.parse(JSON.stringify(this.navParams.data.groups));
		this.workflows		  = this.navParams.data.workflows;

		let user = this.userService.getUser();
		//console.log('user', user);

		if (this.userabsence.iduser){
			//console.log('this.userabsence.iduser exist');
			this.userAbsenced.id = this.userabsence.iduser;
			for(let i=0; i<this.absenceUsersList.length ; i++){
				if(this.absenceUsersList[i].utilizador == this.userabsence.iduser){
					this.userAbsenced.name = this.absenceUsersList[i].nome;
					this.userAbsencedIndex = i;
				}
			}
		} else {
			//console.log('this.userabsence.iduser dont exist');
			for(let i=0; i<this.absenceUsersList.length ; i++){
				if(this.absenceUsersList[i].utilizador == user.id){
					this.userAbsenced.id = this.absenceUsersList[i].utilizador;
					this.userAbsenced.name = this.absenceUsersList[i].nome;
					this.userAbsencedIndex = i;
				}
			}
		}
		
		//console.log('this.usersDefault');
		//console.log(this.usersDefault);
		//console.log('this.groupsDefault');
		//console.log(this.groupsDefault);
		//console.log('this.workflows');
		//console.log(this.workflows);
		//console.log('this.profile.manageabsences');
		//console.log(this.profile.manageabsences);

		if (this.userabsence.iduserreplacementdefault){ // IF ALREADY HAVE A PERFORMER TO REPLACE WHEN BY OMISSION SHOW

			if(this.userabsence.owntypeuserreplacementdefault == 0){ // SEE IF IT IS A USER
				for(let i=0; i<this.usersDefault.length ; i++){
					if(this.usersDefault[i].utilizador == this.userabsence.iduserreplacementdefault){
						this.defaultNewPerformer = this.usersDefault[i].nome;
						//this.usersDefault[i].checkd = true;
						this.DefaultIndex = i;

					}
				}

			} else if(this.userabsence.owntypeuserreplacementdefault == 1){ // SEE IF IT IS A GROUP
				this.selectDefaultGroup = true;								//CHANGE DEFAULT DROPDOWN LIST USERS TO GROUPS
				for(let i=0; i<this.groupsDefault.length ; i++){
					if(this.groupsDefault[i].idgroups == this.userabsence.iduserreplacementdefault){
						this.defaultNewPerformer = this.groupsDefault[i].name;
						//this.groupsDefault[i].checkd = true;
						this.DefaultIndex = i;
					}
				}
			}

			if(this.defaultNewPerformer){
					this.defaultHasNewPerformer = true;
			}
		}

		if (this.userabsence.iduserreplacementforward){	// IF ALREADY HAVE A PERFORMER TO REPLACE WHEN FORWARD SHOW

			if(this.userabsence.owntypeuserreplacementforward == 0){
				//console.log('this.usersForward.length', this.usersForward.length);
				for(let i=0; i<this.usersForward.length ; i++){
					if(this.usersForward[i].utilizador == this.userabsence.iduserreplacementforward){
						this.forwardNewPerformer = this.usersForward[i].nome;
						//this.usersForward[i].checkd = true;
						this.ForwardIndex = i;
					}
				}

			} else if(this.userabsence.owntypeuserreplacementforward == 1){

				this.selectForwardGroup = true;	//CHANGE DEFAULT DROPDOWN LIST USERS TO GROUPS
				//console.log('this.groupsForward.length', this.groupsForward.length);
				for(let i=0; i<this.groupsForward.length ; i++){
					if(this.groupsForward[i].idgroups == this.userabsence.iduserreplacementforward){
						this.forwardNewPerformer = this.groupsForward[i].name;
						//this.groupsForward[i].checkd = true;
						this.ForwardIndex = i;
					}
				}
			}

			if(this.forwardNewPerformer){
					this.forwardHasNewPerformer = true;
			}
		}

		for(let w=0; w<this.workflows.length ; w++){
			this.countActions = this.countActions + this.workflows[w].actions.length;
		}
		if (this.countActions <= 0){
			this.showActions = false;
		} else {
			this.showActions = true;
			if(this.userabsence.actions){
				if (this.userabsence.actions.length > 0){ // IF ALREADY HAVE ACTIONS CONFIGURED
					//console.log('teste');
					for (let ua=0; ua<this.userabsence.actions.length; ua++){
						//console.log('teste1');
						if(this.userabsence.actions[ua].iduserreplacement !== ''){ // IF iduserreplacement IS DEFINED
							for (let w=0; w<this.workflows.length; w++){
								//console.log('teste2');
								for (let a=0; a<this.workflows[w].actions.length; a++){
									if(this.userabsence.actions[ua].idaction == this.workflows[w].actions[a].idaccao){
										//console.log('teste4');
										// CHECKAR PELO TIPO SE É USER OU GRUPO E PESQUISAR NAS VARIAVEIS E MUDAR TIPO DE DROPDOWN SE FOR GRUPO


										if(this.userabsence.actions[ua].owntypeuserreplacement==0){

											for(var u=0; u<this.workflows[w].actions[a].users.length ; u++){
												if(this.workflows[w].actions[a].users[u].utilizador == this.userabsence.actions[ua].iduserreplacement){
													this.workflows[w].actions[a].newPerformer = this.workflows[w].actions[a].users[u].nome;
													//this.workflows[w].actions[a].users[u].checkd = true;
												}
											}


										} else if(this.userabsence.actions[ua].owntypeuserreplacement==1){

											for(var g=0; g<this.workflows[w].actions[a].groups.length ; g++){
												if(this.workflows[w].actions[a].groups[g].idgroups == this.userabsence.actions[ua].iduserreplacement){
													this.workflows[w].actions[a].newPerformer = this.workflows[w].actions[a].groups[g].name;
													//this.workflows[w].actions[a].groups[g].checkd = true;
													this.workflows[w].actions[a].showGroups = true;
												}
											}


										}

										if(this.workflows[w].actions[a].newPerformer !== false) {
											this.workflows[w].actions[a].hasNewPerformer = true;
										}


									}
								}
							}
						}

					}
				}
			}

		}

		//console.log('showActions');
		//console.log(this.showActions);
		//console.log(this.countActions);
	}

	checkDateRestriction(): void {
		if ('' != this.userabsence.dtstart) {
			//console.log(this.userabsence.dtstart)
			if (this.userabsence.dtend < this.userabsence.dtstart) {
				this.userabsence.dtend = this.userabsence.dtstart;
				this.minEndDate = this.userabsence.dtstart;
			}

			this.maxPendingActionsSinceDate = this.userabsence.dtstart;
			if (this.userabsence.actiondtstart > this.userabsence.dtstart) {
				this.userabsence.actiondtstart = this.userabsence.dtstart;
			}

		}

		if ('' != this.userabsence.dtend) {
			if (this.userabsence.dtend < this.userabsence.dtstart) {
				this.userabsence.dtstart = this.userabsence.dtend;
				this.maxStartDate = this.userabsence.dtend;
			}
		}
	}

	performerChange(index: any, users: any, forward: boolean): void { // WHEN A CHANGE IN USER OCCURED

		if ( 'undefined' !== typeof index){
			
			if(forward){
				this.forwardHasNewPerformer = true;
				this.ForwardIndex = index;
			} else {
				this.defaultHasNewPerformer = true;
				this.DefaultIndex = index;
			}
			
			if ('undefined' !== typeof users[index].idgroups) {
				// IF THE CHANGED OCCURRED IN THE LIST OF GROUPS

				if(forward){
					// IF THE CHANGED OCCURRED IN FORWARD LIST
					this.forwardNewPerformer						= users[index].name;
					this.userabsence.iduserreplacementforward		= users[index].idgroups;
					this.userabsence.owntypeuserreplacementforward	= 1;
					
				} else {					// IF THE CHANGED OCCURRED IN BY OMISSION LIST
					this.defaultNewPerformer = users[index].name;
					this.userabsence.iduserreplacementdefault  = users[index].idgroups;
					this.userabsence.owntypeuserreplacementdefault = 1;
				}


			} else {
				// IF THE CHANGED OCCURRED IN THE LIST OF USERS

				if(forward){
					// IF THE CHANGED OCCURRED IN FORWARD LIST
					this.forwardNewPerformer						= users[index].nome;
					this.userabsence.iduserreplacementforward		= users[index].utilizador;
					this.userabsence.owntypeuserreplacementforward	= 0;
				} else {
					// IF THE CHANGED OCCURRED IN BY OMISSION LIST
					this.defaultNewPerformer						= users[index].nome;
					this.userabsence.iduserreplacementdefault		= users[index].utilizador;
					this.userabsence.owntypeuserreplacementdefault	= 0;
				}
			}
		}else{

			if(forward){
				this.forwardHasNewPerformer = false;
				this.forwardNewPerformer = '';
				this.ForwardIndex = undefined;

			} else {
				this.defaultHasNewPerformer = false;
				this.userabsence.iduserreplacementdefault      = '';
				this.userabsence.owntypeuserreplacementdefault = '';
				this.defaultNewPerformer = '';
				this.DefaultIndex = undefined;
			}
		}

		//console.log("default new performer",this.defaultNewPerformer);
		//console.log("index",index);
		//console.log("default index",this.DefaultIndex);
		//console.log("forward new performer",this.forwardNewPerformer);
		//console.log("forward index",this.ForwardIndex);
	}

	toogleAbsenceUserList(){
		this.showAbsenceUserList = !this.showAbsenceUserList;
	}

	userabsencedChange(value){
		this.userAbsencedIndex = value;
		this.userAbsenced.id = this.absenceUsersList[this.userAbsencedIndex].utilizador;
		this.userAbsenced.name = this.absenceUsersList[this.userAbsencedIndex].nome;

		//console.log("Index ->",this.userAbsencedIndex);
		//console.log("nome ->",this.userAbsenced.name);
		//console.log("Id ->",this.userAbsenced.id);
		
		/*for(let i=0; i<users.length ; i++){
			if(i == index){
				this.userAbsenced.id = users[i].utilizador;
				this.userAbsenced.name = users[i].nome;
				users[i].checkd = true;
			} else {
				users[i].checkd = false;
			}
		}*/
	}

	actionPerformerChange(performer: any, action: any, group: boolean){	// WHEN A CHANGE IN ACTION'S PERFORMER OCCURED
		
		if('undefined' !== typeof performer){
			if(group){
				action.hasNewPerformer = true;
				action.newPerformer = performer;
			} else{
				action.hasNewPerformer = true;
				action.newPerformer = performer;
			}
		}else{
			if(group){
				action.hasNewPerformer = false;
				action.newPerformer = undefined;
			} else{
				action.hasNewPerformer = false;
				action.newPerformer = undefined;
			}
		}

		//console.log("performer",performer);
		//console.log("new performer",action.newPerformer);

		/*if ('undefined' == typeof performer.idgroups){			//CHECK IF THE SELECTED ONE IS A USER OR A GROUP
			for(let i=0; i< action.users.length; i++){

				if(action.users[i]==performer) {
					action.users[i].checkd = !action.users[i].checkd;			//TOOGLE THE CHECKD ONE
					if (action.users[i].checkd){
						action.hasNewPerformer = true;
						action.newPerformer = action.users[i].nome;						//MAKE SURE ALL OTHERS  ARE NOT SELECTED
						for(let i=0; i< action.users.length; i++){
							if(performer != action.users[i]) {
								action.users[i].checkd = false;
							}
						}
					}

				}
			}
		} else {

			for(let i=0; i< action.groups.length; i++){
				if(action.groups[i]==performer) {
					action.groups[i].checkd = !action.groups[i].checkd;			//TOOGLE THE CHECKD ONE
					if (action.groups[i].checkd){
						action.hasNewPerformer = true;
						action.newPerformer = action.groups[i].name;
						for(let i=0; i< action.groups.length; i++){				//MAKE SURE ALL OTHERS USERS ARE NOT SELECTED
							if(performer != action.groups[i]) {
								action.groups[i].checkd = false;
							}
						}
					}

				}
			}
		}*/

	}

	toogleSelectDefault(): void {	// WHEN A CHANGE BETWEEN USERS OR GROUPS OCCURED MAKE SURE THE OTHER LIST ARE NOT SELECTED
		this.selectDefaultGroup = !this.selectDefaultGroup;
		this.defaultHasNewPerformer = false;
		this.DefaultIndex = undefined;
		/*if (this.selectDefaultGroup) {
			for(let i=0; i< this.usersDefault.length; i++){
				this.usersDefault[i].checkd = false;
			}
		} else if (!this.selectDefaultGroup) {
			for(let i=0; i< this.groupsDefault.length; i++){
				this.groupsDefault[i].checkd = false;
			}
		}*/

	}

	toogleSelectForward(): void {	// WHEN A CHANGE BETWEEN USERS OR GROUPS OCCURED MAKE SURE THE OTHER LIST ARE NOT SELECTED
		this.selectForwardGroup = !this.selectForwardGroup;
		this.forwardHasNewPerformer = false;
		this.ForwardIndex = undefined;
		/*if (this.selectForwardGroup) {
			for(let i=0; i< this.usersForward.length; i++){
				this.usersForward[i].checkd = false;
			}
		} else if (!this.selectForwardGroup) {
			for(let i=0; i< this.groupsForward.length; i++){
				this.groupsForward[i].checkd = false;

			}
		}*/

	}

	toogleActionSelect(action:any): void {

		action.hasNewPerformer = false;
		action.showGroups = !action.showGroups;
		action.newPerformer = '';
		/*if (action.showGroups) {
			for(let i=0; i< action.users.length; i++){
				action.users[i].checkd = false;
			}
		} else if (!action.showGroups) {
			for(let i=0; i< action.groups.length; i++){
				action.groups[i].checkd = false;
			}
		}*/
	}

	toogleList(showList: boolean): void {
		//console.log(showList);
		showList = !showList;
	}

	toogleDefaultList(): void {
		this.showDefaultList = !this.showDefaultList;
	}

	toogleForwardList(): void {
		this.showForwardList = ! this.showForwardList;
	}


	toogleListForAction(action: any): void {
		action.showList = !action.showList;
	}

	toogleListForWorkflow(workflow: any): void {
		workflow.showList = !workflow.showList;
	}

	save(): void {

		//console.log('save');

		this.userabsence.iduser = this.userAbsenced.id;

		// SAVE IDS FOR SELECT BY OMISSION
			// IF IT IS A USER
		if(!this.selectDefaultGroup){

			this.userabsence.iduserreplacementdefault = this.usersDefault[this.DefaultIndex].utilizador;
			this.userabsence.owntypeuserreplacementdefault = this.selectDefaultGroup;

			/*for (let u = 0; u < this.usersDefault.length; u++){
				if (this.usersDefault[u].checkd) {
					this.userabsence.iduserreplacementdefault = this.usersDefault[u].utilizador;
					this.userabsence.owntypeuserreplacementdefault = this.selectDefaultGroup;
				}
			}*/

			// IF IT IS A GROUP
		} else if (this.selectDefaultGroup) {

			this.userabsence.iduserreplacementdefault = this.groupsDefault[this.DefaultIndex].idgroups;
			this.userabsence.owntypeuserreplacementdefault = this.selectDefaultGroup;

			/*for (let g = 0; g < this.groupsDefault.length; g++){
				if (this.groupsDefault[g].checkd) {
					this.userabsence.iduserreplacementdefault = this.groupsDefault[g].idgroups;
					this.userabsence.owntypeuserreplacementdefault = this.selectDefaultGroup;
				}
			}*/
		}

		// SAVE IDS FOR SELECT FORWARD
			// IF IT IS A USER
		if('undefined' !== typeof this.ForwardIndex){
			
			if(!this.selectForwardGroup){

				this.userabsence.iduserreplacementforward = this.usersForward[this.ForwardIndex].utilizador;
				this.userabsence.owntypeuserreplacementforward = this.selectForwardGroup;
	
				/*for (let u = 0; u < this.usersForward.length; u++){
					if (this.usersForward[u].checkd) {
						this.userabsence.iduserreplacementforward = this.usersForward[u].utilizador;
						this.userabsence.owntypeuserreplacementforward = this.selectForwardGroup;
					}
				}*/
				// IF IT IS A GROUP
			} else if (this.selectForwardGroup) {
	
				this.userabsence.iduserreplacementforward = this.groupsForward[this.ForwardIndex].idgroups;
				this.userabsence.owntypeuserreplacementforward = this.selectForwardGroup;
	
				/*for (let g = 0; g < this.groupsForward.length; g++){
					if (this.groupsForward[g].checkd) {
						this.userabsence.iduserreplacementforward = this.groupsForward[g].idgroups;
						this.userabsence.owntypeuserreplacementforward = this.selectForwardGroup;
					}
				}*/
			}
		}
		

		//SAVE IDS FOR ACTIONS CHANGED

		const actionsChanged: Array<{idaccao: number; owner_type: number; idpessoa: string}> = [];

		////console.log(this.workflows);

		for(let w=0; w<this.workflows.length; w++){
			for(let a=0; a<this.workflows[w].actions.length; a++){
				for(let u=0; u<this.workflows[w].actions[a].users.length; u++){
					if(this.workflows[w].actions[a].users[u].nome === this.workflows[w].actions[a].newPerformer){
						actionsChanged.push({idaccao :  this.workflows[w].actions[a].idaccao,
											 owner_type: this.workflows[w].actions[a].showGroups,
											 idpessoa:   this.workflows[w].actions[a].users[u].utilizador});
					}
				}

				for(let u=0; u<this.workflows[w].actions[a].groups.length; u++){
					if(this.workflows[w].actions[a].groups[u].name === this.workflows[w].actions[a].newPerformer){
						actionsChanged.push({idaccao :  this.workflows[w].actions[a].idaccao,
											 owner_type: this.workflows[w].actions[a].showGroups,
											 idpessoa:   this.workflows[w].actions[a].groups[u].idgroups});

					}
				}
			}
		}

		//console.log(actionsChanged);
		this.dismiss({absence: this.userabsence, actions: actionsChanged});
	}

	dismiss(params: any) {
		//console.log('params', params);
		this.viewCtrl.dismiss(params);
	}

	btnDismiss(): void {
		this.dismiss({});
	}

	btnDisabled(): boolean {

		if (   '' !== this.userabsence.iduserreplacementdefault
			&& '' !== this.userabsence.dtstart
			&& '' !== this.userabsence.dtend
			&& '' !== this.userabsence.actiondtstart) {
			return false;
		}
		return true;
	}
}
