import { Component, ViewChild, OnDestroy, OnInit } from '@angular/core';
import { AlertController, IonNav } from '@ionic/angular';
import { IonFab } from '@ionic/angular';

import { Subscription } from 'rxjs';

import { DocumentService } from '../../services/document-service';
import { MydocsService } from '../../services/mydocs-service';
import { ConfigService } from '../../services/config-service';
import { UserService } from '../../services/user-service';
import { StorageUtils } from '../../utils/storage-utils';
import { FileService } from '../../services/file/file-service';

import { Toast } from '../../controllers/message-controller';
import { Loading } from '../../controllers/message-controller';

import { Camera, CameraResultType, CameraSource } from '@capacitor/camera';
import { Events } from '../../services/event-service';
import { WebStorageService } from '../../services/webstorage-service';
import { promise } from 'protractor';
import { TranslateService } from '@ngx-translate/core';

@Component({
	selector: 'app-page-list-mydocs',
	templateUrl: 'list-mydocs.html',
	providers: [MydocsService, DocumentService, Toast],
	styleUrls: ['list-mydocs.scss']
})

export class ListMyDocsPage implements OnInit {

	@ViewChild('fab') fab: IonFab;

	hasMydocs = true;
	delDocs = false;
	mydocs: any = new Array();
	filtermydocs: any = {total: 0, name: 'MyDocs'};
	toggledelete = false;
	selectedAll = false;
	getmydocs: boolean;
	user: any;
	addeddocs = 0;
	ficheiro = '';
	iconLoading = false;
	title = ''; //page title
	params: Record<string, unknown>;
	itemsmydocs: Array<any> = []; //mydocs
	checkedItems: Array<any> = [];
	total: string; //total
	countItemsChecked = 0;
	alert: any;
	editEnable = false;
	infiniteScroll: Subscription;
	sortTitle = false;
	sortDate = true;
	typeSort = 'insertedon';
	needle: number;
	public show = true;
	public longPressActive = false;
	public sortDisabled = false;
	public startLocal = 0;
	public order: string;
	public searchParams = {
		start: this.startLocal,
		order: 'insertedon',
		way:  'desc'
	};
	public translation = {
		deleteMsg: 'Do you want to delete the selected files?',
		deleteMsgOK: 'Documents removed successfully',
		deleteMsgErr: 'An error occured while removing documents',
		deleteMsgErr2: 'No files were selected!',
		deleteMsg3: 'Do you want to delete this file?',
		deleteMsgOK3: 'Document removed successfully',
		deleteMsgErr3: 'An error occured while removing the selected document',
		uploadMsg: 'Do you want to upload the file 123123',
		uploadMsgOK: 'Document inserted successfully',
		uploadMsgErr: 'An error occured while inserting the document'

	};

	constructor(
		    public alertCtrl: AlertController,
		    public events: Events,
		    public localStorageService: WebStorageService,
		    public mydocsService: MydocsService,
		    public fileService: FileService,
		    public documentService: DocumentService,
		    public configService: ConfigService,
		    public userService: UserService,
		    public toast: Toast,
		    public loading: Loading,
			public nav: IonNav,
			public translateService: TranslateService
		    ) {
			    this.toggledelete = false;
			    StorageUtils.setItem('tab_selected', 'mydocs');
			    this.localStorageService.set('tab_selected', 'mydocs');
			    this.user = userService.getUser();

				this.translateService.get('Do you want to delete the selected files?').subscribe(
					value => {
						this.translation.deleteMsg = value;
					}
				);
				this.translateService.get('Documents removed successfully').subscribe(
					value => {
						this.translation.deleteMsgOK = value;
					}
				);
				this.translateService.get('An error occured while removing documents').subscribe(
					value => {
						this.translation.deleteMsgErr = value;
					}
				);
				this.translateService.get('No files were selected!').subscribe(
					value => {
						this.translation.deleteMsgErr2 = value;
					}
				);
				this.translateService.get('Do you want to delete this file?').subscribe(
					value => {
						this.translation.deleteMsg3 = value;
					}
				);
				this.translateService.get('Document removed successfully').subscribe(
					value => {
						this.translation.deleteMsgOK3 = value;
					}
				);
				this.translateService.get('An error occured while removing the selected document').subscribe(
					value => {
						this.translation.deleteMsgErr3 = value;
					}
				);
				this.translateService.get('Do you want to upload the file').subscribe(
					value => {
						this.translation.uploadMsg = value;
					}
				);
				this.translateService.get('Document inserted successfully').subscribe(
					value => {
						this.translation.uploadMsgOK = value;
					}
				);
				this.translateService.get('An error occured while inserting the document').subscribe(
					value => {
						this.translation.uploadMsgErr = value;
					}
				);
		    }

	ionViewWillEnter() {
		this.localStorageService.set('Root','ListMyDocsPage');
		this.loading.show({})
			.then(
				() => this.findDocs(this.loading)
			);
		if (this.checkedItems.length === 0) {
			this.longPressActive = false;
			this.sortDisabled = false;
		}
	}

	findDocs(loading: any) {
		this.startLocal = 0;
		this.searchParams.start = this.startLocal;
		this.mydocsService
			.findmydocs(this.searchParams)
			.subscribe({
				next: (data: any) => {
					this.mydocs = data.mydocs;

					this.filtermydocs.total = data.total;

					this.startLocal = this.startLocal + 20;

					if(this.startLocal > this.filtermydocs.total){
						this.startLocal = this.filtermydocs.total;
					}

					this.configService.setVar('mydocs', this.mydocs);

					this.userService.refreshLogo();

					if(this.mydocs.length > 0){
						this.hasMydocs = true;
						this.itemsmydocs = this.mydocs;
					}else{
						this.hasMydocs = false;
					}
				},
				complete: () => {
					loading.dismiss();
					setTimeout(() => this.show = true);
				}
			});
	}

	sorting(sortType: string){
		switch (sortType) {
			case 'title':
				this.searchParams.order = 'titulo';
				this.sortTitle = (this.typeSort === sortType) ? this.sortTitle : !this.sortTitle;
				if(this.sortTitle){
					this.searchParams.way = 'asc';
					this.sortTitle = false;
				}else{
					this.searchParams.way = 'desc';
					this.sortTitle = true;
				}
				break;
			case 'insertedon':
				this.searchParams.order = 'insertedon';
				this.sortDate = (this.typeSort === sortType) ? this.sortDate : !this.sortDate;
				if(this.sortDate){
					this.searchParams.way = 'asc';
					this.sortDate = false;
				}else{
					this.searchParams.way = 'desc';
					this.sortDate = true;
				}
				break;
		}
		this.typeSort = sortType;
		this.loading.show({})
			.then(
				() => this.findDocs(this.loading)
			);
	}


	download(iddoc): void {
		if (!this.delDocs) {
			const user = this.userService.getUser();
			if ('0' == this.localStorageService.get('platform_cordova')) {
				this.documentService.downloadbytoken({iddoc, chronschemasort: user.defaultchronschemasort}, '');
			} else {
				this.fileService.download(iddoc);
			}
		}
	}

	search(str) {
		const itens = this.itemsmydocs;
		let i = 0;

		if(str !== undefined){
			for( i = 0 ; i < itens.length ; i++){
				if(!itens[i].titulo.includes(str)){
					document.getElementById(itens[i].iddoc).classList.add('mostrar');
				} else {
					document.getElementById(itens[i].iddoc).classList.remove('mostrar');
				}
			}
		}
	}

	onChange(item) {
		if(this.checkedItems.indexOf(item) >= 0){
			this.checkedItems.splice(this.checkedItems.indexOf(item), 1);
		}else {
			this.checkedItems.push(item);
		}
	}

	selectall(){
		let i = 0;
	    if(this.checkedItems.length === this.itemsmydocs.length) {
			for (const doc of this.itemsmydocs) {
				this.tapDoc(i, doc);
				i++;
			}
	    }else{
			for (const doc of this.itemsmydocs) {
				let flag = 0;
				for (const item of this.checkedItems) {
					if (item === doc.iddoc) {
						flag = 1;
					}
				}
				if (flag === 0) {
					this.tapDoc(i, doc);
				}
				i++;
			}
	    }
	}

	pressDoc(i: number, doc: any) {
		if (!this.longPressActive) {
			this.longPressActive = true;
			this.sortDisabled = true;
			this.tapDoc(i, doc);
		} else {
			this.tapDoc(i, doc);
		}
	}

	tapDoc(i: number, doc: any) {
		if (this.longPressActive) {
			if (this.inArray(doc.iddoc, this.checkedItems)) {
				this.checkedItems.splice(this.needle, 1);
				document.getElementById(doc.iddoc).classList.remove('selected');
				if (this.checkedItems.length === 0) {
					this.longPressActive = false;
					this.sortDisabled = false;
				}
			} else {
				this.checkedItems.push(doc.iddoc);
				document.getElementById(doc.iddoc).classList.add('selected');
			}
		}
	}

	inArray(needle, haystack) {
		const length = haystack.length;
		for(let i = 0; i < length; i++) {
			if(haystack[i] == needle) {
				this.needle = i;
				return true;
			}
		}
		return false;
	}

	presentAlert1(mydocsService) {
	    let i = 0;
	    this.alertCtrl.create({
		    header: 'Delete',
		    message: this.translation.deleteMsg,
		    buttons: [{
			    text: 'Cancel',
			    role: 'cancel',
			    cssClass: 'secondary',
			    handler: () => {
			      //console.log('Confirm Cancel: blah');
			    }
			}, {
			    text: 'OK',
			    handler: () => {
					this.mydocsService.removemydocs({iddocs:this.checkedItems})
					.subscribe({
						next: data => {
							this.toast.show({message: this.translation.deleteMsgOK , cssClass: 'success', dismissOnPageChange: false});

							this.reload();

							this.checkedItems = [];
						},
						error: err => {
							this.toast.show({
								message: this.translation.deleteMsgErr,
								cssClass: 'error',
								dismissOnPageChange: false
							});
						}
					});
			    }
		    }]
	    }).then((alert) => {
			alert.present();
		});
	}

	presentAlert2() {
		let i = 0;
		this.alertCtrl.create({
			header: 'Delete',
			message: this.translation.deleteMsgErr2,
			buttons: ['OK']
		}).then((alert) => {
			alert.present();
		});
	}
	presentAlert3(iddoc: number) {
		let i = 0;
		const arr = [iddoc];
	    this.alertCtrl.create({
		    header: 'Delete',
		    message: this.translation.deleteMsg3,
		    buttons: [{
			    text: 'Cancel',
			    role: 'cancel',
			    cssClass: 'secondary',
			    handler: () => {
			      //console.log('Confirm Cancel: blah');
			    }
			}, {
			    text: 'OK',
			    handler: () => {
					this.mydocsService.removemydocs({iddocs: arr})
					.subscribe({
						next: () => {
							this.toast.show({message: this.translation.deleteMsgOK3, cssClass: 'success', dismissOnPageChange: false});

							this.reload();
						},
						error: () => {
							this.toast.show({
								message: this.translation.deleteMsgErr3,
								cssClass: 'error',
								dismissOnPageChange: false
							});
						}
					});
			    }
		    }]
	    }).then((alert) => {
			alert.present();
		});
	}

	presentWhichAlert(){
		if(this.checkedItems.length > 0) {
			this.presentAlert1(this.mydocsService);
		}
		else {
			this.presentAlert2();
		}
	}

	showDelCheckboxes(){
		const list = document.getElementsByClassName('showdel');
		let i = 0;

		if (this.toggledelete == false) {
		    this.delDocs = false;
			for(i=0;i<list.length;i++){
				list[i].classList.add('delete');
			}
		} else {
		    this.delDocs = true;
			for(i=0;i<list.length;i++){
				list[i].classList.remove('delete');
			}
		}
	}

	reload() {
		this.show = false;
		this.loading.show({})
			.then(
				() => this.findDocs(this.loading)
			);
	}

	openCamera(){
		//take a Photo
		Camera.getPhoto({
			resultType: CameraResultType.Base64,
			source: CameraSource.Camera,
			quality: 100
		}).then((capturedPhoto) => {
			const date = new Date().toLocaleString();
			if (capturedPhoto !== undefined) {
				const photoBlob = this.convertBase64ToBlob(capturedPhoto.base64String);
				const photoName = 'Captured Document - ('+date+').png';
				this.user = this.userService.getUser();
				const iddoc = this.getBase64(
					this.alertCtrl,
					this.mydocsService,
					this.itemsmydocs,
					this.user,
					this.loading,
					this.toast,
					photoBlob,
					photoName
					);
				this.addeddocs += 1;
			}
		});
	}

	convertBase64ToBlob(base64) {
		// Decode Base64 string
		const decodedData = window.atob(base64);

		// Create UNIT8ARRAY of size same as row data length
		const uInt8Array = new Uint8Array(decodedData.length);

		// Insert all character code into uInt8Array
		for (let i = 0; i < decodedData.length; ++i) {
		      uInt8Array[i] = decodedData.charCodeAt(i);
		}

		// Return BLOB image after conversion
	//			  return new Blob([uInt8Array], { type: 'application/octet-stream' });
		return new Blob([uInt8Array], { type: 'image/png' });
      }

	openFile(){
		document.getElementById('fileInput').click();
	}

	getBase64(alertCtrl, mydocsService, itemsmydocs, user, loading, toast, file, filename = undefined) {

	    const returnvalue = '';
	    const reader = new FileReader();
	    reader.readAsDataURL(file);

	    reader.onload = async () => {
		if (file.name === undefined && filename !== undefined) {
			file.name = filename;
		}
		const aux = (reader.result as string).split('base64,')[1];
		const dados = {login: user.username,titulo:file.name,ficheiro:aux};

		const alert = await alertCtrl.create({
			title: 'Upload',
			message: this.translation.uploadMsg + ' ' + file.name + ' (' + file.size + ' bytes)?',
			buttons: [{
				text: 'Cancel',
				role: 'cancel',
				cssClass: 'secondary',
				handler: () => {
					//console.log('Confirm Cancel: blah');
				}
			    },
			{
				text: 'Yes',
				handler: () => {
					mydocsService.fileuploaded(dados).subscribe({
					    next: successData => {
						    toast.show({message: this.translation.uploadMsgOK, cssClass: 'success', dismissOnPageChange: false});
						    this.reload();
					    },
					    error: err => {
						    toast.show({
								message: this.translation.uploadMsgErr,
								cssClass: 'error',
								dismissOnPageChange: false
							});
					    }
					});
				}
			}]
		});
		await alert.present();
		//console.log(reader.result);
	    };

	    //reader.onerror = function(error) {
		//console.log('Error when converting PDF file to base64: ', error);
	    //};
	    return returnvalue;
	}

	fileUploaded(){

		const fileInput = (document.getElementById('fileInput') as HTMLInputElement).files[0];
		this.user = this.userService.getUser();

		if(fileInput){
		    const iddoc = this.getBase64(
				this.alertCtrl,
				this.mydocsService,
				this.itemsmydocs,
				this.user,
				this.loading,
				this.toast,
				fileInput
				);
		    }
		this.addeddocs += 1;
	}

	ionViewWillLeave() {
		this.editEnable = false;
		this.fab.close();

		if (this.loading.isOn) {
			this.loading.dismiss();
		}

	}

	ngOnInit(): void {
		if (this.loading.isOn) {
			setTimeout(() => {
				this.loading.dismiss();
			}, 100);
		}
	}

	ionViewDidEnter() {
	}

	ionViewDidLeave() {
		this.countItemsChecked = 0;
		this.iconLoading = false;
	}

	processResults(data: any) {

	}


	goToHome(){
		this.nav.popToRoot();
		/*this.events.publish('doc:setRootPage', {
			page: 'HomePage',
			params: {
				actions: undefined,
				frompage: 'ListActionsPage'
			}
		});*/
	}

	goToMenu(){
		this.events.publish('doc:pushPage', {
			page: 'MenuPage',
			params: {
				actions: undefined,
				frompage: 'ListActionsPage'
			}
		});
	}

	doInfinite(infiniteScroll: any): void {
		this.searchParams.start = this.startLocal;
		this.mydocsService
			.findmydocs(this.searchParams)
			.subscribe({
				next: (data: any) => {
					this.mydocs = [ ...this.mydocs, ...data.mydocs];

					this.total = data.total;

					this.startLocal = this.startLocal + 20;
					if (this.startLocal > data.total) {
						this.startLocal = data.total;
					}

					this.configService.setVar('mydocs', this.mydocs);

					if(this.mydocs.length > 0){
						this.hasMydocs = true;
						this.itemsmydocs = this.mydocs;
					}else{
						this.hasMydocs = false;
					}
				},
				complete: () => {
					infiniteScroll.target.complete();
				}
			});
	}
}
