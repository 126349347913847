import { Inject, Injectable, InjectionToken } from '@angular/core';
import { StorageService } from 'ngx-webstorage-service';
import { ConsoleService } from './console/console-service';


export const WEB_STORAGE_SERVICE =
    new InjectionToken<StorageService>('WEB_STORAGE_SERVICE');

@Injectable()
export class WebStorageService {

    constructor(
        @Inject(WEB_STORAGE_SERVICE) public storage: StorageService,
        public console: ConsoleService
    )  {}

    public get(key: string, prefix?: string): any {
        if (prefix !== undefined) {
            //this.console.log('WebStorageService', 'get', 'key = ' + key + ', prefix = ' + prefix);
            key = prefix + key;
            return this.storage.get(key);
        }
        //this.console.log('WebStorageService', 'get', 'key = ' + key, 'result = ' + this.storage.get(key));

        return (this.storage.get(key) === undefined) ? null : this.storage.get(key);
    }

    public set(key: string,value: unknown, prefix?: string): void {
        if (prefix !== undefined) {
            //this.console.log('WebStorageService', 'set', 'key = ' + key + ', value = ' + value + ', prefix = ' + prefix);
            key = prefix + key;
            this.storage.set(key, value);
            return;
        }
        //this.console.log('WebStorageService', 'set', 'key = ' + key + ', value = ' + value);
        this.storage.set(key, value);
    }

    public remove(key: string, prefix?: string): void {
        if (prefix !== undefined) {
           //this.console.log('WebStorageService', 'remove', 'key = ' + key + ', prefix = ' + prefix);
            key = prefix + key;
            this.storage.remove(key);
            return;
        }
        //this.console.log('WebStorageService', 'remove', key);
        this.storage.remove(key);
    }
}
