import { NgModule } from '@angular/core';
import { PreloadAllModules, RouterModule, Routes } from '@angular/router';
import { AuthGuardService } from './Interceptors/auth-guard';

const routes: Routes = [
  /**,
   * PAGES ROUTING LOCATED IN /src/app/pages
   */
  {
    path: 'login',
    loadChildren: () => import('./pages/login/login.module').then( m => m.LoginPageModule)
  },
  {
    path: 'home',
    loadChildren: () => import('./pages/home/home.module').then( m => m.HomePageModule),
    canActivate: [AuthGuardService]
  },
  {
    path: 'about',
    loadChildren: () => import('./pages/about/about.module').then( m => m.AboutPageModule),
    canActivate: [AuthGuardService]
  },
  {
    path: 'account-form',
    loadChildren: () => import('./pages/account-form/account-form.module').then( m => m.AccountFormPageModule),
    canActivate: [AuthGuardService]
  },
  {
    path: 'action',
    loadChildren: () => import('./pages/action/action.module').then( m => m.ActionPageModule),
    canActivate: [AuthGuardService]
  },
  {
    path: 'action-configfilter',
    loadChildren: () => import('./pages/action-configfilter/action-configfilter.module').then( m => m.ActionConfigfilterPageModule),
    canActivate: [AuthGuardService]
  },
  {
    path: 'action-execute',
    loadChildren: () => import('./pages/action-execute/action-execute.module').then( m => m.ActionExecutePageModule),
    canActivate: [AuthGuardService]
  },
  {
    path: 'action-forward',
    loadChildren: () => import('./pages/action-forward/action-forward.module').then( m => m.ActionForwardPageModule),
    canActivate: [AuthGuardService]
  },
  {
    path: 'action-schedule',
    loadChildren: () => import('./pages/action-schedule/action-schedule.module').then( m => m.ActionSchedulePageModule),
    canActivate: [AuthGuardService]
  },
  {
    path: 'advanced-search',
    loadChildren: () => import('./pages/advanced-search/advanced-search.module').then( m => m.AdvancedSearchPageModule),
    canActivate: [AuthGuardService]
  },
  {
    path: 'chronologicalscheme',
    loadChildren: () => import('./pages/chronologicalscheme/chronologicalscheme.module').then( m => m.ChronologicalschemePageModule),
    canActivate: [AuthGuardService]
  },
  {
    path: 'document',
    loadChildren: () => import('./pages/document/document.module').then( m => m.DocumentPageModule),
    canActivate: [AuthGuardService]
  },
  {
    path: 'eml',
    loadChildren: () => import('./pages/eml/eml.module').then( m => m.EmlPageModule),
    canActivate: [AuthGuardService]
  },
  {
    path: 'error',
    loadChildren: () => import('./pages/error/error.module').then( m => m.ErrorPageModule),
    canActivate: [AuthGuardService]
  },
  {
    path: 'info',
    loadChildren: () => import('./pages/info/info.module').then( m => m.InfoPageModule),
    canActivate: [AuthGuardService]
  },
  {
    path: 'link-documents',
    loadChildren: () => import('./pages/link-documents/link-documents.module').then( m => m.LinkDocumentsPageModule),
    canActivate: [AuthGuardService]
  },
  {
    path: 'list-actions',
    loadChildren: () => import('./pages/list-actions/list-actions.module').then( m => m.ListActionsPageModule),
    canActivate: [AuthGuardService]
  },
  {
    path: 'list-actions-document',
    loadChildren: () => import('./pages/list-actions-document/list-actions-document.module').then( m => m.ListActionsDocumentPageModule),
    canActivate: [AuthGuardService]
  },
  {
    path: 'list-documents',
    loadChildren: () => import('./pages/list-documents/list-documents.module').then( m => m.ListDocumentsPageModule),
    canActivate: [AuthGuardService]
  },
  {
    path: 'list-documents-assoc',
    loadChildren: () => import('./pages/list-documents-assoc/list-documents-assoc.module').then( m => m.ListDocumentsAssocPageModule),
    canActivate: [AuthGuardService]
  },
  {
    path: 'list-emails',
    loadChildren: () => import('./pages/list-emails/list-emails.module').then( m => m.ListEmailsPageModule),
    canActivate: [AuthGuardService]
  },
  {
    path: 'list-emails-assoc',
    loadChildren: () => import('./pages/list-emails-assoc/list-emails-assoc.module').then( m => m.ListEmailsAssocPageModule),
    canActivate: [AuthGuardService]
  },
  {
    path: 'list-mydocs',
    loadChildren: () => import('./pages/list-mydocs/list-mydocs.module').then( m => m.ListMydocsPageModule),
    canActivate: [AuthGuardService]
  },
  {
    path: 'mark',
    loadChildren: () => import('./pages/mark/mark.module').then( m => m.MarkPageModule),
    canActivate: [AuthGuardService]
  },
  {
    path: 'menu',
    loadChildren: () => import('./pages/menu/menu.module').then( m => m.MenuPageModule),
    canActivate: [AuthGuardService]
  },
  {
    path: 'user',
    loadChildren: () => import('./pages/user/user.module').then( m => m.UserPageModule),
    canActivate: [AuthGuardService]
  },
  {
    path: 'user-absence',
    loadChildren: () => import('./pages/user-absence/user-absence.module').then( m => m.UserAbsencePageModule),
    canActivate: [AuthGuardService]
  },
  {
    path: 'user-absencelist',
    loadChildren: () => import('./pages/user-absencelist/user-absencelist.module').then( m => m.UserAbsencelistPageModule),
    canActivate: [AuthGuardService]
  },
  {
    path: 'user-comment',
    loadChildren: () => import('./pages/user-comment/user-comment.module').then( m => m.UserCommentPageModule),
    canActivate: [AuthGuardService]
  },
  {
    path: 'user-commentlist',
    loadChildren: () => import('./pages/user-commentlist/user-commentlist.module').then( m => m.UserCommentlistPageModule),
    canActivate: [AuthGuardService]
  },
  {
    path: 'user-profile',
    loadChildren: () => import('./pages/user-profile/user-profile.module').then( m => m.UserProfilePageModule),
    canActivate: [AuthGuardService]
  },
  {
    path: 'vip',
    loadChildren: () => import('./pages/vip/vip.module').then( m => m.VipPageModule),
    canActivate: [AuthGuardService]
  },
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, {
      preloadingStrategy: PreloadAllModules,
      //useHash: true
     })
  ],
  exports: [RouterModule]
})
export class AppRoutingModule { }
