export class StringUtils {

	static replaceSpecialChars(str: string): string {
		if ('string' !== typeof str) {
			return str;
		}

		let arr_search = Array(
			'á','à','ã','â','é','è','ê','í','ì','î','ó','ò','õ','ô','ú','ù','û','ç','ä','ë','ï','ö','ü',
			'Á','À','Ã','Â','É','È','Ê','Í','Ì','Î','Ó','Ò','Õ','Ô','Ú','Ù','Û','Ç', 'Ä','Ë','Ï','Ö','Ü');
		let arr_replace = Array(
			'a','a','a','a','e','e','e','i','i','i','o','o','o','o','u','u','u','c','a','e','i','o','u',
			'A','A','A','A','E','E','E','I','I','I','O','O','O','O','U','U','U','C','A','E','I','O','U');

		for (let i = 0; i < arr_search.length; i++) {
			str = str.replace(new RegExp(arr_search[i], 'gi') , arr_replace[i]);
		}

		return str;
	}

	static replaceToFs(str: string): string {
		if ('string' !== typeof str) {
			return str;
		}

		let arr_search = Array('/');
		let arr_replace = Array('-');

		for (let i = 0; i < arr_search.length; i++) {
			str = str.replace(new RegExp(arr_search[i], 'gi') , arr_replace[i]);
		}

		return str;
	}


	static base64_encode(str: string): string {
		try {
			return btoa(str);
		} catch(err) {
			//console.log('base64_encode error: ' + err.message);
		}

		return str;
	}
}
