import { Injectable } from '@angular/core';
import { StorageUtils } from '../utils/storage-utils';
import { WebStorageService } from './webstorage-service';

@Injectable({
	providedIn: 'root',
  })
export class ConfigService {
	vars: any = {};

	constructor(public localStorageService: WebStorageService) {
	}

	public setVar(varName: string, varValue: any): void {
		this.vars[varName] = varValue;
	}

	public deleteVar(varName: string): any{
		delete this.vars[varName];
	}

	public getVar(varName: string): any {
		if ('server_url' == varName) {
			if ('1' === StorageUtils.getItem('platform_cordova')) {
				this.setVar(varName,  StorageUtils.getItem('server_url') + '/api');
				this.setVar(varName,  this.localStorageService.get('server_url') + '/api');
			} else  {
				this.setVar(varName, location.protocol + '//' + location.hostname +  (location.port && ':' + location.port) + '/api');
			}
		}

		return this.vars[varName];
	}

	public getServerurl(): string {
		let server_url = '';
		if ('1' === StorageUtils.getItem('platform_cordova')) {
			server_url = StorageUtils.getItem('server_url');
			server_url = this.localStorageService.get('server_url');
		} else  {
			server_url = location.protocol + '//' + location.hostname +  (location.port && ':' + location.port);
		}

		return server_url;
	}

	public getAllVar(): any {
		return this.vars;
	}

	public getTime2refresh(): number {
		return 600;
	}

	/**
	 * Http configuration
	 */
	public getHttp(): any {
		return {
			retry: 3
		};
	}
}
