import { Injectable } from '@angular/core';

@Injectable({
	providedIn: 'root',
  })
export class ConsoleService {

	mklog = true;

	constructor() {
		this.log('ConsoleService', 'constructor');
	}

	/**
	 * Write to console of browser
	 *
	 * @param c class name
	 * @param m method name
	 * @param p variable name
	 * @param v values
	 */
	log(c: string, m: string, p?: string, ...v: any[]) {
		if (!p) {
			p = '?';
		}

		if (true === this.mklog) {
			//console.log(c + '.' + m + ' ' + p + ':', v);
		}
	}
}
