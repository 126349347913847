import { Component, Input, Output, EventEmitter } from '@angular/core';

/**
 * Generated class for the DocumentTitleComponent component.
 *
 * See https://angular.io/api/core/Component for more info on Angular
 * Components.
 */
@Component({
  selector: 'document-title',
  templateUrl: 'document-title.html'
})
export class DocumentTitleComponent {

  @Input('doc') document: any;
  @Output() onDownloading = new EventEmitter<boolean>();

  constructor() {}

  download() {
    this.onDownloading.emit(true);
  }

}
