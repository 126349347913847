import { Component } from '@angular/core';
import { NavController, NavParams, IonNav } from '@ionic/angular';

import { SiteService } from '../../services/site-service';
import { StorageUtils } from '../../utils/storage-utils';
import { HomePage } from '../../pages/home/home';
import { LoginPage } from '../../pages/login/login';
import { WebStorageService } from '../../services/webstorage-service';


@Component({
	selector: 'app-page-error',
	templateUrl: 'error.html',
	providers: [SiteService],
	styleUrls: ['error.scss']
})

export class ErrorPage {

	errorFromHome = false;
	http_status_500 = false;
	http_status_503 = false;

	constructor(
		public navController: NavController,
		public navParams: NavParams,
		public nav: IonNav,
		public siteService: SiteService,
		public localStorageService: WebStorageService,
	) {

		//console.log('this.navController.length()', this.nav.length());
		//console.log('this.navController.getActive().index', this.nav.getActive());
		/*
		if (this.navController.length()>1){
			this.navController.remove(this.navController.getActive().index);
		}
		*/
		if(typeof this.navParams.get('errorFromHome') !== 'undefined'){
			this.errorFromHome = this.navParams.get('errorFromHome')
		}

		if(typeof this.navParams.get('http_status') !== 'undefined'){

			if(this.navParams.get('http_status') == 500){
				this.http_status_500 = true;
			} else if (this.navParams.get('http_status') == 503) {
				this.http_status_503 = true;
			}
		}
	}

	tryAgain() {

		this.siteService.ping().subscribe(
			data => {
				StorageUtils.removeItem('http_status');
				this.localStorageService.remove('http_status');

				if (this.errorFromHome) {
					this.nav.setRoot(HomePage);
				} else {
					this.navController.pop();
				}


			}, err => {

			}
		)


	}

	changeServer() {

		this.nav.setRoot(LoginPage, {changeServer: true});

	}

	goToHome() {
		StorageUtils.removeItem('http_status');
		this.localStorageService.remove('http_status');
		this.nav.setRoot(HomePage);

	}
}
