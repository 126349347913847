import {Component} from '@angular/core';
import {NavController, NavParams} from '@ionic/angular';

import {UserService} from '../../services/user-service';
import {Toast} from '../../controllers/message-controller';

import {ArrayUtils} from '../../utils/array-utils';
import {StringUtils} from '../../utils/string-utils';

@Component({
	selector: 'app-page-vip',
	templateUrl: 'vip.html',
	providers: [UserService, Toast],
	styleUrls: ['vip.scss']
})
export class VipListPage {
	userSearch = '';
	usersall: any = [];
	users: any = [];
	idsuservip: any = [];

	backSave = false;

	hasUsers = false;

	finding = false;



	constructor(public navCtrl: NavController, public navParams: NavParams, public userService: UserService, public toast: Toast) {}

	ionViewWillEnter() {

		this.finding = true;

		this.userService.uservip({}).subscribe({
			next:(data: any) => {

				this.finding = false;

				let obj = {};
				let idsuservip = [];

				idsuservip = ArrayUtils.contert2simple(data.uservip, 'iduservip');

				this.users = [];
				for (const a of data.users) {
					obj = {checked: false};
					if (idsuservip.indexOf(a.utilizador) > -1) {
						obj = {checked: true};
					}
					obj = Object.assign(a, obj);

					this.users.push(obj);
				}


				if (this.users.length > 0){
					this.hasUsers = true;
					//console.log(this.hasUsers)
				} else {
					this.hasUsers = false;
				}

				this.usersall = this.users;
			},
			error:(err: any) => {
				this.finding = false;
			},
			complete: () => {
				this.finding = false;
			}
		});
	}

	ionViewWillLeave() {
		this.backSave = true;
		this.save();
	}

	sizeOf(item: any) {
		let size = 0;
		let key;
		for (key in item) {
			if (item.hasOwnProperty(key)) {
				 size++;
			}
		}
		return size;
	}

	giveMeIdCheckedOf(item){
		const itemChecked = [];
		for ( let i = 0; i<this.sizeOf(item); i++){
			if (typeof item[i].checked !== 'undefined') {
				if(item[i].checked) {
					 if ((typeof item[i].utilizador !== 'undefined')){
						itemChecked.push(item[i].utilizador);
					}

				}
			}
		}
		return itemChecked;
	}

	userChange(data): void {
		data.checked = !data.checked;
	}

	getItems(ev: any) {
		// Reset items back to all of the items
		this.users = this.usersall;

		// set val to the value of the searchbar
		const val = StringUtils.replaceSpecialChars(ev.target.value);

		// if the value is an empty string don't filter the items
		if (val && val.trim() != '') {
			this.users = this.users
			.filter((user) => StringUtils.replaceSpecialChars(user.nome).toLowerCase().indexOf(val.toLowerCase()) > -1);
		}
	}

	save(): void {
		this.idsuservip = [];

		for (const a of this.usersall) {
			if (a.checked) {
				this.idsuservip.push(a.utilizador);
			}
		}

		this.userService.saveuservip({idsuservip: this.idsuservip}).subscribe({
			next: (data: any) => {
			},
			error: (err: any) => {
			},
			complete: () => {
				if (false === this.backSave) {
					this.toast.show({cssClass:'success'});
				}
			}
		});
	}

}
