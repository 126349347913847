import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import { Observable, retry, map } from 'rxjs';
import { JwtHelperService } from '@auth0/angular-jwt';

import { ConfigService } from './config-service';


@Injectable({
	providedIn: 'root',
  })
export class EntityService {

	constructor(
    	public configService: ConfigService,
    	public jwtHttp: JwtHelperService,
		public http: HttpClient
	) {
	}

	/**
	 * Find entities
	 */
	public findentities(params): Observable<any> {
		return this.httpRequest('findentities', params)
			.pipe(map((response) => response));
			//.map((item) => item);
	}

	private httpRequest(method: string, params): Observable<any> {
		return this.http
			.post(this.configService.getVar('server_url')  + '/entity/' + method, params)
			.pipe(retry(this.configService.getHttp().retry));
	}
}
