import {Component} from '@angular/core';
import {NavController, NavParams, ModalController, IonItemSliding, AlertController} from '@ionic/angular';

import {UserCommentPage} from '../user-comment/user-comment';

import {ConfigService} from '../../services/config-service';
import {UserService} from '../../services/user-service';
import {TranslateService} from '@ngx-translate/core';

import {Loading} from '../../controllers/message-controller';

@Component({
  selector: 'app-page-user-commentlist',
  templateUrl: 'user-commentlist.html',
  providers: [ConfigService, UserService, Loading],
  styleUrls: ['user-commentlist.scss']
})
export class UserCommentlistPage {
	comments: any = [];

	finding = false;
	hasComments = false;

	constructor(	public navCtrl: NavController,
					public navParams: NavParams,
					public modalCtrl: ModalController,
					public userService: UserService,
					public loading: Loading,
					public alertController: AlertController,
					public translateService: TranslateService
				) {}

	ionViewWillEnter() {
		this.saveAndFind({});
	}

	ionViewWillLeave() {
	}

	async edit(comment: any) {
		const modal = await this.modalCtrl.create({component: UserCommentPage, componentProps: {comment}});

		modal.onDidDismiss()
		.then(data => {
			if ('undefined' !== typeof data) {
				this.saveAndFind(data.data);
			}
		});
		await modal.present();
	}

	saveAndFind(params: any): void {

		this.finding = true;

		this.userService.findcomments(params).subscribe({
			next:(data: any) => {
				//console.log(data);
				this.comments = data.comments;
				this.finding = false;
				if (this.comments.length > 0) {
					this.hasComments = true;
				} else {
					this.hasComments = false;
				}
			},
			error: (err: any) => {
				this.finding = false;
			},
			complete: () => {

			}
		});
	}

	removeAndFind(params) {

		this.finding = true;

		this.userService.removecomments(params).subscribe({
			nexst: (data: any) => {
				this.comments = data.comments;
				this.finding = false;
				if (this.comments.length > 0) {
					this.hasComments = true;
				} else {
					this.hasComments = false;
				}
			},
			error: (err: any) => {
			},
			complete: () => {
				this.finding = false;
			}
		});
	}

	remove(params: any, slidingItem: IonItemSliding) {
		slidingItem.close();

		const translations: any = {	 title: 'Do you want to delete this comment?',
									cancel: 'Cancel',
									delete: 'Delete'};

		for(const translation in translations) {
			this.translateService.get(translations[translation]).subscribe(
				value => {
					translations[translation] = value;
				}
			)
		}

		this.alertController.create({
			header: translations.title,
			buttons: [
				{
					text: translations.cancel,
					role: 'cancel',
					handler: () => {
						//console.log('Cancel clicked');
					}
				},
				{
					text: translations.delete,
					cssClass: 'alert-delete-button',
					handler: () => {
						this.removeAndFind({comment: params});
						//console.log('delete clicked');
					}
				}
			]
		}).then(evt => evt.present());




	}
}
