import { Component, OnDestroy, OnInit } from '@angular/core';

import { AlertController, IonNav, ModalController, NavController } from '@ionic/angular';
import { TranslateService } from '@ngx-translate/core';

import { AccountFormPage } from './../account-form/account-form';
import { Account } from './../../models/Account';
import { UserService } from './../../services/user-service';
import { AccountService } from './../../services/account-service';
import { ConsoleService } from '../../services/console/console-service';
import { SiteService } from '../../services/site-service';
import { Loading } from '../../controllers/message-controller';
import { Events } from '../../services/event-service';
import { WebStorageService } from '../../services/webstorage-service';
import { LoginPage } from '../login/login';
import { UserProfilePage } from '../user-profile/user-profile';
import { AboutPage } from '../about/about';
import { interval } from 'rxjs';

@Component({
  selector: 'app-page-menu-page',
  templateUrl: 'menu.html',
  styleUrls: ['menu.scss']
})
export class MenuPage implements OnInit, OnDestroy {

	accounts: Array<Account> = [];
	toggledAccount: string;
	switchedaccount = 0;
	confirmdelete = 0;

	constructor(
		public accountService: AccountService,
		public userService: UserService,
		public events: Events,
		public modalController: ModalController,
		public navCtrl: NavController,
		public alertCtrl: AlertController,
		private _siteService: SiteService,
		private _localStorageService: WebStorageService,
		private _consoleService: ConsoleService,
		private _translateService: TranslateService,
		private _loading: Loading,
		private nav: IonNav
	) {}

	openPage(page: any): void {
		this._consoleService.log('MenuPage', 'openPage', 'page: ' + page);
		if ('undefined' !== typeof page) {
			if ('undefined' !== typeof page.class) {
				this.events.publish('doc:pushPage', {
					page: page.class,
					params: {
						frompage: 'MenuPage'
					}
				});
			} else {
				this._consoleService.log('MenuPage', 'openPage', 'Error: openPage page.class not defined');
			}
		} else {
			this._consoleService.log('MenuPage', 'openPage', 'Error: openPage page not defined');
		}
	}

	logout(): void {
		this.userService.logout();
		this.nav.setRoot(LoginPage);
		this.nav.popToRoot();
	}

	goToProfile(){
		this.nav.push(UserProfilePage);
	}

	goToAbout(){
		this.nav.push(AboutPage);
	}

	goToContacts(){
		window.open('https://www.iportaldoc.com');
	}

	async addAccount() {
		const modal = await this.modalController.create({component: AccountFormPage});
		await modal.present();

		modal.onDidDismiss()
		.then(data => {
			this._consoleService.log('MenuPage', 'addAccount', 'onDidDismiss');


			// ngOnInit code
			this.accountService.checkAccounts();

			const currentAccount: Account = this.accountService.getCurrentAccount();
			this._consoleService.log('MenuPage', 'addAccount', 'currentAccount = ', currentAccount);
			currentAccount.setAccesstoken(this._localStorageService.get('access_token'));
			currentAccount.setRefreshtoken(this._localStorageService.get('refresh_token'));
			currentAccount.setAttr('user', localStorage.getItem('user'));
			this.accountService.saveAccount(currentAccount);

			const allAccounts = this.accountService.getAccounts();
			let flag = 0;
			for (const item of allAccounts) {
			    flag = 0;
			    if (item.link == currentAccount.link && item.server_url == currentAccount.server_url) {
				for (const acc of this.accounts) {
				    if (acc.id == item.id) {
				    flag = 1;
				    }
				}
				if (flag == 0) {
				    this.accounts.push(item);
				}
			    }
			}
			//this.accounts = this.accountService.getAccounts();
		});
	}

	activateAccount(event) {
		let account;
		for (const index of this.accounts) {
			if (index.getServerurl() + '|' + index.getUser().getId() === event.detail.value) {
				account = index;
			}
		}
		this.accountService.activateAccount(this.accountService.getCurrentAccount(), account);
		this.switchedaccount = 1;

		this._loading.show({});
		this.userService.refreshAvatar();
		this._siteService.refreshLogo().subscribe({
			next: (res) => {

			},
			error: (err) => {
				//this.splashscreen.show();
				window.location.reload();
			},
			complete: () => {
				//this.splashscreen.show();
				window.location.reload();
			}
		});
	}

	async confirmDelete(account: Account) {
		this._consoleService.log('MenuPage', 'confirmDelete', 'account', account);

		if (0 === this.confirmdelete && false === this.accountService.isActiveAccount(account)) {
			this.confirmdelete = 1;

			const translation = {title: '', ok: '', cancel: '', message: ''};
			this._translateService.get('Confirm').subscribe(
				value => {
					translation.title = value;
				}
			);
			this._translateService.get('Do you want to delete the account?').subscribe(
				value => {
					translation.message = value;
				}
			);
			this._translateService.get('Cancel').subscribe(
				value => {
					translation.cancel = value;
				}
			);
			this._translateService.get('ok').subscribe(
				value => {
					translation.ok = value;
				}
			);

			const confirm = await this.alertCtrl.create({
				header: translation.title,
				message: translation.message,
				buttons: [
				{
					text: translation.cancel,
					handler: () => {
						this.confirmdelete = 0;
					}
				},
				{
					text: translation.ok.toUpperCase(),
					handler: () => {
						this.confirmdelete = 0;
						this.accounts = this.accountService.deleteAccount(account);
					}
				}
				]
			});
			await confirm.present();
		}
	}

	ngOnDestroy() {
		if (1 === this.switchedaccount) {
			this.switchedaccount = 0;
			this.events.publish('doc:setRootPage', {
				page: 'HomePage',
			});
		}

		return new Promise((resolve: Function, reject: Function) => {
			resolve();
		});
	}

	ngOnInit(): void {
		this.accountService.checkAccounts();

		const currentAccount: Account = this.accountService.getCurrentAccount();
		this._consoleService.log('MenuPage', 'addAccount', 'currentAccount = ', currentAccount);
		currentAccount.setAccesstoken(this._localStorageService.get('access_token'));
		currentAccount.setRefreshtoken(this._localStorageService.get('refresh_token'));
		currentAccount.setAttr('user', localStorage.getItem('user'));
		this.accountService.saveAccount(currentAccount);

		const allAccounts = this.accountService.getAccounts();
		for (const item of allAccounts) {
		    if (item.link == currentAccount.link && item.server_url == currentAccount.server_url) {
				this.accounts.push(item);
		    }
		}
		//console.log('morning');
		for (const account of this.accounts) {
			if (account.checked === true) {
				this.toggledAccount = account.getServerurl() + '|' + account.getUser().getId();
			}
		}
		//this.accounts = this.accountService.getAccounts();
	}
}
