import {Component} from '@angular/core';
import {NavController, NavParams, ModalController, IonItemSliding, IonNav, AlertController} from '@ionic/angular';
import {UserAbsencePage} from '../user-absence/user-absence';

import {ConfigService} from '../../services/config-service';
import {UserService} from '../../services/user-service';
import {TranslateService} from '@ngx-translate/core';


@Component({
	selector: 'app-page-user-absencelist',
	templateUrl: 'user-absencelist.html',
	providers: [ConfigService, UserService],
	styleUrls: ['user-absencelist.scss']
})
export class UserAbsencelistPage {
	absences: any = [];
	actions: any = [];
	users: any = [];
	groups: any = [];
	workflows: any = [];
	showList = false;
	showGroups = false;
	userModalChoise: any;
	utilizador: number;
	profile: any = {manageabsences: false};

	finding = false;
	hasAbsences = false;

	constructor(
		public navCtrl: NavController,
		public navParams: NavParams,
		public modalCtrl: ModalController,
		public nav: IonNav,
		public userService: UserService,
		public alertController: AlertController,
		public translateService: TranslateService
				) {}

	ionViewWillEnter() {
		const user = this.userService.getUser();
		//console.log('user', user);
		if(typeof user.nome !== 'undefined'){
			this.userModalChoise = user.nome;
			this.utilizador = user.id;
		} else {
			this.userModalChoise = user.name;
			this.utilizador = user.id;
		}

		this.saveAndFind({});
	}

	ionViewWillLeave() {
	}

	async edit(absence: any) {
		const modal = await this.modalCtrl.create({
			component: UserAbsencePage,
			componentProps:{
				absence,
				actions: this.actions,
				users: this.users,
				groups: this.groups,
				workflows: this.workflows,
				profile: this.profile
			}
		});

		modal.onDidDismiss()
		.then(data => {
			if ('undefined' !== typeof data) {
				this.saveAndFind(data.data);
			}
		});
		await modal.present();
	}

	saveAndFind(params: any): void {

		this.finding = true;
		this.userService.findabsences(params).subscribe({
			next: (data: any) => {
				this.absences = data.absences;

				this.finding = false;
				if (this.absences.length > 0) {
					this.hasAbsences = true;
				} else {
					this.hasAbsences = false;
				}

				this.actions = data.actions;
				this.users = data.users;
				this.groups = data.groups;
				this.workflows = data.workflows;

				if (data.profile) {
					this.profile = data.profile;
				}

				//console.log('profile', this.profile);
				for (let i = 0; i < this.users.length; i++) {
							if (this.users[i].checked) {

							} else {
								this.users[i] = Object.assign(this.users[i], {checked: false });
							}
				}
				for (let i = 0; i < this.groups.length; i++) {
							if (this.groups[i].checked) {

							} else {
								this.groups[i] = Object.assign(this.groups[i], {checked: false});
							}
				}
				for (let w = 0; w < this.workflows.length; w++) {

					this.workflows[w] = Object.assign(this.workflows[w], {showList: false});

					for (let a = 0; a < this.workflows[w].actions.length; a++) {

						const users = JSON.parse(JSON.stringify(this.users));
						const groups = JSON.parse(JSON.stringify(this.groups));

						this.workflows[w].actions[a] = Object.assign(
							this.workflows[w].actions[a],
							{
								showList: false,
								showGroups: false,
								hasNewPerformer: false,
								ewPerformer: false,
								users,
								groups
							});
					}
				}
			},
			error: (err: any) => {
				this.finding = false;
				this.hasAbsences = false;
			}
		});
	}



	removeAndFind(params: any) {

		this.finding = true;
		this.userService.removeabsences(params).subscribe({
			next: (data: any) => {
				this.absences = data.absences;
				this.finding = false;
				if (this.absences.length > 0) {
					this.hasAbsences = true;
				} else {
					this.hasAbsences = false;
				}
			},
			error: (err: any) => {
				this.finding = false;
			},
			complete: () => {

			}
		});
	}

	remove(params: any, slidingItem: IonItemSliding) {
		slidingItem.close();

		const translations: any = {	 title: 'Do you want to delete this absence?',
									cancel: 'Cancel',
									delete: 'Delete'};

		for(var translation in translations) {
			this.translateService.get(translations[translation]).subscribe(
				value => {
					translations[translation] = value;
				}
			)
		}

		this.alertController.create({
			header: translations.title,
			buttons: [
				{
					text: translations.cancel,
					role: 'cancel',
					handler: () => {
						//console.log('Cancel clicked');
					}
				},
				{
					text: translations.delete,
					cssClass: 'alert-delete-button',
					handler: () => {
						this.removeAndFind({absence: params});
						//console.log('delete clicked');
					}
				}
			]
		}).then(evt => evt.present());
		//absences
	}

	async presentUsersModal(){

			let modal = await this.modalCtrl.create({
				component: SelectUsersWithModal,
				componentProps:{items: this.users}
			});

			modal.onDidDismiss()
			.then(data => {

			if(typeof data !== 'undefined'){

				//console.log(data);

				if(typeof data.data.nome !== 'undefined' || typeof data.data.name !== 'undefined'){ //user

					if(typeof data.data.nome !== 'undefined'){
						this.userModalChoise = data.data.nome;
						this.utilizador = data.data.utilizador;
					} else {
						this.userModalChoise = data.data.name;
						this.utilizador = data.data.utilizador;
					}

					//console.log('absences', this.absences);
					//console.log('userModalChoise', this.userModalChoise);
					//console.log('utilizador', this.utilizador);

					this.saveAndFind({iduser: data.data.utilizador});

				}
			}

			});
			await modal.present();


	}
}

@Component({
	 template: `
    <ion-header>
		<ion-toolbar>
			<ion-title>{{'Users' | translate}}</ion-title>
			<ion-buttons slot='secondary'>
				<ion-button (click)='dismiss()'>
				<ion-label><span style='color:white'>{{'Cancel' | translate}}</span></ion-label>
				</ion-button>
			</ion-buttons>
		</ion-toolbar>
		<ion-searchbar (ionInput)='getItems($event)' placeholder='{{"Search" | translate}}' name='searchModel'></ion-searchbar>
	</ion-header>
	<ion-content>
		<ion-list>
			<ion-item *ngFor='let item of items'>
				<ion-label *ngIf='item.descricao' (click)='dismiss(false, item)' class='clickable'>{{item.descricao}}</ion-label>
				<ion-label *ngIf='item.nome' (click)='dismiss(false, item)' class='clickable'>{{item.nome}}</ion-label>
			</ion-item>
		</ion-list>
	</ion-content>
  `
})
export class SelectUsersWithModal {

	items: any;
	allitems: any;

	constructor(params: NavParams, public viewController: ModalController) {
		if(params.get('items')){
			this.items = params.get('items');
			this.allitems = this.items;
		}
		//console.log('items', this.items)
	}

	dismiss(allitems?: boolean, item?: any){

		if (!allitems && typeof item !== 'undefined'){
			this.viewController.dismiss(item);

		} else if (allitems && typeof item !== 'undefined'){
			//console.log(item[0]);
			if(item[0].idtipodoc){
				this.viewController.dismiss({descricao:'All'});
			} else if(item[0].utilizador){
				this.viewController.dismiss({nome:'All'});
			}

		}  else {
			this.viewController.dismiss();
		}
	}

	getItems(ev) {

		this.items = this.allitems;
		// set val to the value of the ev target
		const val = ev.target.value;

		// if the value is an empty string don't filter the items
		if (val && val != '' && val.trim() != '') {
			this.items = this.items.filter((item) => {
				if(typeof item.descricao !== 'undefined'){
					return (item.descricao.toLowerCase().indexOf(val.toLowerCase()) > -1);
				} else if(typeof item.nome !== 'undefined'){
					return (item.nome.toLowerCase().indexOf(val.toLowerCase()) > -1);
				}

			});
		}
	}
}
