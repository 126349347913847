import { Component, OnInit } from '@angular/core';
import { NavController, NavParams, ModalController, IonNav } from '@ionic/angular';

import { UserService } from '../../services/user-service';
import { DocumentService } from '../../services/document-service';
import { FileService } from '../../services/file/file-service';
import { StorageUtils } from '../../utils/storage-utils';
import { Toast } from '../../controllers/message-controller';
import { ActionSheet } from '../../controllers/actionsheet-controller';
import { Events } from '../../services/event-service';
import { timer } from 'rxjs';

@Component({
	templateUrl: 'mark.html',
	selector: 'app-page-mark-page',
	providers: [UserService, DocumentService],
	styleUrls: ['mark.scss']
})

export class MarkPage implements OnInit/*, OnDestroy*/ {

	idtag: any = 0;
	note = '';
	alertdate: any;
	alertdatesave = false;
	alertstatechange = false;
	mindate: any;
	maxdate: any;
	oneDayDate: any;
	fiveDaysDate: any;
	oneWeekDate: any;
	selectedValue: string;

	finding = false;

	isTagged = false;

	tags: any = [];

	document: any;

	constructor(
		public navCtrl: NavController,
		public navParams: NavParams,
		public nav: IonNav,
		public viewCtrl: ModalController,
		public events: Events,
		public userService: UserService,
		public documentService: DocumentService,
		public fileService: FileService,
		public toast: Toast,
		public actionSheet: ActionSheet
	) {

		if (this.navParams.get('params')) {

			const params = this.navParams.get('params');

			if ('object' === typeof params.document && 'number' === typeof params.document.iddoc) {

				this.document = params.document;
			}
		}

		this.alertdate = this.rememberInNDay(1);
		this.mindate = new Date(new Date().setFullYear(new Date().getFullYear() - 10)).toISOString().slice(0, 10);
		this.maxdate = new Date(new Date().setFullYear(new Date().getFullYear() + 10)).toISOString().slice(0, 10);
	}

	ngOnInit(): void {
		this.finding = true;
		this.documentService.documenttag({iddoc: this.document.iddoc}).subscribe({
			next: (data: any) => {

				this.finding = false;

				this.tags = [];
				for (const tag of data.tags) {

					tag.selected = false;
					if (tag.idtag == data.idtag) {
						tag.selected = true;
					}
					this.tags.push(tag);
				}

				this.idtag = data.idtag;
				//console.log('data.idtag',data.idtag);
				if (this.idtag){
					this.isTagged = true;
				} else {
					this.isTagged = false;
				}
				this.note = data.description;

				if (data.alertdate) {
					this.alertdate = data.alertdate;
					this.showDate();
				}


				this.alertstatechange = false;
				if (1 == data.alertstatechange) {
					this.alertstatechange = true;
				}
			},
			error: () => {
				this.finding = false;
			},
			complete: () => {
				this.finding = false;
			}
		});
	}

	/* Quem é que teve a brilhante ideia de mandar um erro artificial no final de tudo acontecer?!
	ngOnDestroy(): void {
		throw new Error('Method not implemented.');
	}
	*/

	showDate(){
		this.alertdatesave = true;
	}

	hideDate(){
		this.alertdatesave = false;
		this.alertdate = this.rememberInNDay(1);
	}

	changeDate(){
		this.alertdatesave = true;
		this.selectedValue = 'Nothing';
	}

	changeidtag(value){
		this.idtag = value;
	}

	radialCheck(value: string) {
		//console.log(value);
		switch (value) {
			case 'oneDay':
				this.alertdatesave = true;
				this.alertdate = this.rememberInNDay(1);
				break;

			case 'fiveDays':
				this.alertdatesave = true;
				this.alertdate = this.rememberInNDay(5);
				break;

			case 'oneWeek':
				this.alertdatesave = true;
				this.alertdate = this.rememberInNDay(7);
				break;

			case 'Nothing':
				break;

			default:
				this.hideDate();
				break;
		}
	}

	rememberInNDay(n): string {
		const date = new Date();
		date.setDate(date.getDate() + n);

		return date.toISOString().slice(0, 10);
	}


	clear(): void {
		this.hideDate();
		this.idtag            = null;
		this.note             = null;
		this.alertdate        = null;
		this.alertstatechange = false;
	}


	ionViewWillLeave() {

		if (this.isTagged) {
			//console.log('isTagged', this.isTagged);
			this.save();

		} else {
			this.remove();
		}


	}

	save(): void {
		//console.log('Entrou no Save',this.idtag);
		if (this.idtag > 0) {
			let alertstatechange = 0;
			if (true == this.alertstatechange) {
				alertstatechange = 1;
			}

			if (!this.alertdatesave){
				this.alertdate = null;
			}

			const params = {
				iddoc: this.document.iddoc,
				idtag: this.idtag,
				description: this.note,
				alertdate: this.alertdate,
				alertstatechange
			};

			//console.log('Antes de subscrever ao savemark');
			this.documentService.savemark(params).subscribe({
				next: () => {
					//console.log('dentro do next');
					this.toast.show({
						message: 'The document was tagged successfully',
						duration: 3000,
						cssClass: 'success',
						dismissOnPageChange: false
					});
				},
				error: () => {
					this.toast.show({
						message: 'An error ocurred while tagging document',
						duration: 3000,
						cssClass: 'error',
						dismissOnPageChange: false
					});
				}
			});
			//console.log('EEEEEEEEEEEEEEEEEEEEEEEEEEEEEEEEEEEEEEEEEEEEEEEEEEEEEEEEEEEEEEEE');
		}
	}

	remove(): void {
		this.documentService.removedocumenttag({iddoc: this.document.iddoc, idtag: this.idtag}).subscribe({
			next: () => {
				this.clear();
			},
			error: () => {
				this.toast.show({
					message: 'An error ocurred while untagging document',
					duration: 3000,
					cssClass: 'error',
					dismissOnPageChange: false
				});
			}
		});
	}

	goToInfo(): void{
		this.nav.getActive().then((view) => {
			this.events.publish('doc:pushPage', {
				page: 'InfoPage',
				params: {
					document: this.document,
					frompage: 'MarkPage'
				}
			});
			timer(50).forEach(() => {
				this.nav.removeIndex(view.nav.views.length - 2);
			});
		});
	}

	goToChronologicalScheme() {
		this.nav.getActive().then((view) => {
			this.events.publish('doc:pushPage', {
				page: 'ChronologicalschemePage',
				params: {
					document: this.document,
					frompage: 'MarkPage'
				}
			});
			timer(50).forEach(() => {
				this.nav.removeIndex(view.nav.views.length - 2);
			});
		});
	}

	openActionSheetFor(){
		this.actionSheet.show('MarkPage', 'more', {item: this.document});
	}

	download(): any {

		const user = this.userService.getUser();
		if ('0' == StorageUtils.getItem('platform_cordova')) {
			this.documentService.downloadbytoken({iddoc: this.document.iddoc, chronschemasort: user.defaultchronschemasort}, '');
		} else {
			this.fileService.download(this.document);

		}
	}
}
