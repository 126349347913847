import { Component, ElementRef, OnDestroy, ViewChild } from '@angular/core';

import { NavController, NavParams, AlertController, ModalController, IonNav } from '@ionic/angular';

import { TranslateService } from '@ngx-translate/core';
import { DragulaService } from 'ng2-dragula';
import { map, Subscription, timer } from 'rxjs';

import * as autoScroll from 'dom-autoscroller';

import { StorageUtils } from '../../utils/storage-utils';
import { ActionService } from '../../services/action-service';
import { MydocsService } from '../../services/mydocs-service';
import { UserService } from '../../services/user-service';
import { ConfigService } from '../../services/config-service';
import { DocumentService } from '../../services/document-service';
import { Loading, Toast } from '../../controllers/message-controller';
import { ActionSheet } from '../../controllers/actionsheet-controller';
import { ActionConfigfilterPage } from '../action-configfilter/action-configfilter';
import { Events } from '../../services/event-service';
import { WebStorageService } from '../../services/webstorage-service';
import { AccountService } from 'src/app/services/account-service';

@Component({
	selector: 'app-page-home',
	templateUrl: 'home.html',
	providers: [
				ActionService,
				DocumentService,
				MydocsService,
				NavParams,
				DragulaService
			   ],
	styleUrls: ['home.scss']

})
export class HomePage implements OnDestroy {
	filtersLeft: Array<{idactionlist: any; name: string; total: number; className: string}> = [];
	filtersRight: Array<{idactionlist: any; name: string; total: number; className: string}> = [];
	filters: any = new Array();
	mydocs: any = new Array();
	filtersgrid: any = new Array();
	filterall: any = {total: 0};
	filtermydocs: any = {total: 0, name: 'MyDocs'};
	filtergroupall: any = {total: 0, idactionlist: -2, name: 'Group actions' };
	filterpersonalall: any = {total: 0, idactionlist: -1, name: 'Personal actions'};
	fsavefiltersorder = false;
	groupActionsFilter: any;
	personalActionsFilter: any;

	dragEnable = false;
	refresher: any;

	showOptions = false;
	shortcuts: any;
	help: any;

	disableMyDocs = false;

	wrapperOverflowEnable = true;
	@ViewChild('autoscroll') autoscroll: ElementRef;
	//@ViewChild('autoscroll2') autoscroll2: Element;

	bg_home_url: any;
	buttonup = '/assets/images/buttonup.png';
	buttondown = '/assets/images/buttondown.png';

	timer: any;
	istrue = false;
	delay = 2000; // how much long u have to hold click in MS
	subs = new Subscription();

	loading1 = false;
	loading2 = false;

	constructor(public navCtrl: NavController,
				public params: NavParams,
				public alertController: AlertController,
				public modalCtrl: ModalController,
				public events: Events,
				public localStorageService: WebStorageService,
				public dragulaService: DragulaService,
				public actionService: ActionService,
				public mydocsService: MydocsService,
				public translateService: TranslateService,
				public userService: UserService,
				public documentService: DocumentService,
				public configService: ConfigService,
				public loading: Loading,
				public toast: Toast,
				public actionSheet: ActionSheet,
				public nav: IonNav,
				public acc: AccountService
			) {


		this.buttonup = this.configService.getServerurl() + '/assets/images/buttonup.png';
		this.buttondown = this.configService.getServerurl() + '/assets/images/buttondown.png';
		document.addEventListener('contextmenu', event => event.preventDefault());

		StorageUtils.setItem('tab_selected', 'actions');
		this.localStorageService.set('tab_selected', 'actions');

		if (!this.dragulaService.find('filters-bag')) {
			//console.log('set filters-bag');

			dragulaService.createGroup('filters-bag', {
				moves:(el, container, handle) => handle.classList.contains('handle')
			});
		} else {
			this.dragulaService.destroy('filters-bag');
			//console.log('destroy and set bag');
			dragulaService.createGroup('filters-bag', {
				moves: (el, container, handle) => handle.classList.contains('handle')
			});
		}


		this.subs.add(this.dragulaService.drag()
			.subscribe((value) => {
			//console.log('drag');
			this.wrapperOverflowEnable = false;
		}));

		this.subs.add(this.dragulaService.dragend()
			.subscribe((value) => {
			//console.log('dragend');

		}));

		this.subs.add(this.dragulaService.over()
			.subscribe((value) => {
			//console.log('over');
			 this.wrapperOverflowEnable = false;
		}));

		this.subs.add(this.dragulaService.drop()
			.subscribe((value) => {
			//console.log('drop');
			this.wrapperOverflowEnable = true;
			this.fsavefiltersorder = true;
		}));

		this.subs.add(this.dragulaService.out()
			.subscribe((value) => {
			//console.log('out');
			 this.wrapperOverflowEnable = true;
		}));

		this.scroll();

	}

	onClickedOutside(e: Event) {
		if (this.dragEnable){
			this.dragEnable = false;
		}
	}

	doNothing(){

	}

	startTimer(){
		this.istrue = true;
		const root = this;
		this.timer = setTimeout(() => {root.makeChange();}, root.delay);
	}

	makeChange(){
		  if(this.timer){
			  clearTimeout(this.timer);
		  }

		  if(this.istrue){
			  this.dragEnable = true;

		  }
	}

	revertTimer(){
		this.istrue = false;
	}

	scroll(){
		setTimeout(() => {
			autoScroll([
			  this.autoscroll.nativeElement//,
			  //this.autoscroll2.nativeElement
			],{
			margin: 20,
			maxSpeed: 5,
			scrollWhenOutside: true,
			autoScroll:() => this.buttondown
			  //Only scroll when the pointer is down.
			  //return true;
			 });
		}, 2000);
	}

	async ionViewWillEnter(){
		this.loading1 = false;
		this.loading2 = false;
		await this.loading.show({});
		//this.bg_home_url = this.configService.getServerurl() + '/images/bg_home.png';
		this.userService.refreshAvatar();
		this.userService.refreshLogo();
		this.find();
		if(this.loading.isOn === true) {
			timer(5000).pipe(map(() => {
				if (this.loading1 === true && this.loading2 === true) {
					this.loading.dismiss();
				}
			}));
		}
	}

	ionViewWillLeave(){


		this.showOptions = false;

		if ('undefined' != typeof this.refresher) {
			this.refresher.complete();
		}

		if (this.loading.isOn){
			this.loading.dismiss();
		}


		if (this.fsavefiltersorder){
			this.saveFiltersOrder();
		}
	}

	find(): any {
		let home: any = {
			filtersLeft:       this.filtersLeft,
			filtersRight:      this.filtersRight,
			filterall:         this.filterall,
			filtergroupall:    this.filtergroupall,
			filtermydocs:      this.filtermydocs,
			mydocs:		   this.mydocs,
			filterpersonalall: this.filterpersonalall
		};
		if (this.configService.getVar('home')) {
			home = this.configService.getVar('home');
			this.filtersLeft       = home.filtersLeft;
			this.filtersRight      = home.filtersRight;
			this.filterall         = home.filterall;
			this.filtergroupall    = home.filtergroupall;
			this.filtermydocs      = home.filtermydocs;
			this.mydocs    	       = home.mydocs;
			this.filterpersonalall = home.filterpersonalall;
		}

		this.mydocsService.checkmydocs({}).subscribe({
			next: (data: any) => {
				if (data == true) {
					this.mydocsService.findmydocs({}).subscribe({
						next: (data: any) => {
							this.disableMyDocs = false;
							this.mydocs = data.mydocs;
			
							home.mydocs	       = this.mydocs;
							this.filtermydocs.total = data.total;
			
							home.filtermydocs      = this.filtermydocs;
			
							this.configService.setVar('home', home);
							this.configService.setVar('mydocs', this.mydocs);
			
							this.userService.refreshLogo();
						},
						error: err => {
							if (err.status === 418) {
								//console.log('418 - Brewing Coffee ☕');
								this.disableMyDocs = true;
								this.filtermydocs.total = 0;
								this.userService.refreshLogo();
								this.loading1 = true;
								if (this.loading2 === true) {
									this.loading.forcedismiss();
								}
							}
						},
						complete: () => {
							this.loading1 = true;
							if (this.loading2 === true) {
								this.loading.forcedismiss();
							}
						}
					});
				} else {
					this.disableMyDocs = true;
					this.filtermydocs.total = 0;
					this.userService.refreshLogo();
					this.loading1 = true;
					if (this.loading2 === true) {
						this.loading.forcedismiss();
					}
				}
				
			},
			error: (err: any) => {
				this.disableMyDocs = true;
				this.filtermydocs.total = 0;
				this.userService.refreshLogo();
				this.loading1 = true;
				if (this.loading2 === true) {
					this.loading.forcedismiss();
				}
			}

		});
		

		this.actionService.findactionfilters({}).subscribe({
			next: (data: any) => {
				if (data.config) {
					if (data.config.user) {
						if (data.config.user.lang) {
							let usr = this.userService.getUser();
							let lang: string = this.userService.validateLang(data.config.user.lang);
							if (lang !== usr.lang) {
								this.translateService.setDefaultLang(lang);

								this.userService.i18n({lang: lang}).subscribe({
									next: (data: any) => {
										this.translateService.setTranslation(lang, data, true);
									},
									error: err => {
									},
									complete: () => {
									}
								});

								usr = Object.assign(usr, {lang: lang});
								this.userService.setUser(JSON.stringify(usr));
							}
						}
					}
					if (data.config.app) {
						if(data.config.app['fetch-bg_home']){
							//console.log('fetch bg');
							this.bg_home_url = this.configService.getServerurl() + '/images/bg_home.png?' + (new Date()).getTime();
						}
					}
				}

				if (!data.hasaccessedbefore) {
					this.fsavefiltersorder = true;
				}

				//this.filters = data.json().filters;
				this.filterall.total = data.filterall.cnt;
				this.filtersLeft = data.filtersleft;
				this.filtersRight = data.filtersright;
				this.filtergroupall.total = data.filtergroupall.cnt;
				this.mydocs = data.mydocs;
				this.filterpersonalall.total = data.filterpersonalall.cnt;
				this.shortcuts = data.shortcuts;
				this.help = data.help;

				//save on global service to avoid show the loading
				home.filtersLeft       	= this.filtersLeft;
				home.filtersRight      	= this.filtersRight;
				home.filterall         	= this.filterall;
				home.filtergroupall    	= this.filtergroupall;
				home.mydocs				= this.mydocs;
				home.filterpersonalall 	= this.filterpersonalall;
				this.configService.setVar('home', home);

				this.userService.refreshLogo();
			},
			error: err => {

			},
			complete: () => {
				this.loading2 = true;
				if (this.loading1 === true) {
					this.loading.forcedismiss();
				}
			}
		});

	}

	saveFiltersOrder(): void {

		//console.log('saveFiltersOrder');

		let filtersLeftIds = [];
		let filtersRightIds = [];

		for (var i=0; i<this.filtersLeft.length; i++){
			if ('' != this.filtersLeft[i].idactionlist) {
				filtersLeftIds.push(this.filtersLeft[i].idactionlist)
			}

		}

		for (i=0; i<this.filtersRight.length; i++){
			if ('' != this.filtersRight[i].idactionlist) {
				filtersRightIds.push(this.filtersRight[i].idactionlist);
			}
		}

		this.actionService.saveactionfilterorder({filtersleft: filtersLeftIds, filtersright: filtersRightIds}).subscribe({
			next: data => {
				this.fsavefiltersorder = false;
			},
			error: err => {
				this.toast.show({message: 'An error occured while saving action filters order', duration: 3000, cssClass: 'error'});

			},
			complete: () => {

			}
		});
	}

	toogleOptions(){
		if(!this.showOptions){
			if (this.dragulaService.find('filters-bag')) {
				//console.log('destroy');
				this.dragulaService.destroy('filters-bag');
			}

			this.showOptions = true;

		} else {

			this.showOptions = false;

			if (!this.dragulaService.find('filters-bag')) {
				//console.log('set filters-bag');

				this.dragulaService.createGroup('filters-bag', {
					moves:(el, container, handle) => handle.classList.contains('handle')
				});
			}

			this.subs.add(this.dragulaService.drag()
				.subscribe((value) => {
				//console.log('drag');
				this.wrapperOverflowEnable = false;
			}));

			this.subs.add(this.dragulaService.dragend()
				.subscribe((value) => {
				//console.log('dragend');
				//this.dragEnable = false;

			}));

			this.subs.add(this.dragulaService.over()
				.subscribe((value) => {
				//console.log('over');
				 this.wrapperOverflowEnable = false;
			}));

			this.subs.add(this.dragulaService.drop()
				.subscribe((value) => {
				//console.log('drop');
				this.wrapperOverflowEnable = true;
				this.fsavefiltersorder = true;
			}));

			this.subs.add(this.dragulaService.out()
				.subscribe((value) => {
				//console.log('out');
				 this.wrapperOverflowEnable = true;
			}));


			this.scroll();
		}
	}

	edit(filter?: any) {
		//console.log('edit');
		if (this.fsavefiltersorder) {
			this.saveFiltersOrder();
		}

		const actionConfigfilterModal = this.modalCtrl.create({
			component: ActionConfigfilterPage,
			componentProps: {filter}

		}).then((evt)=>{

			this.fsavefiltersorder = true;
			this.toogleOptions();
			this.find();
		
			evt.present();
			
		});


	}

	remove(filter: any) {
		let translation = {title: '', cancel: '', ok: 'ok', delete:''};
		this.translateService.get('Do you want to delete this filter?').subscribe(
			value => {
				translation.title = value;
			}
		);
		this.translateService.get('Cancel').subscribe(
			value => {
				translation.cancel = value;
			}
		);
		this.translateService.get('Delete').subscribe(
			value => {
				translation.delete = value;
			}
		);


		this.alertController.create({
			header: translation.title,
			buttons: [
			  {
					text: translation.cancel,
					handler: () => {
					}
			  },
			  {
				text: translation.delete,
				cssClass: 'alert-delete-button',
				handler: () => {

					let filtersLeftIds = [];
					let filtersRightIds = [];

					for (let i = 0; i < this.filters.length; i++) {
						if (filter.idactionlist == this.filters[i].idactionlist){
							this.filters.splice(i, 1);
							break;
						}
					}
					for (let i = 0; i < this.filtersRight.length; i++) {
						if (filter.idactionlist == this.filtersRight[i].idactionlist){
							this.filtersRight.splice(i, 1);
						} else {
							filtersRightIds.push(this.filtersRight[i].idactionlist);
						}
					}
					for (let i = 0; i < this.filtersLeft.length; i++) {
						if (filter.idactionlist == this.filtersLeft[i].idactionlist){
							this.filtersLeft.splice(i, 1);
						} else {
							filtersLeftIds.push(this.filtersLeft[i].idactionlist);
						}
					}
					this.actionService.removeactionfilter({
						filter: filter,
						filtersLeftIds: filtersLeftIds,
						filtersRightIds: filtersRightIds
					}).subscribe(
						() => {
							this.fsavefiltersorder = true;
					});
				}
			  }
			]
		  }).then(evt => evt.present());
		  
	}

	selectShortcut(){

		//console.log('shortcuts', this.shortcuts);

		if (this.shortcuts) {
			let translation = {title: '', cancel: '', ok: 'ok'};
			this.translateService.get('Shortcuts').subscribe(
				value => {
					translation.title = value;
				}
			)
			this.translateService.get('Cancel').subscribe(
				value => {
					translation.cancel = value;
				}
			)
			let selectShortcutOptions = {
				title: translation.title,
				inputs: [],
				buttons: [
					{
						text: translation.cancel
					},
					{
						text: translation.ok.toUpperCase(),
						handler: (data: any) => {
							//console.log('Radio data:', data);
							let shortcutName;
							for (let i = 0; i < this.shortcuts.length; i++) {
								if (data == this.shortcuts[i].idshort) {
									shortcutName = this.shortcuts[i].name
								}
							}
							let shortcut = {idshort: data, name: shortcutName};

							this.goToListDocuments({
								shortcut,
								shortcuts: this.shortcuts
							});
						}
					}

				]
			};

			for (let i = 0; i < this.shortcuts.length; i++) {
				selectShortcutOptions.inputs.push({label: this.shortcuts[i].name, value: this.shortcuts[i].idshort, type: 'radio'});
			}
			//console.log('options', selectShortcutOptions);

			this.alertController.create(selectShortcutOptions).then((evt) => evt.present());
		}
	}

	goToMoreInfo(){
		this.events.publish('doc:pushPage', {
			page: 'ListDocumentsPage',
			params: {
				help: this.help,
				document: undefined,
				frompage: 'HomePage'
			}
		});
	}

	goToListActions(filter?: any): void {
		if (!this.dragEnable) { // to make sure no navCtrl.setRoot occurs when dragEnable is on
			if ('undefined' != typeof filter) {
				this.events.publish('doc:pushPage', {
					page: 'ListActionsPage',
					params: {
						filter,
						actions: undefined,
						frompage: 'HomePage'
					}
				});
			} else {
				this.events.publish('doc:pushPage', {
					page: 'ListActionsPage',
					params: {
						actions: undefined,
						frompage: 'HomePage'
					}
				});
			}
		}
	}

	goToListMyDocs(filter?: any): void {
		if (!this.dragEnable) { // to make sure no navCtrl.setRoot occurs when dragEnable is on
			if ('undefined' != typeof filter) {
				this.events.publish('doc:pushPage', {
					page: 'ListMyDocsPage',
					params: {
						filter,
						actions: undefined,
						frompage: 'HomePage'
					}
				});
			} else {
				this.events.publish('doc:pushPage', {
					page: 'ListMyDocsPage',
					params: {
						actions: undefined,
						frompage: 'HomePage'
					}
				});
			}
		}
	}

	doRefresh(refresher): void {
		this.refresher = refresher;
		this.find();
	}

	goToListDocuments(params?: any){
		if ('undefined' != typeof params) {
			if (typeof params.shortcut !== 'undefined' && typeof params.shortcuts !== 'undefined') {
				this.events.publish('doc:pushPage', {
					page: 'ListDocumentsPage',
					params: {
						shortcut: params.shortcut,
						shortcuts: params.shortcuts,
						frompage: 'HomePage'
					}
				});
			}
		} else {
			this.events.publish('doc:pushPage', {
				page: 'ListDocumentsPage',
				params: {
					documents: undefined,
					frompage: 'HomePage'
				}
			});
		}

	}

	goToListEmails(){
		this.events.publish('doc:pushPage', {
			page: 'ListEmailsPage',
			params: {
				emails: undefined,
				frompage: 'HomePage'
			}
		});
	}

	goToMenu(){
		this.events.publish('doc:pushPage', {
			page: 'MenuPage',
			params: {
				frompage: 'HomePage'
			}
		});
	}

	ngOnDestroy(){
		this.subs.unsubscribe();
	}

}
