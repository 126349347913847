import { Injectable } from '@angular/core';

import { Observable, catchError, retry, timer } from 'rxjs';
import { JwtHelperService } from '@auth0/angular-jwt';


import { ConfigService } from './config-service';
import { Events } from './event-service';
import { HttpClient } from '@angular/common/http';
import { IonNav } from '@ionic/angular';
import { ListMyDocsPage } from '../pages/list-mydocs/list-mydocs';

@Injectable({
	providedIn: 'root',
  })
export class MydocsService {

	constructor(
		public configService: ConfigService,
		public jwtHttp: JwtHelperService,
		public events: Events,
		private http: HttpClient,
		private nav: IonNav
	) {
  	}

	/**
	 * Find mydocs
	 */
	public find(params) {
		return this.httpRequest('find', params);
	}

	public actionForm(params) {
		return this.httpRequest('form', params);
	}

	public findmydocs(params) {
		return this.httpRequest('findmydocs', params);
	}

	public removemydocs(params: any): any {
		return this.httpRequest('removemydocs', params);
	}

	public fileuploaded(params) {
		return this.httpRequest('fileuploaded', params);
	}

	public checkmydocs(params) {
		return this.httpRequest('checkmydocs', params);
	}

	public goToListMyDocs() {
		this.nav.getActive().then((view) => {
			this.events.publish('doc:pushPage', {
				page: 'ListMyDocsPage',
				params: {
					getmydocs: true,
					actions: undefined,
					frompage: 'HomePage'
				}
			});
			timer(50).forEach(() => {
				this.nav.removeIndex(view.nav.views.length - 2);
			});
		});
	}
	private httpRequest(method, params): Observable<any> {
		return this.http
			.post(this.configService.getVar('server_url')  + '/mydocs/' + method, params)
			.pipe(retry({
				count: this.configService.getHttp().retry,
				delay:this.shouldRetry
			}));
	}
	shouldRetry(error: any) {
		// Example for catching specific error code as well
		console.log(error);
		if (error.status === 422 || error.status === 418) {
				throw error;
		}
		return timer(10);
		
	}
}
