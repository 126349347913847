import {Component} from '@angular/core';
import {NavController, NavParams, AlertController, IonItemSliding} from '@ionic/angular';

import {DocumentService} from '../../services/document-service';
import {UserService} from '../../services/user-service';
import { FileService } from '../../services/file/file-service';
import {StorageUtils} from '../../utils/storage-utils';
import { Events } from '../../services/event-service';
import { WebStorageService } from '../../services/webstorage-service';


@Component({
	selector: 'app-page-list-emails-assoc',
	templateUrl: 'list-emails-assoc.html',
	providers: [DocumentService],
	styleUrls: ['list-emails-assoc.scss']
})
export class ListEmailsAssocPage {
	document: any;
	emails: Array<any> = [];
	assocEmailAccessedIndex: number;
	finding = false;

	constructor(public navCtrl: NavController,
		public navParams: NavParams,
		public documentService: DocumentService,
		public userService: UserService,
		public alertCtrl: AlertController,
		public events: Events,
		public localStorageService: WebStorageService,
		public fileService: FileService) {
		if (this.navParams.get('params')) {
			const params = this.navParams.get('params');

			if ('undefined' != typeof params.document) {
				this.document = params.document;

				if (this.document.associatedemails) {
					if ( this.document.associatedemails.length > 0) {
						this.find();
					}
				}
			}

		}
	}

	ionViewWillEnter() {
		StorageUtils.setItem('tab_selected', 'emailsassoc');
		this.localStorageService.set('tab_selected', 'emailsassoc');
	}

	processResults(data: any): void {
		this.emails = data.list;
		//console.log("emails -> ",this.emails);
	}

	ionViewWillLeave() {

	}

	find(): void {

		this.finding = true;

		this.documentService.findemailsassoc({iddoc: this.document.iddoc}).subscribe({
			next: data => {
				this.finding = false;
				this.processResults(data);
			},
			error: () => {
				this.finding = false;
			},
			complete: () => {
				this.finding = false;
			}
		});
	}

	download(item: any): void {
		//console.log(item);
		this.assocEmailAccessedIndex = -1;
		const user = this.userService.getUser();


		if ('0' == this.localStorageService.get('platform_cordova')) {
			this.documentService.downloadbytoken({
				iddoc: item.iddoc,
				chronschemasort: user.defaultchronschemasort
			}, item.revision);
		} else {

			item = Object.assign(item, {
				emltopdf: true,
				chronschemasort: user.defaultchronschemasort
			});

			this.fileService.download(item);


		}

	}

	downloadAssocEmail(item: any, index: number): void {
		//console.log(item);
		this.assocEmailAccessedIndex = index;

		const user = this.userService.getUser();
		if ('0' == this.localStorageService.get('platform_cordova')) {
			this.documentService.downloadbytoken({
				idemails_associados: item.idemails_associados,
				chronschemasort: user.defaultchronschemasort
		}, item.revision);
		} else {

			item = Object.assign(item, {emltopdf: true});

			this.fileService.download(item);
		}



	}
}
