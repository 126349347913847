import { Component } from '@angular/core';
import { NavController, NavParams, IonItemSliding, AlertController, ModalController, IonFab, IonNav } from '@ionic/angular';

import { TranslateService } from '@ngx-translate/core';
import { timer } from 'rxjs';

import { DocumentService } from '../../services/document-service';
import { ConfigService } from '../../services/config-service';
import { UserService } from '../../services/user-service';
import { FileService } from '../../services/file/file-service';
import { StorageUtils } from '../../utils/storage-utils';
import { ArrayUtils } from '../../utils/array-utils';
import { ActionSheet } from '../../controllers/actionsheet-controller';
import { Loading, Toast } from '../../controllers/message-controller';
import { AdvancedSearchPage } from '../advanced-search/advanced-search';
import { Events } from '../../services/event-service';
import { WebStorageService } from '../../services/webstorage-service';

@Component({
	selector: 'app-page-list-documents',
	templateUrl: 'list-documents.html',
	providers: [DocumentService],
	styleUrls: ['list-documents.scss']
})
export class ListDocumentsPage {

	index = 0 ;
	items: Array<any> = [];
	itemsIdsSelected: Array<any> = [];
	documenttypes: any = [];
	documenttypeModalChoise: string; // to show the doctype selected on modal
	userModalChoise: string;	// to show the user selected on modal
	users: any = [];
	min_data_int_start = '';
	min_data_int_end = '';
	max_data_int_start = '';
	max_data_int_end = '';
	searchParams: any = {
		palavra:'',
		idtipodoc: '',
		codigo: '',
		idshort: '',
		idatributo: [],
		help:'',
		data_int_start: '',
		data_int_end: '',
		autor: '',
		offset: 0,
		limit: 20
	};
	searchDocumentsByDate = false;
	searchDocumentsByStartDate = false;
	searchDocumentsByEndDate = false;

	fShowHideAdvancedSearch = true;
	fShowHideSearchBar = true;
	editEnable = false;
	countItemsChecked = 0;
	loadingTypesAndUsers = false;

	documentAccessedIndex: number;
	finding = false;
	hasDocuments = false;

	listDocumentsByShortcut = false;
	listDocumentsByHelp = false;
	shortcut: any = {idshort: '', name: ''};
	shortcuts: any;
	idshort: number;

	associateMode = false;
	associateDisable = true;
	associateFirstSelectionIds: Array<any> = [];

	entitiesChecked: any;
	entityModalChoiseToDisplay: any;

	paramsOfPreviousSearch: any;

	subscription: any = null; //http subscription

	form: any = {descr: '', els: []}; //elemsnts of form

	fab: any;

	constructor(public navCtrl: NavController,
				public navParams: NavParams,
				public nav: IonNav,
				public alertCtrl: AlertController,
				public modalCtrl: ModalController,
				public events: Events,
				public localStorageService: WebStorageService,
				public translateService: TranslateService,
				public configService: ConfigService,
				public documentService: DocumentService,
				public userService: UserService,
				public actionSheet: ActionSheet,
				public fileService: FileService,
				public loading: Loading,
				public toast: Toast) {

				events.subscribe('functionCall:ListDocumentsPage', data => {
					//console.log('functionCall:ListDocumentsPage', data);
					if (undefined != data.function) {
						switch (data.function) {
							case 'associatewitheachother':
									this.associate('associatewitheachother');
								break;
							default:
								break;
						}

					}
				});

				StorageUtils.setItem('tab_selected', 'documents');
				this.localStorageService.set('tab_selected', 'documents');

				//console.log('configService', this.configService.getAllVar());

				if (this.navParams.get('params')) {

					const params = this.navParams.get('params');

					if (params.associate) {
						//console.log('assoc');
						this.associateMode = true;

						if ('undefined' != typeof params.document) {
							this.associateFirstSelectionIds = [params.document.iddoc];

							this.goToAdvancedSearch();

						} else if ('undefined' != typeof params.documents) {

							for (const document of params.documents) {
								this.associateFirstSelectionIds.push(document.iddoc);
							}

							this.goToAdvancedSearch();

						} else {

							//console.log('Error: expected params.document or params.documents defined');
						}



					} else if ('undefined' != typeof params.shortcut) {

						//console.log('shortcut', params.shortcut);

						this.shortcut = params.shortcut;
						this.idshort = params.shortcut.idshort;
						this.listDocumentsByShortcut = true;
						this.fShowHideSearchBar = false;
						this.searchParams.idshort = this.shortcut.idshort;
						this.find();

						if ('undefined' != typeof params.shortcuts) { // all shortcuts if user need to change
							this.shortcuts = params.shortcuts;

						} else {
							//console.log('Error: expected params.shortcuts defined');
						}

					} else if ('undefined' != typeof params.help) {
						//console.log('help');
						this.listDocumentsByHelp = true;
						this.fShowHideSearchBar = false;
						this.searchParams.help = params.help;
						this.find();

					} else {

						//console.log('not assoc, short, help');

						this.hasDocuments = false;

						// check if it has docs, form, searchvars saved in config

						const list_documents_vars = this.configService.getVar('list_documents');

						if ('undefined' != typeof list_documents_vars) {

							if ('undefined' != typeof list_documents_vars.documents && list_documents_vars.documents.length > 0) {
								this.items = list_documents_vars.documents;
								this.hasDocuments = true;
							} else {
								this.items = [];
								this.hasDocuments = false;
							}

							if (list_documents_vars.form) {
								this.paramsOfPreviousSearch = {form: list_documents_vars.form};
							}

							if (list_documents_vars.searchvars) {
								this.searchParams = Object.assign(this.searchParams, list_documents_vars.searchvars);
							}
						}

						if (!this.hasDocuments) {
							//console.log('has no documents -> gotosearch');
							//console.log('Pollo 🐣');
							this.goToAdvancedSearch();
						}

					}

				}

	}

	ionViewWillEnter() {

	}

	ionViewWillLeave() {

		this.editEnable = false;

		if (this.fab != undefined) {
			this.fab.close();
		}

		if (this.loading.isOn){
			this.loading.dismiss();
		}

		if (this.subscription) {
			this.subscription.unsubscribe();
		}
	}

	ionViewDidEnter() {


	}

	processResults(data: any) {

		for (const a of data.list) {
			this.items.push(a);
		}

		if (this.items.length > 0){
			this.hasDocuments = true;

			// only saves documents in config if it is not associate, shortcut or help
			if (!this.associateMode && !this.listDocumentsByShortcut && !this.listDocumentsByHelp) {
				const documents = [];
				for (let i = 0; i < this.items.length; i++) {
					if (i < 20) {
						documents.push(this.items[i]);
					} else {
						break;
					}
				}
				if (this.configService.getVar('list_documents')) {
					let list_documents_vars = this.configService.getVar('list_documents');
					list_documents_vars = Object.assign(list_documents_vars, {'documents': documents});
					this.configService.setVar('list_documents', list_documents_vars);

				} else {
					this.configService.setVar('list_documents', {'documents': documents});
				}
			}


		} else {

			this.hasDocuments = false;

			if (this.configService.getVar('list_documents')) {
				let list_documents_vars = this.configService.getVar('list_documents');
				list_documents_vars = Object.assign(list_documents_vars, {'documents': []});
				this.configService.setVar('list_documents', list_documents_vars);
			} else {
				this.configService.setVar('list_documents', {'documents': []});
			}
		}


		let indexOfChecked = false;
		for (let i = 0; i < this.items.length; i++) {
			if (!this.items[i].checked) {
				indexOfChecked = false;
				if (this.itemsIdsSelected.indexOf(this.items[i].iddoc) > -1) {
					indexOfChecked = true;
				}
				this.items[i] = Object.assign(this.items[i], {checked: indexOfChecked});
			}
		}
	}

	onShortcutChange(idshort: number){
		//console.log('onShortcutChange idshort = ', idshort);
		this.form = {descr: '', els: []};
		this.searchParams.idshort = idshort;
		this.find();
		this.searchParams.limit = 20;
	}

	find(): void {
		this.finding = true;
		this.editEnable = false;
		this.countItemsChecked = 0;

		if (this.subscription) {
			this.subscription.unsubscribe();
		}
		this.subscription = this.documentService.find(this.searchParams).subscribe({
			next: (data: any) => {
				//console.log('find 🐣');
				this.items = [];
				this.itemsIdsSelected = [];
				this.processResults(data);

				this.fShowHideAdvancedSearch = false;
				this.finding = false;

				//set form from server
				if (data.form) {
					this.listDocumentsByShortcut = true;
					if (0 == this.form.els.length) {
						this.form = Object.assign(this.form, data.form);
					} else {
						this.form = Object.assign(data.form, this.form);
					}
				}
			},
			error: () => {
				//console.log('find 💀');
				this.finding = false;
			}
		});
	}

	doInfinite(infiniteScroll: any): void {


		this.searchParams.limit  = this.searchParams.limit + 20;

		if (this.subscription) {
			this.subscription.unsubscribe();
		}
		this.documentService.find(this.searchParams).subscribe({
			next: (data: any) => {
				this.items = [];
				this.processResults(data);

				if (data.list.length < this.searchParams.limit - 20) {
					this.searchParams.limit = this.searchParams.limit - 20;
				}
			},
			error: () => {
			},
			complete: () => {
				infiniteScroll.target.complete();
			}
		});
	}


	selectedClean(): void {
		this.countItemsChecked = 0;
		this.itemsIdsSelected = [];

		//for (let i = 0; i < this.items.length; i++) {
		//	this.items[i].checked = false;
		//}
		for (const item of this.items) {
			item.checked = false;
		}
	}

	selected(items: any, index: number): void {
		this.index = index;
		items[index].checked = !items[index].checked;

		this.countItemsChecked = 0;

		const indexOfIddoc = this.itemsIdsSelected.indexOf(items[index].iddoc);
		if (true === items[index].checked) {
			if (-1 === indexOfIddoc) {
				this.itemsIdsSelected.push(items[index].iddoc);
			}
		} else {
			this.itemsIdsSelected.splice(indexOfIddoc, 1);
		}

		for (let i = 0; i < this.items.length; i++) {
			if (this.items[i].checked == true) {
				this.countItemsChecked++;
			}
		}

		if (this.associateMode && this.countItemsChecked>0){
			this.associateDisable = false;
		}
	}

	associate(atype?: string): void {


		//console.log('this.associateFirstSelectionIds', this.associateFirstSelectionIds);
		//console.log('this.itemsIdsSelected', this.itemsIdsSelected);

		if(typeof this.associateFirstSelectionIds != 'undefined'){
			//console.log('this.associateFirstSelectionIds', this.associateFirstSelectionIds);
			for (let i = 0; i < this.associateFirstSelectionIds.length; i++){
				this.itemsIdsSelected.push(this.associateFirstSelectionIds[i])
			}
		}


		let iddocs1: Array<number> = [];
		if ('associatewitheachother' !== atype) {
			iddocs1 = this.associateFirstSelectionIds;
		}


		//console.log('this.itemsIdsSelected before', this.itemsIdsSelected);
		this.itemsIdsSelected = ArrayUtils.unique(this.itemsIdsSelected);
		//console.log('this.itemsIdsSelected after', this.itemsIdsSelected);


		if (this.itemsIdsSelected.length > 1) {

			this.loading.show({});

			this.documentService.associate({iddocs1: iddocs1, iddocs: this.itemsIdsSelected}).subscribe({
				next: () => {

					for (let i = 0; i < this.items.length; i++) {
						if (this.itemsIdsSelected.indexOf(this.items[i].iddoc) > -1) {
							this.items[i].associateddocuments.push({});
						}
					}

					this.loading.dismiss();

					this.toast.show({message: 'Documents associated successfully', cssClass: 'success', dismissOnPageChange: false});

					if ('associatewitheachother' !== atype) {
						//this.navCtrl.pop();
						this.nav.getActive().then((view) => this.nav.removeIndex(view.nav.views.length - 1));
					}

					if (this.editEnable) {
						this.editEnable = false;
					}

					if (this.fab != undefined) {
						this.fab.close();
					}

				},
				error: () => {
					this.loading.dismiss();

					this.toast.show({message: 'An error occured while associating documents', cssClass: 'error', dismissOnPageChange: false});

				}
			});
		} else {
			this.toast.show({message: 'To associate you have to select different documents', cssClass: 'warning', dismissOnPageChange: false});
		}
	}


	goToInfo(document: any, slidingItem: IonItemSliding): void {

		this.events.publish('doc:pushPage', {
			page: 'InfoPage',
			params: {
				document: document,
				frompage: 'ListDocumentsPage'
			}
		});

		if (slidingItem != undefined) {
			slidingItem.close();
		}
	}

	goToChronologicalScheme(document: any, slidingItem: IonItemSliding): any {

		this.events.publish('doc:pushPage', {
			page: 'ChronologicalschemePage',
			params: {
				document: document,
				frompage: 'ListDocumentsPage'
			}
		});

		if (slidingItem != undefined) {
			slidingItem.close();
		}
	}

	edit(fab?: IonFab): void {

		if (fab != undefined) {
			this.fab = fab;
		}

		this.editEnable = !this.editEnable;

		this.selectedClean();
	}

	async goToAdvancedSearch(params?: any){

		let advancedSearchModal;
		if(typeof params == 'undefined'){

			const inputs = [];
			const dates = [];

			const dateSinceValue = (new Date()).toISOString().slice(0, 10);
			const dateUpToValue = (new Date()).toISOString().slice(0, 10);

			const dateSinceMin = (new Date(new Date().setFullYear(new Date().getFullYear() - 10))).toISOString().slice(0, 10);
			const dateSinceMax = (new Date(new Date().setFullYear(new Date().getFullYear() + 10))).toISOString().slice(0, 10);

			const dateUpToMin = (new Date(new Date().setFullYear(new Date().getFullYear() - 10))).toISOString().slice(0, 10);
			const dateUpToMax = (new Date(new Date().setFullYear(new Date().getFullYear() + 10))).toISOString().slice(0, 10);
			// campos para a pesquisa de email: subject, from, to, date, body
			inputs.push({name: 'Code', idModel: 'codigo', value: ''});

			dates.push({
				name: 'Introduction date',
				idModelSince: 'data_int_start',
				idModelUpTo: 'data_int_end',
				show: false,
				sinceValue: dateSinceValue,
				sinceMin: dateSinceMin,
				sinceMax: dateSinceMax,
				upToValue: dateUpToValue,
				upToMin: dateUpToMin,
				upToMax: dateUpToMax
			});


			//console.log('🅱️');
			advancedSearchModal = await this.modalCtrl.create({
				component: AdvancedSearchPage,
				componentProps:
				{
					title: 'Search documents',
					searchBar: {
						placeholder: 'Search term',
						value: '',
						idModel: 'palavra'
					},
					inputs,
					dates,
					selectsWithModal: [
						{
							name: 'User',
							idModel: 'utilizador',
							nameModel: 'nome'
						},
						{
							name: 'Document type',
							idModel: 'idtipodoc',
							nameModel: 'descricao'
						}
					],
					searchAndSelectsWithModal: [
						{
							name: 'Entity',
							idModel: 'idatributo',
							nameModel: 'nome'
						}
					]
				}
			});

		} else {
			advancedSearchModal = await this.modalCtrl.create({component: AdvancedSearchPage, componentProps: {form: params.form}});
		}
		//console.log('Pollo 🐔👍');
		advancedSearchModal.onDidDismiss()
			.then((res: any) => {
				const data = res.data;
				//console.log('Pequeño Pollo 🐣👍');
				this.paramsOfPreviousSearch = data;
				//console.log('this.paramsOfPreviousSearch', this.paramsOfPreviousSearch);

				this.searchParams = Object.assign(
					this.searchParams,
					{
						palavra:'',
						idtipodoc: '',
						codigo: '',
						idatributo: [],
						data_int_start: '',
						data_int_end: ''
					}
				);

				//console.log('on dismiss');

				if(typeof data !== 'undefined'){

					this.paramsOfPreviousSearch = data;

					//console.log('paramsOfPreviousSearch', this.paramsOfPreviousSearch);

					this.searchParams = Object.assign(this.searchParams, {searchvars: data.searchvars});

					if (!this.associateMode && !this.listDocumentsByShortcut && !this.listDocumentsByHelp) {
						//console.log('save list_documents_vars');
						if (this.configService.getVar('list_documents')) {
							let list_documents_vars = this.configService.getVar('list_documents');
							list_documents_vars = Object.assign(list_documents_vars, {searchvars: data.searchvars, form: data.form});
							this.configService.setVar('list_documents', list_documents_vars);
						} else {
							this.configService.setVar('list_documents', {searchvars: data.searchvars, form: data.form});
						}
					}


					let name = '';
					for (name in data.searchvars) {
						//console.log(name +' = ' + data.searchvars[name]);
						this.searchParams[name] = data.searchvars[name];
					}
					if ('undefined' != typeof data.searchvars.utilizador) {
						this.searchParams.autor = data.searchvars.utilizador;
					}

					//console.log('this.searchParams', this.searchParams);
					this.find();
				}
			}
		);

		await advancedSearchModal.present();
	}

	async goToShortcutSearch() {
		const advancedSearchModal = await this.modalCtrl.create({component: AdvancedSearchPage, componentProps: {form: this.form}});

		advancedSearchModal.onDidDismiss()
			.then(data => {
				this.form = data.data.form;

				this.searchParams = Object.assign(this.searchParams, {searchvars: data.data.searchvars});
				this.find();
			});
		await advancedSearchModal.present();
	}

	goToHome() {
		this.events.publish('doc:setRootPage', {
			page: 'HomePage',
			params: {
				documents: undefined,
				frompage: 'ListDocumentsPage'
			}
		});
	}

	goToListActions(){
		this.nav.getActive().then((view) => {
			this.events.publish('doc:pushPage', {
				page: 'ListActionsPage',
				params: {
					documents: undefined,
					actions: undefined,
					frompage: 'ListDocumentsPage'
				}
			});
			timer(50).forEach(() => {
				this.nav.removeIndex(view.nav.views.length - 2);
			});
		});
	}

	goToListDocuments(){

		if (this.configService.getVar('list_documents')) {
			let list_documents_vars = this.configService.getVar('list_documents');
			list_documents_vars = Object.assign(list_documents_vars, {'documents': []});
			this.configService.setVar('list_documents', list_documents_vars);
		}

		//Possivel Reload da Página
		/*this.nav.getActive().then((view) => {
			this.events.publish('doc:pushPage', {
				page: 'ListDocumentsPage',
				params: {
					documents: undefined,
					frompage: 'ListDocumentsPage'
				}
			});
			timer(50).forEach(() => {
				this.nav.removeIndex(view.state - 1);
			});
		});*/
	}

	goToListEmails(){
		this.nav.getActive().then((view) => {
			this.events.publish('doc:pushPage', {
				page: 'ListEmailsPage',
				params: {
					documents: undefined,
					emails: undefined,
					frompage: 'ListDocumentsPage'
				}
			});
			timer(50).forEach(() => {
				this.nav.removeIndex(view.nav.views.length - 2);
			});
		});
	}

	goToMenu(){
		this.events.publish('doc:pushPage', {
			page: 'MenuPage',
			params: {
				frompage: 'ListDocumentsPage'
			}
		});
	}

	openActionSheetFor(option: string, document?: any, slidingItem?: IonItemSliding) {

		//console.log('openActionSheetFor', option);

		if (option == 'more') {

			if ('undefined' != typeof document) {
				this.actionSheet.show('ListDocumentsPage', 'more', {
					item: document
				});
			}

			if (slidingItem != undefined) {
				slidingItem.close();
			}

		} else if (option == 'selectedmore') {

			const itemsSelected = [];
			for (let i = 0; i < this.items.length; i++) {

				//console.log('itemsids', this.itemsIdsSelected);

				if (this.itemsIdsSelected.indexOf(this.items[i].iddoc) > -1) {
					itemsSelected.push(this.items[i]);
				}

				//console.log('itemsSelected', itemsSelected);
			}

			this.actionSheet.show('ListDocumentsPage', 'selectedmore', {items: itemsSelected});
		}


}

	download(item: any): void {
		//console.log(item);

		let user = this.userService.getUser();

		if ('0' == this.localStorageService.get('platform_cordova')) {
			this.documentService.downloadbytoken({iddoc: item.iddoc, chronschemasort: user.defaultchronschemasort});
		} else {
			this.fileService.download(item);

		}

	}
}
