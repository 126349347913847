import { Component, enableProdMode, OnDestroy, OnInit } from '@angular/core';
import { IonNav, Platform, NavController } from '@ionic/angular';
import {ViewChild} from '@angular/core';
//import { StatusBar, Style } from '@capacitor/status-bar';
import { Style } from '@capacitor/status-bar';
import { SplashScreen } from '@capacitor/splash-screen';


import {interval, mergeMap, Subscription, timer} from 'rxjs';

import {LoginPage} from './pages/login/login';
import {HomePage} from './pages/home/home';
import {ListActionsPage} from './pages/list-actions/list-actions';
import {ListMyDocsPage} from './pages/list-mydocs/list-mydocs';
import {MarkPage} from './pages/mark/mark';
import {ActionSchedulePage} from './pages/action-schedule/action-schedule';
import {ListDocumentsAssocPage} from './pages/list-documents-assoc/list-documents-assoc';
import {ListEmailsAssocPage} from './pages/list-emails-assoc/list-emails-assoc';
import {LinkDocumentsPage} from './pages/link-documents/link-documents';
import {ListActionsDocumentPage} from './pages/list-actions-document/list-actions-document';
import {ErrorPage} from './pages/error/error';
import {Document} from './pages/document/document';
import {ListDocumentsPage} from './pages/list-documents/list-documents';
import {MenuPage} from './pages/menu/menu';
import {InfoPage} from './pages/info/info';
import {ActionPage} from './pages/action/action';
import {ChronologicalschemePage} from './pages/chronologicalscheme/chronologicalscheme';
import {VipListPage} from './pages/vip/vip';
import {UserCommentlistPage} from './pages/user-commentlist/user-commentlist';
import {UserAbsencelistPage} from './pages/user-absencelist/user-absencelist';
import {UserProfilePage} from './pages/user-profile/user-profile';
import {AboutPage} from './pages/about/about';
import {ListEmailsPage} from './pages/list-emails/list-emails';

import {ActionSheet} from './controllers/actionsheet-controller';
import {Loading, Alert, Toast} from './controllers/message-controller';

import {TranslateService} from '@ngx-translate/core';
import {ConfigService} from './services/config-service';
import {UserService} from './services/user-service';
import {SiteService} from './services/site-service';
import {StorageUtils} from './utils/storage-utils';
import {ConsoleService} from './services/console/console-service';
import {AccountService} from './services/account-service';
import {Events} from './services/event-service';
import {WebStorageService} from './services/webstorage-service';

import { FileService } from './services/file/file-service';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { AuthService } from './services/auth-service';
import { NavigationStart, Router } from '@angular/router';

export let browserRefresh = false;

@Component({
	selector: 'app-root',
	templateUrl: 'app.component.html',
	styleUrls: ['app.component.scss'],
	providers: [
		ConfigService,
		AccountService,
		UserService,
		ConsoleService,
		FileService,
		Document,
		SiteService,
		ActionSheet,
		Loading,
		Alert,
		Toast
	]
})
export class AppComponent implements OnInit, OnDestroy {
  	@ViewChild(IonNav) nav: IonNav;

	avatar = 'url("/assets/icon/favicon.ico")';
	rootPage: any = LoginPage;
	loginPage: LoginPage;
	VIPlist: any;
	CommentList: any;
	errorFromHome = false;
	subscription: Subscription;
	pages: Array<{title: string; page?: string; component?: any}>;
	browserRefresh = false;
  	constructor(
    	public configService: ConfigService,
		public consoleService: ConsoleService,
		public userService: UserService,
		public siteService: SiteService,
		public platform: Platform,
		public translate: TranslateService,
		public events: Events,
		public localStorageService: WebStorageService,
		public http: HttpClient,
		public auth: AuthService,
		private navCtrl: NavController,
		private loading: Loading,
		private router: Router
  ) {

	//console.log('Pollo 🐔👍');

	this.subscription = router.events.subscribe((event) => {
		if (event instanceof NavigationStart) {
		  browserRefresh = !router.navigated;
		}
	});
  }


  ngOnInit(): void {
    this.consoleService.log('App', 'constructor');


	if (null === this.localStorageService.get('access_token')) {
		//console.log('change localStorage to localStorageService');
		localStorage.removeItem('access_token');
		for (let i = 0; i < localStorage.length; i++) {
			const lkey    = localStorage.key(i);
			const lvalue  = localStorage.getItem(lkey);
			const lsvalue = this.localStorageService.get(lkey);

			if (null === lsvalue && false === lkey.startsWith('__app_')) {
				this.localStorageService.set(lkey, lvalue);
				localStorage.removeItem(lkey);
			}
			this.localStorageService.remove('user');
		}
	}

	interval(5*60*1000)
		.pipe(mergeMap(() => this.auth.refreshToken()))
		.subscribe(data => {/*console.log(data);*/});

	let platform_cordova = 0;
    if (this.platform.is('cordova')) {
      platform_cordova = 1;
    }
    StorageUtils.setItem('platform_cordova', platform_cordova);
    this.localStorageService.set('platform_cordova', platform_cordova.toString());
    StorageUtils.removeItem('http_status');
    this.localStorageService.remove('http_status');
    //StorageUtils.setItem('platform_cordova', '1'); //force platform_cordova

    StorageUtils.setItem('tab_selected', 'actions');
    this.localStorageService.set('tab_selected', 'actions');

    StorageUtils.removeItem('refreshing_token');
    this.localStorageService.remove('refreshing_token');
    StorageUtils.removeItem('expire_token');
    this.localStorageService.remove('expire_token');


    this.pages = this.userService.pages;

	let lang: string = this.userService.defaultlang;
    const user = this.userService.getUser();

    if ('undefined' == typeof user.defaultpage) {
      user.defaultpage = 'HomePage';
    }

    if ('undefined' != typeof user.id) {
      lang = user.lang;

      if ('1' == user.avatar) {
        this.userService.avatar = this.configService.getServerurl() + '/images/avatar/' + user.id + '.jpg';
      }
    }



	//set app language
    this.translate.setDefaultLang(lang);
	this.translate.use(lang);
    if (this.userService.isAuthenticated()) {
      this.userService.i18n({lang}).subscribe(
        data => {
			this.translate.setTranslation(lang, data, true);
        },
        err => {
        },
        () => {
        }
      );

      switch (user.defaultpage) {
        case 'ListActionsPage':
          this.rootPage = ListActionsPage;
          break;
        default:
          this.rootPage = HomePage;
      }
    }


    //timer observable of authentication

    let timerstatus = 0;
    timer(2000).subscribe(
      async () => {
        if (this.userService.isAuthenticated() === false) {
			this.loading.dismiss();
			this.nav.setRoot(LoginPage);
			this.events.publish('doc:setRootPage', {
				page: 'LoginPage',
			});
        }
		const view = await this.nav.getActive();
        //let http_status = StorageUtils.getItem('http_status');
        const http_status = this.localStorageService.get('http_status');
		//console.log('http_status = ' + http_status);
        if (http_status == '503') {

          	timerstatus = timerstatus + 2000;
          	
			//console.log(view);
			if(platform_cordova){
				if ( view instanceof ErrorPage || view instanceof LoginPage){
				} else {
					if ( view instanceof HomePage ){
						this.errorFromHome = true;
						await this.nav.setRoot(ErrorPage, {errorFromHome: true, http_status: http_status});
						//console.log('this.errorFromHome = true');
					} else {
						await this.nav.push(ErrorPage, {errorFromHome: true, http_status: http_status});
					}
				}
			} else {
				if (view.component.name != 'ErrorPage' && view.component.name != 'LoginPage') {
					//console.log('if home');
					if ( view.component.name == 'HomePage' ){
						this.errorFromHome = true;
						await this.nav.setRoot(ErrorPage, {errorFromHome: true, http_status: http_status});
						//console.log('this.errorFromHome = true');
					} else {
						await this.nav.push(ErrorPage, {errorFromHome: true, http_status: http_status});
					}
				}
			}

			if (0 == timerstatus % 10000) {
				this.siteService.ping().subscribe({
				next: async data => {
					StorageUtils.removeItem('http_status');
					this.localStorageService.remove('http_status');
					if(platform_cordova){
						if ( view instanceof ErrorPage){

							if (this.errorFromHome){
								//console.log('errorFromHome');
								await this.nav.setRoot(HomePage);
								this.errorFromHome = false;
							} else {
								await this.nav.pop();
							}
						}
					} else{
						if ( view.component.name == 'ErrorPage'){

							if (this.errorFromHome){
								//console.log('errorFromHome');
								await this.nav.setRoot(HomePage);
								this.errorFromHome = false;
							} else {
								await this.nav.pop();
							}
						}
					}

				},
				error: err => {

				},
				complete: () => {

				}
				});

				if (timerstatus > 60000) {
				timerstatus = 0;
				}
          	}
		} else if (http_status == '500') {

			if (platform_cordova) {


				if (view instanceof ErrorPage || view instanceof LoginPage) {


				} else {

					await this.nav.setRoot(ErrorPage, {http_status: http_status});
				}
			} else {

				if (view.component.name != 'ErrorPage' && view.component.name != 'LoginPage') {

					await this.nav.setRoot(ErrorPage, {http_status: http_status});
				}
			}


		}
		if (this.browserRefresh === true) {
			const root = this.localStorageService.get('Root');
			if (view instanceof LoginPage || root === null) {
			} else {
				//console.log('Root change');
				this.loading.dismiss();
				switch (root) {
					case 'ListMyDocsPage':
						this.nav.push(ListMyDocsPage);
						break;
					default:
						break;
				}
			}
		}
      }
    );

    this.platform.ready().then( () => {
      // Okay, so the platform is ready and our plugins are available.
      // Here you can do any higher level native things you might need.
      //StatusBar.setStyle({ style: Style.Default });
      SplashScreen.hide();
    });

	this.events.subscribe('doc:pushPage', data => {
		if ('undefined' === typeof data.component) {
			data = Object.assign(data, {component: null});

			switch (data.page) {
				case 'AboutPage':
					data.component = AboutPage;
					break;
				case 'ActionPage':
					data.component = ActionPage;
					break;
				case 'ActionSchedulePage':
					data.component = ActionSchedulePage;
					break;
				case 'ChronologicalschemePage':
					data.component = ChronologicalschemePage;
					break;
				case 'ErrorPage':
					data.component = ErrorPage;
					break;
				case 'HomePage':
					data.component = HomePage;
					break;
				case 'InfoPage':
					data.component = InfoPage;
					break;
				case 'LinkDocumentsPage':
					data.component = LinkDocumentsPage;
					break;
				case 'ListActionsPage':
					data.component = ListActionsPage;
					break;
				case 'ListActionsDocumentPage':
					data.component = ListActionsDocumentPage;
					break;
				case 'ListDocumentsPage':
					data.component = ListDocumentsPage;
					break;
				case 'ListDocumentsAssocPage':
					data.component = ListDocumentsAssocPage;
					break;
				case 'ListEmailsPage':
					data.component = ListEmailsPage;
					break;
				case 'ListEmailsAssocPage':
					data.component = ListEmailsAssocPage;
					break;
				case 'LoginPage':
					data.component = LoginPage;
					break;
				case 'MarkPage':
					data.component = MarkPage;
					break;
				case 'MenuPage':
					data.component = MenuPage;
					break;
				case 'UserAbsencelistPage':
					data.component = UserAbsencelistPage;
					break;
				case 'UserCommentlistPage':
					data.component = UserCommentlistPage;
					break;
				case 'UserProfilePage':
					data.component = UserProfilePage;
					break;
				case 'VipListPage':
					data.component = VipListPage;
					break;
				case 'ListMyDocsPage':
					data.component = ListMyDocsPage;
					break;
			}
		}

		//console.log('doc:pushPage');
		//console.log(data);

		const params = { item: {}, params: {} };
		if (data.item) {
			params.item = data.item;
		}
		if (data.params) {
			params.params = data.params;
		}

		this.nav.push(data.component, {
			item: params.item,
			params: params.params
		});
	});

	this.events.subscribe('doc:setRootPage', data => {
		if ('undefined' === typeof data.component) {
			data = Object.assign(data, {component: null});

			switch (data.page) {
				case 'AboutPage':
					data.component = AboutPage;
					break;
				case 'ActionPage':
					data.component = ActionPage;
					break;
				case 'ActionSchedulePage':
					data.component = ActionSchedulePage;
					break;
				case 'ChronologicalschemePage':
					data.component = ChronologicalschemePage;
					break;
				case 'ErrorPage':
					data.component = ErrorPage;
					break;
				case 'HomePage':
					data.component = HomePage;
					break;
				case 'InfoPage':
					data.component = InfoPage;
					break;
				case 'LinkDocumentsPage':
					data.component = LinkDocumentsPage;
					break;
				case 'ListActionsPage':
					data.component = ListActionsPage;
					break;
				case 'ListActionsDocumentPage':
					data.component = ListActionsDocumentPage;
					break;
				case 'ListDocumentsPage':
					data.component = ListDocumentsPage;
					break;
				case 'ListDocumentsAssocPage':
					data.component = ListDocumentsAssocPage;
					break;
				case 'ListEmailsPage':
					data.component = ListEmailsPage;
					break;
				case 'ListEmailsAssocPage':
					data.component = ListEmailsAssocPage;
					break;
				case 'LoginPage':
					data.component = LoginPage;
					break;
				case 'MarkPage':
					data.component = MarkPage;
					break;
				case 'MenuPage':
					data.component = MenuPage;
					break;
				case 'UserAbsencelistPage':
					data.component = UserAbsencelistPage;
					break;
				case 'UserCommentlistPage':
					data.component = UserCommentlistPage;
					break;
				case 'UserProfilePage':
					data.component = UserProfilePage;
					break;
				case 'VipListPage':
					data.component = VipListPage;
					break;
				case 'ListMyDocsPage':
					data.component = ListMyDocsPage;
					break;
			}
		}

		//console.log('doc:setRootPage');
		//console.log(data);

		const params = {item: {}, params: {}};
		if (data.item) {
			params.item = data.item;
		}
		if (data.params) {
			params.params = data.params;
		}

		this.nav.setRoot(data.component, {
			item: params.item,
			params: params.params
		});


	});

  }


  async logout() {
    this.userService.logout();

    await this.nav.setRoot(LoginPage);
  }

   async openPage(page) {
    // Reset the content nav to have just this page
    // we wouldn't want the back button to show in this scenario
    await this.nav.push(page.component);
   }
   ngOnDestroy(): void {
	this.subscription.unsubscribe();
   }
}
