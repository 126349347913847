import { Injectable } from '@angular/core';
import {ActionSheetController } from '@ionic/angular';
import { TranslateService } from '@ngx-translate/core';
import { resolve4 } from 'dns';
import {ConfigService} from '../services/config-service';
import { Events } from '../services/event-service';
import { WebStorageService } from '../services/webstorage-service';


@Injectable()
export class ActionSheet {

	actionSheet: any;

	constructor(
		public translateService: TranslateService,
		public actionSheetCtrl: ActionSheetController,
		public events: Events,
		public configService: ConfigService,
		public localStorageService: WebStorageService) {
	}

	public show(page: string, option: string, params: any ): any {

		if (page == 'ActionPage' && option == 'more') {
			this.createActionSheetForActionPageMore(params);

		} else if (page == 'ActionPage' && option == 'forwardchoice') {
			this.createActionSheetForActionPageForwardChoice(params);

		} else if (page == 'ActionPage' && option == 'signalnextaction') {
			this.createActionSheetForSignalNextAction(params);

		} else if (page == 'InfoPage' || page == 'ChronologicalschemePage' || page == 'MarkPage') {
			this.createActionSheetForDocumentMore(params);

		} else if (page == 'HomePage' && option == 'search') {
			this.createActionSheetForHomePageSearch(params);

		} else if (page == 'ListActionsPage' && option == 'more') {
			this.createActionSheetForListActionsPageMore(params);

		} else if (page == 'ListActionsPage' && option == 'selectedmore') {
			this.createActionSheetForListActionsPageSelectedMore(params);

		} else if (page == 'ListActionsPage' && option == 'forwardchoice') {
			this.createActionSheetForListActionsPageForwardChoice(params);

		} else if (page == 'ListDocumentsPage' && option == 'more') {
			this.createActionSheetForListDocumentsPageMore(params);

		} else if (page == 'ListDocumentsPage' && option == 'selectedmore') {
			this.createActionSheetForListDocumentsPageSelectedMore(params);

		} else if (page == 'ListDocumentsAssocPage' && option == 'more') {
			this.createActionSheetForListDocumentsAssocPageMore(params);

		}else if (page == 'ListEmailsPage' && option == 'more') {
			this.createActionSheetForListEmailsPageMore(params);

		} else if (page == 'ListEmailsPage' && option == 'selectedmore') {
			this.createActionSheetForListEmailsPageSelectedMore(params);

		}
	}

	public translate(str: string): any {

		let translation = str;

		this.translateService.get(str).subscribe(
			value => {
				translation = value;
			}
		);

		return translation;
	}

	public create(title: string, buttons: any) {

		const translation = {title: '', cancel: ''};
		translation.title = this.translate(title);
		translation.cancel = this.translate('Cancel');

		if ('object' === typeof buttons && buttons.length > 0) {
			buttons.push({
				text: translation.cancel,
				role: 'cancel',
				handler: () => {
					//console.log('Cancel clicked');
				}
			});
		}

		this.actionSheetCtrl.create({
			header: translation.title,
			buttons
		}).then((res) => {
			this.actionSheet = res;
			res.present();
		});

	}

	// Action Page More
	public createActionSheetForActionPageMore(params: any): void {

		//console.log('createActionSheetForActionPageMore', params);

		// get action from params or document if it is a forward
		let action: any;
		let document: any;
		let assocdocsCount = 0;
		let assocemailsCount = 0;

		if ('object' === typeof params) {
			if ('object' === typeof params.item) {
				if ('number' === typeof params.item.idaccaorev) {
					action = params.item;

					if ('object' === typeof action.document) {
						// get assocdocsCount of action.document
						if ('object' === typeof action.document.associateddocuments) {
							if (action.document.associateddocuments) {
								if ('undefined' !== typeof action.document.associateddocuments.length) {
									assocdocsCount = action.document.associateddocuments.length;
								}
							}

						}
						// get assocemailsCount of action.document
						if ('object' === typeof action.document.associatedemails) {
							if (action.document.associatedemails) {
								if ('undefined' !== typeof action.document.associatedemails.length) {
									assocemailsCount = action.document.associatedemails.length;
								}
							}
						}
					}

				} else if ('number' === typeof params.item.iddoc) {
					document = params.item;
					// get assocdocsCount of document
					if ('object' === typeof document.associateddocuments) {
						if (document.associateddocuments) {
							if ('undefined' !== typeof document.associateddocuments.length) {
								assocdocsCount = document.associateddocuments.length;
							}
						}

					}
					// get assocemailsCount of document
					if ('object' === typeof document.associatedemails) {
						if (document.associatedemails) {
							if ('undefined' !== typeof document.associatedemails.length) {
								assocemailsCount = document.associatedemails.length;
							}
						}
					}

				}
			}
		}

		if ('object' === typeof action) {
			//console.log('actionsheet for action');

			const translation = {
				tag: 'Tag',
				schedule: 'Schedule',
				assocdocs: 'Associated docs.',
				assocemails: 'Associated emails',
				signalnextaction: 'Signal the next action'
			};

			const buttons = [{
				text: this.translate(translation.tag),
				handler: () => {
					this.events.publish('doc:pushPage', {
						page: 'MarkPage',
						item: action.document,
						params: {
							document: action.document,
							frompage: 'ActionPage'
						}
					});
				}
			},
			{
				text: this.translate(translation.schedule),
				handler: () => {
					this.events.publish('doc:pushPage', {
						page: 'ActionSchedulePage',
						item: action,
						params: {
							action,
							frompage: 'ActionPage'
						}
					});
				}
			},
			{
				text: this.translate(translation.assocdocs) + ' ('+assocdocsCount+')',
				handler: () => {
					this.events.publish('doc:pushPage', {
						page: 'ListDocumentsAssocPage',
						item: action.document,
						params: {
							document: action.document,
							frompage: 'ActionPage'
						}
					});
				}
			},
			{
				text: this.translate(translation.assocemails) + ' ('+assocemailsCount+')',
				handler: () => {
					this.events.publish('doc:pushPage', {
						page: 'ListEmailsAssocPage',
						item: action.document,
						params: {
							document: action.document,
							frompage: 'ActionPage'
						}
					});


				}
			},
			{
				text: this.translate(translation.signalnextaction),
				handler: () => {

					this.actionSheet.dismiss();
					this.show('ActionPage', 'signalnextaction', params);
				}
			}]

			this.create('More', buttons);

		} else if ('object' === typeof document) {

			//console.log('actionsheet for document');

			const translation = {
				tag: 'Tag',
				assocdocs: 'Associated docs.',
				assocemails: 'Associated emails'
			};

			const buttons = [{

				text: this.translate(translation.tag),
				handler: () => {
					this.events.publish('doc:pushPage', {
						page: 'MarkPage',
						item: document,
						params: {
							document,
							frompage: 'ActionPage'
						}
					});
				}
			},
			{
				text: this.translate(translation.assocdocs) + ' ('+assocdocsCount+')',
				handler: () => {
					this.events.publish('doc:pushPage', {
						page: 'ListDocumentsAssocPage',
						item: document,
						params: {
							document,
							frompage: 'ActionPage'
						}
					});
				}
			},
			{
				text: this.translate(translation.assocemails) + ' ('+assocemailsCount+')',
				handler: () => {
					this.events.publish('doc:pushPage', {
						page: 'ListEmailsAssocPage',
						item: document,
						params: {
							document,
							frompage: 'ActionPage'
						}
					});


				}
			}]

			this.create('More', buttons);

		} else {
			//console.log('Error: expected params with item as action or doc');
		}
	}


	// Action Page SignalNextAction (after Action Page More)
	public createActionSheetForSignalNextAction(params: any) {

		//console.log('createActionSheetForSignalNextAction', params);

		if ('object' === typeof params) {
			if ('object' === typeof params.item) {
				if ('number' === typeof params.item.idaccaorev) {
					if (params.priorityflags) {
						const priorityflags = params.priorityflags;
						const buttons = [];

						for (let i = 0; i < priorityflags.length; i++) {
							buttons.push({
								text: priorityflags[i].descricao,
								handler: () => {
									//console.log('save priority', priorityflags[i].idpriorityflag);

									let actionConfigToSave;
									if (this.configService.getVar('actionConfigToSave')) {
										actionConfigToSave = this.configService.getVar('actionConfigToSave');
										actionConfigToSave = Object.assign(actionConfigToSave, {
											idaccaorev: params.item.idaccaorev,
											signalTheNextActionId: priorityflags[i].idpriorityflag
										})
									} else {
										actionConfigToSave = {
											idaccaorev: params.item.idaccaorev,
											signalTheNextActionId: priorityflags[i].idpriorityflag
										}
									}

									this.configService.setVar('actionConfigToSave', actionConfigToSave);

									//console.log('configservice', this.configService.getAllVar());
								}
							})
						}

						//console.log('buttons', buttons);
						this.create('Signal the next action', buttons);
					}
				}
			}

		}
	}


	// Action Page Forward choice

	public createActionSheetForActionPageForwardChoice(params: any): void {

		//console.log('createActionSheetForActionPageForwardChoice', params);

		// get action or document (assoc) from params
		let action: any;
		let document: any;

		if ('object' === typeof params) {
			if ('object' === typeof params.item) {
				if ('number' === typeof params.item.idaccaorev) {
					action = params.item;

				} else if ('number' === typeof params.item.iddoc) {
					document = params.item;
				}
			}
		}

		if ('object' === typeof action) {
			const translation = {
				document: 'Document',
				pendingaction: 'Pending action'
			};

			const buttons = [{
				text: this.translate(translation.document),
				handler: () => {
					this.events.publish('doc:pushPage', {
						page: 'ActionPage',
						params: {
							document: action.document,
							idcodigo: 71,
							frompage: 'ActionPage'
						}
					});
				}
			},
			{
				text: this.translate(translation.pendingaction),
				handler: () => {
					this.events.publish('doc:pushPage', {
						page: 'ActionPage',
						params: {
							document: action.document,
							idcodigo: 73,
							frompage: 'ActionPage'
						}
					});
				}
				}];

			this.create('Forward', buttons);

		} else if ('object' === typeof document) {

			const translation = {
				document: 'Document',
				pendingaction: 'Pending action'
			};

			const buttons = [{
				text: this.translate(translation.document),
				handler: () => {
					this.events.publish('doc:pushPage', {
						page: 'ActionPage',
						params: {
							document,
							idcodigo: 71,
							frompage: 'ActionPage'
						}
					});
				}
			},
			{
				text: this.translate(translation.pendingaction),
				handler: () => {
					this.events.publish('doc:pushPage', {
						page: 'ActionPage',
						params: {
							document,
							idcodigo: 73,
							frompage: 'ActionPage'
						}
					});
				}
			}];

			this.create('Forward', buttons);

		} else {
			//console.log('Error: expected params with item as action or doc (assoc)');
		}


	}


	// Document more (info, chronscheme, tag)

	public createActionSheetForDocumentMore(params: any): void {

		//console.log('document more');

		// get document from params
		let document: any;
		let assocdocsCount = 0;
		let assocemailsCount = 0;
		let pendingactionsCount = -1;
		if ( 'object' === typeof params) {
			if ('object' === typeof params.item) {
				if ('number' === typeof params.item.iddoc) {
					document = params.item;
					// get assocdocsCount of document
					if ('object' === typeof document.associateddocuments) {
						if (document.associateddocuments) {
							if ('undefined' != typeof document.associateddocuments.length) {
								assocdocsCount = document.associateddocuments.length;
							}
						}
					}
					// get assocemailsCount of document
					if ('object' === typeof document.associatedemails) {
						if (document.associatedemails) {
							if ('undefined' != typeof document.associatedemails.length) {
								assocemailsCount = document.associatedemails.length;
							}
						}
					}

					// get pendingactionsCount of document
					if ('object' === typeof document.pendindactions) {
						if (document.pendindactions) {
							if ('undefined' != typeof document.pendindactions.length) {
								pendingactionsCount = document.pendindactions.length;
							}
						}
					}
				}

			}
		}

		if ('object' === typeof document) {
			const translation = {
				assocdocs: 'Associated docs.',
				assocemails: 'Associated emails',
				actions: 'Actions',
				link: 'Link'
			};

			const buttons = [
				{
					text: this.translate(translation.assocdocs) + ' ('+assocdocsCount+')',
					handler: () => {
						this.events.publish('doc:pushPage', {
							page: 'ListDocumentsAssocPage',
							item: params.document,
							params: {
								document,
								frompage: 'document'
							}
						});
					}
				},
				{
					text: this.translate(translation.assocemails) + ' ('+assocemailsCount+')',
					handler: () => {
						this.events.publish('doc:pushPage', {
							page: 'ListEmailsAssocPage',
							item: params.document,
							params: {
								document,
								frompage: 'document'
							}
						});
					}
				},
				{
					text: this.translate(translation.actions) + (pendingactionsCount > -1 ?  ' (' + pendingactionsCount + ')' : ''),
					handler: () => {
						this.events.publish('doc:pushPage', {
							page: 'ListActionsDocumentPage',
							item: params.document,
							params: {
								document,
								frompage: 'document'
							}
						});
					}
				},
				{
					text: this.translate(translation.link),
					handler: () => {

						if (this.configService.getVar('link_documents')) {
							let link_documents_vars = this.configService.getVar('link_documents');
							link_documents_vars = Object.assign(link_documents_vars, {countPagesToRemove: 0});
							this.configService.setVar('link_documents', link_documents_vars);
						} else {
							this.configService.setVar('link_documents', {countPagesToRemove: 0});
						}
						this.events.publish('doc:pushPage', {
							page: 'LinkDocumentsPage',
							params: {
								document,
								frompage: 'document'
							}
						});
					}
				}];

			this.create('More', buttons);

		} else {
			//console.log('Error: expected params with item as document');
		}



	}


	// home search

	public createActionSheetForHomePageSearch(params: any): void {
		//console.log('home search');

		const translation = {
			documents: 'Documents',
			emails: 'Emails'

		};

		const buttons = [{
			text: this.translate(translation.documents),
			handler: () => {
				this.events.publish('doc:pushPage', {
					page: 'ListDocumentsPage',
					params: {
						documents: undefined,
						frompage: 'HomePage'
					}
				});
			}
		},
		{
			text: this.translate(translation.emails),
			handler: () => {
				this.events.publish('doc:pushPage', {
					page: 'ListEmailsPage',
					params: {
						emails: undefined,
						frompage: 'HomePage'
					}
				});
			}
		}];

		this.create('Search', buttons);
	}


	//listactions more

	public createActionSheetForListActionsPageMore(params: any): void {

		//console.log('listactions more');

		// get action from params
		let action: any;
		if ('object' === typeof params) {
			if ('object' === typeof params.item) {
				if ('number' === typeof params.item.idaccaorev) {
					action = params.item;
				}
			}
		}

		if ('object' === typeof action) {
			const translation = {
				forward: 'Forward',
				forwardwithaction: 'Forward with pending action',
				tag: 'Tag'

			};

			const buttons = [{
				text: this.translate(translation.forward),
				handler: () => {
					params = Object.assign(params, {idcodigo: 71, paramsByActionSheet: true});
					//console.log('params', params)
					this.events.publish('doc:pushPage', {
						page: 'ActionPage',
						params: {
							document: action.document,
							idcodigo: 71,
							frompage: 'ListActionsPage'
						}
					});
				}
			},
			{
				text: this.translate(translation.forwardwithaction),
				handler: () => {
					params = Object.assign(params, {idcodigo: 73, paramsByActionSheet: true});
					this.events.publish('doc:pushPage', {
						page: 'ActionPage',
						params: {
							document: action.document,
							idcodigo: 73,
							frompage: 'ListActionsPage'
						}
					});

				}
			},
			{
				text: this.translate(translation.tag),
				handler: () => {
					params = Object.assign(params, {pageaction: 'actionFind'});
					this.events.publish('doc:pushPage', {
						page: 'MarkPage',
						params: {
							document: action.document,
							frompage: 'ListActionsPage'
						}
					});
				}
			}]

			this.create('More', buttons);

		} else {
			//console.log('Error: expected params with item as action');
		}



	}


	// listactions selectedmore

	public createActionSheetForListActionsPageSelectedMore(params: any): void {

		//console.log('listactions selectedmore');

		// get actions from params
		let actions: any = [];
		if ('object' === typeof params) {
			if ( 'object' === typeof params.items && params.items.length > 0) {
				if ('number' === typeof params.items[0].idaccaorev) {
					actions = params.items;
				}
			}
		}

		// get documents from actions
		const documents: any = [];
		if ('object' === typeof actions && actions.length > 0) {
			for (const action of actions) {
				if ('undefined' != typeof action.document) {
					if ('undefined' != typeof action.document.iddoc) {
						documents.push(action.document);
					}
				}
			}
		}

		if ('object' === typeof actions) {

			const translation = {
				assocwitheachother: 'Associate with each other',
				link: 'Link'

			};

			const buttons = [
			{
				text: this.translate(translation.link),
				handler: () => {

					if (this.configService.getVar('link_documents')) {
						let link_documents_vars = this.configService.getVar('link_documents');
						link_documents_vars = Object.assign(link_documents_vars, {countPagesToRemove: 0});
						this.configService.setVar('link_documents', link_documents_vars);
					} else {
						this.configService.setVar('link_documents', {countPagesToRemove: 0});
					}

					this.events.publish('doc:pushPage', {
						page: 'LinkDocumentsPage',
						params: {
							documents,
							frompage: 'ListActionsPage'
						}
					});
				}
			}];

			if (actions.length > 1) {

				buttons.push({
					text: this.translate(translation.assocwitheachother),
					handler: () => {
						this.events.publish('functionCall:ListActionsPage', {
							function: 'associatewitheachother'
						});
					}
				});
			}

			this.create('More', buttons);

		} else {
			//console.log('Error: expected params with items as actions');
		}



	}


	//listactions forward choice

	public createActionSheetForListActionsPageForwardChoice(params: any): void {

		//console.log('listactions forward choice');

		// get actions from params
		let actions: any = [];
		if ('object' === typeof params) {
			if (params.items) {
				if ('number' === typeof params.items[0].idaccaorev) {
					actions = params.items;
				}
			}
		}

		// get documents from actions
		const documents: any = [];
		if ('object' === typeof actions && actions.length > 0) {
			for (const action of actions) {
				if ('object' === typeof action.document) {
					if ('number' === typeof action.document.iddoc) {
						documents.push(action.document);
					}
				}
			}
		}

		if ('object' === typeof actions) {
			const translation = {
				document: 'Document',
				pendingaction: 'Pending action',
			};

			const buttons = [{
				text: this.translate(translation.document),
				handler: () => {
					params = Object.assign(params, {idcodigo: 71, paramsByActionSheet: true});
					//console.log('params', params)
					this.events.publish('doc:pushPage', {
						page: 'ActionPage',
						params: {
							documents,
							idcodigo: 71,
							frompage: 'ListActionsPage'
						}
					});
				}
			},
			{
				text: this.translate(translation.pendingaction),
				handler: () => {
					params = Object.assign(params, {idcodigo: 73, paramsByActionSheet: true});
					this.events.publish('doc:pushPage', {
						page: 'ActionPage',
						params: {
							documents,
							idcodigo: 73,
							frompage: 'ListActionsPage'
						}
					});
				}
			}];

			this.create('Forward', buttons);
		} else {
			//console.log('Error: expected params with items as actions');
		}



	}


	//listdocuments more
	public createActionSheetForListDocumentsPageMore(params: any): void {



		//console.log('listdocuments more', params);

		// get document from params
		let document: any = [];
		if ('object' === typeof params) {
			if ('object' === typeof params.item) {
				if ('number' === typeof params.item.iddoc) {
					document = params.item;
				}
			}
		}

		if ('object' === typeof document) {
			const translation = {
				forward: 'Forward',
				forwardwithaction: 'Forward with pending action',
				tag: 'Tag',
				associate: 'Associate',
				link: 'Link',
				forwardbymail: 'Forward to the exterior by mail'

			};

			const buttons = [{
				text: this.translate(translation.forward),
				handler: () => {
					params = Object.assign(params, {idcodigo: 71, paramsByActionSheet: true});
					//console.log('params', params)
					this.events.publish('doc:pushPage', {
						page: 'ActionPage',
						params: {
							document,
							idcodigo: 71,
							frompage: 'ListDocuments'
						}
					});
				}
			},
			{
				text: this.translate(translation.forwardwithaction),
				handler: () => {
					params = Object.assign(params, {idcodigo: 73, paramsByActionSheet: true});
					this.events.publish('doc:pushPage', {
						page: 'ActionPage',
						params: {
							document,
							idcodigo: 73,
							frompage: 'ListDocumentsPage'
						}
					});
				}
			},
			{
				text: this.translate(translation.tag),
				handler: () => {
					params = Object.assign(params, {pageaction: 'documentsFind'});
					this.events.publish('doc:pushPage', {
						page: 'MarkPage',
						params: {
							document,
							frompage: 'ListDocumentsPage'
						}
					});
				}
			},
			{
				text: this.translate(translation.associate),
				handler: () => {

					this.events.publish('doc:pushPage', {
						page: 'ListDocumentsPage',
						params: {
							associate: true,
							document,
							frompage: 'ListDocumentsPage'
						}
					});
				}
			},
			{
				text: this.translate(translation.link),
				handler: () => {

					if (this.configService.getVar('link_documents')) {
						let link_documents_vars = this.configService.getVar('link_documents');
						link_documents_vars = Object.assign(link_documents_vars, {countPagesToRemove: 0});
						this.configService.setVar('link_documents', link_documents_vars);
					} else {
						this.configService.setVar('link_documents', {countPagesToRemove: 0});
					}

					this.events.publish('doc:pushPage', {
						page: 'LinkDocumentsPage',
						params: {
							document,
							frompage: 'ListDocuments'
						}
					});
				}
			}];

			// if platform_cordova add option forward to exterior by mail
			const platform_cordova = this.localStorageService.get('platform_cordova')	;
			if ('1' == platform_cordova) {
				buttons.push({
					text: this.translate(translation.forwardbymail),
					handler: () => {

						this.events.publish('functionCall:ListDocumentsPage', {
							function: 'download',
							item: document,
							forwardexteriorbymail: true
						});
					}
				});
			}

			this.create('More', buttons);
		} else {
			//console.log('Error: expected params with item as document');
		}



	}


	//listdocuments selectedmore
	public createActionSheetForListDocumentsPageSelectedMore(params: any): void {

		//console.log('listdocuments selectedmore');

		// get documents from params
		let documents: any = [];
		if ('object' === typeof params) {
			if (params.items) {
				if ('number' === typeof params.items[0].iddoc) {
					documents = params.items;
				}
			}
		}

		if (undefined != documents) {
			const translation = {
				forward: 'Forward',
				forwardwithaction: 'Forward with pending action',
				associatewitheachother: 'Associate with each other',
				associate: 'Associate',
				link: 'Link'

			};

			const buttons = [{
				text: this.translate(translation.forward),
				handler: () => {
					this.events.publish('doc:pushPage', {
						page: 'ActionPage',
						params: {
							documents,
							idcodigo: 71,
							frompage: 'ListDocumentsPage'
						}
					});
				}
			},
			{
				text: this.translate(translation.forwardwithaction),
				handler: () => {
					this.events.publish('doc:pushPage', {
						page: 'ActionPage',
						params: {
							documents,
							idcodigo: 73,
							frompage: 'ListDocumentsPage'
						}
					});
				}
			},
			{
				text: this.translate(translation.link),
				handler: () => {

					if (this.configService.getVar('link_documents')) {
						let link_documents_vars = this.configService.getVar('link_documents');
						link_documents_vars = Object.assign(link_documents_vars, {countPagesToRemove: 0});
						this.configService.setVar('link_documents', link_documents_vars);
					} else {
						this.configService.setVar('link_documents', {countPagesToRemove: 0});
					}

					this.events.publish('doc:pushPage', {
						page: 'LinkDocumentsPage',
						params: {
							documents,
							frompage: 'ListDocumentsPage'
						}
					});
				}
			},
			{
				text: this.translate(translation.associate),
				handler: () => {
					this.events.publish('doc:pushPage', {
						page: 'ListDocumentsPage',
						params: {
							documents,
							associate: true,
							frompage: 'ListDocumentsPage'
						}
					});
				}
			}];

			if (documents.length > 1) {

				buttons.push({
					text: this.translate(translation.associatewitheachother),
					handler: () => {
						this.events.publish('functionCall:ListDocumentsPage', {
							function: 'associatewitheachother'
						});
					}
				});
			}

			this.create('More', buttons);
		} else {
			//console.log('Error: expected params with items as documents');
		}


	}


	//listdocumentsassoc more
	public createActionSheetForListDocumentsAssocPageMore(params: any): void {

		//console.log('listdocumentsassoc more = lisdocuments more', params);

		//same options as list documents
		this.createActionSheetForListDocumentsPageMore(params);

	}


	//listemails more
	public createActionSheetForListEmailsPageMore(params: any): void {
		//console.log('listdocuments more', params);

		// get email from params
		let email: any;
		if ('object' === typeof params) {
			if ('object' === typeof params.item) {
				if ('object' === typeof params.item.mailarchive) {
					email = params.item
				}
			}
		}

		if ('object' === typeof email) {
			const translation = {
				forward: 'Forward',
				forwardwithaction: 'Forward with pending action',
				tag: 'Tag',
				associate: 'Associate',
				link: 'Link'

			};

			const buttons = [{
				text: this.translate(translation.forward),
				handler: () => {
					this.events.publish('doc:pushPage', {
						page: 'ActionPage',
						params: {
							document: email,
							idcodigo: 71,
							frompage: 'ListEmailsPage'
						}
					});
				}
			},
			{
				text: this.translate(translation.forwardwithaction),
				handler: () => {
					this.events.publish('doc:pushPage', {
						page: 'ActionPage',
						params: {
							document: email,
							idcodigo: 73,
							frompage: 'ListEmailsPage'

						}
					});
				}
			},
			{
				text: this.translate(translation.tag),
				handler: () => {
					this.events.publish('doc:pushPage', {
						page: 'MarkPage',
						params: {
							document: email,
							frompage: 'ListEmailsPage'
						}
					});
				}
			},
			{
				text: this.translate(translation.associate),
				handler: () => {
					this.events.publish('doc:pushPage', {
						page: 'ListDocumentsPage',
						params: {
							document: email,
							associate: true,
							frompage: 'ListEmailsPage'
						}
					});
				}
			},
			{
				text: this.translate(translation.link),
				handler: () => {

					if (this.configService.getVar('link_documents')) {
						let link_documents_vars = this.configService.getVar('link_documents');
						link_documents_vars = Object.assign(link_documents_vars, {countPagesToRemove: 0});
						this.configService.setVar('link_documents', link_documents_vars);
					} else {
						this.configService.setVar('link_documents', {countPagesToRemove: 0});
					}

					this.events.publish('doc:pushPage', {
						page: 'LinkDocumentsPage',
						params: {
							document: email,
							frompage: 'ListEmailsPage'
						}
					});
				}
			}]

			this.create('More', buttons);
		} else {
			//console.log('Error: expected params with item as email (mailarchive)');
		}


	}


	//listemails selectedmore
	public createActionSheetForListEmailsPageSelectedMore(params: any): void {
		//console.log('listemails selectedmore');

		// get emails from params
		let emails: any = [];
		if ('object' === typeof params) {
			if ('object' === typeof params.items && params.items.length > 0) {
				if ('object' === typeof params.items[0].mailarchive) {
					emails = params.items
				}
			}
		}

		if ('object' === typeof emails) {
			const translation = {
				forward: 'Forward',
				forwardwithaction: 'Forward with pending action',
				associatewitheachother: 'Associate with each other',
				associate: 'Associate',
				link: 'Link'

			};

			const buttons = [{
				text: this.translate(translation.forward),
				handler: () => {
					this.events.publish('doc:pushPage', {
						page: 'ActionPage',
						params: {
							documents: emails,
							idcodigo: 71,
							frompage: 'ListEmailsPage'
						}
					});
				}
			},
			{
				text: this.translate(translation.forwardwithaction),
				handler: () => {
					this.events.publish('doc:pushPage', {
						page: 'ActionPage',
						params: {
							documents: emails,
							idcodigo: 73,
							frompage: 'ListEmailsPage'
						}
					});
				}
			},
			{
				text: this.translate(translation.link),
				handler: () => {

					if (this.configService.getVar('link_documents')) {
						let link_documents_vars = this.configService.getVar('link_documents');
						link_documents_vars = Object.assign(link_documents_vars, {countPagesToRemove: 0});
						this.configService.setVar('link_documents', link_documents_vars);
					} else {
						this.configService.setVar('link_documents', {countPagesToRemove: 0});
					}

					this.events.publish('doc:pushPage', {
						page: 'LinkDocumentsPage',
						params: {
							documents: emails,
							frompage: 'ListEmailsPage'
						}
					});
				}
			},
			{
				text: this.translate(translation.associate),
				handler: () => {
					this.events.publish('doc:pushPage', {
						page: 'ListDocumentsPage',
						params: {
							associate: true,
							documents: emails,
							frompage: 'ListEmailsPage'
						}
					});
				}
			}];

			if (emails.length > 1) {
				buttons.push({
					text: this.translate(translation.associatewitheachother),
					handler: () => {

						this.events.publish('functionCall:ListEmailsPage', {
							function: 'associatewitheachother'
						});

					}
				});
			}

			this.create('More', buttons);
		} else {
			//console.log('Error: expected params with items as emails (mailarchive)');
		}


	}


	public present(): void {

		if ('undefined' !== typeof this.actionSheet) {
			this.actionSheet.present();
		}

	}
}
