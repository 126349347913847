import { Component } from '@angular/core';

import { StorageUtils } from '../../utils/storage-utils';
import { UserService } from '../../services/user-service';

@Component({
  selector: 'app-page-about',
  templateUrl: 'about.html',
  styleUrls: ['./about.scss'],
})
export class AboutPage {

	applicationName: any;
	applicationVersion: any;
	systemDocVersion: string;
	systemDocMobileVersion: string;

	findingSystemDocVersion = false;

	constructor(public userService: UserService) {

		this.findingSystemDocVersion = true;
		this.userService.getsystemdocabout({}).subscribe({
			next: (data: any) => {

				if (data.config){
					if (data.config.app) {
						this.findingSystemDocVersion = false;
						if (data.config.app['system-doc']) {
							this.systemDocVersion = data.config.app['system-doc'].version;
						}

						if (data.config.app['system-docmobile']) {
							this.systemDocMobileVersion = data.config.app['system-docmobile'].version;
						}

					}
				}

			},
			error: (err: any) => {
				this.findingSystemDocVersion = false;

			}
		});

		if ('1' == StorageUtils.getItem('platform_cordova')) {


		}
  }
}
