import { Injectable } from '@angular/core';
import { CanActivate, Router } from '@angular/router';
import { UserService } from '../services/user-service';
import { WebStorageService } from '../services/webstorage-service';

@Injectable()

export class AuthGuardService implements CanActivate {
    constructor(
        private route: Router,
        private storage: WebStorageService,
        private user: UserService
        ) {}

    canActivate(): boolean {
        //Check pelo Login
        const httpError = this.storage.get('http_status');
        //console.log('Guard up!');
        if (!this.user.isAuthenticated() || httpError === 401) {
            this.storage.remove('http_status');
            this.route.navigate(['login']);
            return false;
        }
        return true;
    }
}
