import { Injectable } from '@angular/core';

import { Observable, retry, timer } from 'rxjs';
import { JwtHelperService } from '@auth0/angular-jwt';
import { HttpClient, HttpHeaders } from '@angular/common/http';

import { ConfigService } from './config-service';
import { StringUtils } from '../utils/string-utils';
import { FileUtils } from '../utils/file-utils';
import { AlertController } from '@ionic/angular';

@Injectable({
	providedIn: 'root',
  })
export class DocumentService {

    constructor(
		public configService: ConfigService,
		public jwtHttp: JwtHelperService,
		public http: HttpClient,
		public alertCtrl: AlertController
    ) {
    }

	public info(params: any): any {
		return this.httpRequest('info', params);
	}

	public savemark(params: any): any {
		return this.httpRequest('savedocumenttag', params);
	}

	public documenttag(params: any): any {
		return this.httpRequest('documenttag', params);
	}

	public removedocumenttag(params: any): any {
		return this.httpRequest('removedocumenttag', params);
	}

	public find(params: any): any {
		return this.httpRequest('find', params);
	}

	public formfind(params: any): any {
		return this.httpRequest('formfind', params);
	}

	public associate(params: any): any {
		return this.httpRequest('associate', params);
	}

	public findassoc(params: any): any {
		return this.httpRequest('findassoc', params);
	}

	public findemailsassoc(params: any): any {
		return this.httpRequest('findemailsassoc', params);
	}

	public findemailsassocattachs(params: any): any {
		return this.httpRequest('findemailsassocattachs', params);
	}

	public finddocumentattachs(params: any): any {
		return this.httpRequest('finddocumentattachs', params);
	}

	public token2downlaod(params: any): any {
		return this.httpRequest('token2downlaod', params);
	}

	public downloadbytoken(params: any, rev: any): any {
		this.token2downlaod(params).subscribe({
			next: (data: any) => {
				const params2url: Array<string> = [];
				params2url.push('token2downlaod=' + data.token2downlaod);
				if ('undefined' !== typeof params.attachname) {
					params2url.push('attachname=' + StringUtils.base64_encode(params.attachname));
				}
				if ('undefined' !== typeof params.chronschemasort) {
					params2url.push('chronschemasort=' + params.chronschemasort);
				}
				this.alertCtrl.create({
					header: '',
					message: 'Open file?',
					buttons: [{
						text: 'Cancel',
						role: 'cancel',
						cssClass: 'secondary',
						handler: () => {
						  //console.log('Confirm Cancel: blah');
						}
					}, {
						text: 'OK',
						handler: () => {
							if (rev != '') {
								const filetype = rev.path.substring(rev.path.lastIndexOf('.') + 1);
								if (filetype == 'pdf') {
									const urlDownload = encodeURIComponent('/api/document/downloadbytoken?' + params2url.join('&'));
									window.open('assets/js/pdfjs/web/viewer.php?xy=1&xx=0&file=' + urlDownload);
								} else {
									window.open('/api/document/downloadbytoken?' + params2url.join('&'));
								}
							} else {
								window.open('/api/document/downloadbytoken?' + params2url.join('&'));
							}
						}
					}]
				}).then((alert) => {
					alert.present();
				});
			},
			error: err => {
			},
			complete: () => {

			}
		});
	}

	public findshortcuts(params: any): any {
		return this.httpRequest('findshortcuts', params);
	}

	public getalldocumenttypes(params: any): any {
		return this.httpRequest('getalldocumenttypes', params);
	}

	public findGeneric(method: string, params: any): any {
		return this.httpRequest(method, params);
	}

	private httpRequest(method: string, params: any): Observable<any> {
    	return this.http
			.post(this.configService.getVar('server_url')  + '/document/' + method, params)
			.pipe(retry({
				count: this.configService.getHttp().retry,
				delay:this.shouldRetry
			}));
	}
	shouldRetry(error: any) {
		// Example for catching specific error code as well
		console.log(error);
		if (error.status === 422) {
				throw error;
		}
		return timer(10);
		
	}
}
