import { Component, OnInit } from '@angular/core';
import { FormBuilder, Validators, FormGroup } from '@angular/forms';

import { NavController, NavParams, ModalController } from '@ionic/angular'; // Modal ou Popover
import { TranslateService } from '@ngx-translate/core';

import { UserService } from './../../services/user-service';
import { AccountService } from './../../services/account-service';
import { ConfigService } from './../../services/config-service';
import { StringUtils } from '../../utils/string-utils';
import { Loading } from './../../controllers/message-controller';
import { ConsoleService } from '../../services/console/console-service';
import { WebStorageService } from '../../services/webstorage-service';


@Component({
  selector: 'app-page-account-form',
  templateUrl: 'account-form.html',
  providers: [UserService, ConfigService, Loading],
  styleUrls: ['account-form.scss']
})

export class AccountFormPage implements OnInit {

  public formGroup: FormGroup;
  public formSubmited = false;
  public formErrors: object = {};
  public platform_cordova = '0';

  constructor(
    public navCtrl: NavController,
    public navParams: NavParams,
    public modalController: ModalController,
    public loading: Loading,
    private _formBuilder: FormBuilder,
    private _localStorageService: WebStorageService,
    private _userService: UserService,
    private _accountService: AccountService,
    private _translateService: TranslateService,
    private _configService: ConfigService,
    private _consoleService: ConsoleService
  ) {
  }

  onSubmit(): void {
    this.save();
  }

  /**
   * Save account
   */
  save() {
    this._consoleService.log('AccountFormPage', 'save', 'formGroup', this.formGroup);

    if (false === this.formGroup.valid) {
      return;
    }

    if (true === this._accountService.existsAccount(this.formGroup.value.server_url, this.formGroup.value.username)) {
			this._translateService.get('Account already exists').subscribe(
				value => {
					this.formErrors["password"] = value;
				}
      );
      return;
    }

    this.loading.show({});

    this.formErrors = {};
    this.formSubmited = true;

    let credencials = {
      server_url: this.formGroup.value.server_url.replace(/\/{1,}$/,''),
      username: this.formGroup.value.username,
      password: StringUtils.base64_encode(this.formGroup.value.password)
    };
    this._userService.accountLogin(credencials).subscribe({
      next:(res: any) => {
        this.formSubmited = false;

        //res = res.json();
        res.server_url = this.formGroup.value.server_url.replace(/\/{1,}$/,'');
        res.username = this.formGroup.value.username;

        this._accountService.setAccount(res);
        this.loading.dismiss();
        this.modalController.dismiss();
      },
      error:(err) => {
        this.loading.dismiss();
        this.formSubmited = false;

        if (3 === err.type || err.status === 503 || err.status === 404) {
          this._translateService.get('Server unreachable').subscribe(
            value => {
              this.formErrors['password'] = value;
            }
          );
        }

        for (let i = 0; i < err.length; i++) {
          this.formErrors[err[i].field] = err[i].message;
        }

        if ('{}' === JSON.stringify(this.formErrors)) {
          this.formErrors['password'] = (err.type === undefined) ?
           'Authentication failed ( status: ' + err.status + ')' :
           'Authentication failed (type: ' + err.type + ',  status: ' + err.status + ')';
        }
        this._consoleService.log('AccountFormPage', 'save', 'err', err);
      },
      complete: () => {
        this.loading.dismiss();
        this.formSubmited = false;
        }
  });
  }

  dismiss() {
    this.modalController.dismiss();
  }

  ngOnInit() {
    this.formGroup = this._formBuilder.group({
      server_url: [null, [Validators.required, Validators.pattern('^(http:\/\/|https:\/\/|http:\/\/|https:\/\/)[a-z0-9]+([\-\.]{1}[a-z0-9]+)*\.[a-z]{2,5}(:[0-9]{1,5})?(\/.*)?$')]],
      username: [null, [Validators.required]],
      password: [null, [Validators.required]]
    });

    this.platform_cordova = this._localStorageService.get('platform_cordova');
    if ("0" === this.platform_cordova) {
      this.formGroup.patchValue({
        server_url: this._configService.getServerurl()
      });
    }
  }
}
