import {Component} from '@angular/core';
import { AlertController } from '@ionic/angular';
import { Events } from '../../services/event-service';

import {MarkPage} from '../mark/mark';
import {ActionSchedulePage} from '../action-schedule/action-schedule';

import {TranslateService} from '@ngx-translate/core';


@Component({
	templateUrl: 'document.html',
	styleUrls: ['document.scss']
})

export class Document {
	pages: Array<{title: string; component: any}> = [];

	constructor(public alertCtrl: AlertController, public events: Events, public translateService: TranslateService) {
		this.pages.push({title: 'Tag', component: MarkPage});
		this.pages.push({title: 'Schedule', component: ActionSchedulePage});
	}

	setPages(page: string): void {
		this.pages = [];
		this.pages.push({title: 'Schedule', component: ActionSchedulePage});

		if ("ActionPage" === page) {
			this.pages = [];
			this.pages.push({title: 'Tag', component: MarkPage});
			this.pages.push({title: 'Schedule', component: ActionSchedulePage});
		}
	}

	async more(document: any, page: string) {
		if (document[0]) {
			document[0] = {};
		}
		document.pageaction = "actionFind";

		this.setPages(page);

		let translation = {title: '', cancel: '', ok: '', page: ''};
		this.translateService.get('More').subscribe(
			value => {
				translation.title = value;
			}
		);
		this.translateService.get('Cancel').subscribe(
			value => {
				translation.cancel = value;
			}
		);
		this.translateService.get('ok').subscribe(
			value => {
				translation.ok = value;
			}
		);

		let arr;
		for (let p of this.pages) {
			translation.page = p.title;
			this.translateService.get(p.title).subscribe(
				value => {
					translation.page = value;
				}
			)

			const input = {
				type: 'radio',
				label: translation.page,
				value: p.component
			};
			arr.push(input);
		}

		const button = [translation.cancel,{
			text: translation.ok.toUpperCase(),
			handler: data => {
				this.events.publish('doc:pushPage', {component: data, item: document});
			}
		}];
		const popup = await this.alertCtrl.create({
			header:translation.title,
			inputs:arr,
			buttons:button

		});
		await popup.present();
	}
}
