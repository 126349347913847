import { Injectable } from '@angular/core';

import { Observable } from 'rxjs';
import { retry } from 'rxjs/operators';
import { JwtHelperService } from '@auth0/angular-jwt';
import { HttpClient } from '@angular/common/http';

import { ConfigService } from './config-service';

@Injectable({
	providedIn: 'root',
  })
export class ActionService {

	constructor(
		public configService: ConfigService,
		public jwtHttp: JwtHelperService,
		public http: HttpClient
	) {
  	}

	/**
	 * Find actions
	 */
	public find(params) {
		//console.log('find');
		//console.log(params);
		return this.httpRequest('find', params);
	}

	/**
	 * Find documentactions
	 */
	 public findactionsdocument(params) {
		return this.httpRequest('findactionsdocument', params);
	}

	/**
	 * Get chronological scheme
	 */
	public chronologicalscheme(params) {
		return this.httpRequest('chronologicalscheme', params);
	}

	public schedule(params) {
		return this.httpRequest('schedule', params);
	}

	public saveSchedule(params) {
		return this.httpRequest('saveschedule', params);
	}

	public execute(params) {
		return this.httpRequest('execute', params);
	}

	public actionForm(params) {
		return this.httpRequest('form', params);
	}

	public findactionfiltercomponents(params) {
		return this.httpRequest('findactionfiltercomponents', params);
	}

	public findactionfilters(params) {
		return this.httpRequest('findactionfilters', params);
	}

	public saveactionfilter(params) {
		return this.httpRequest('saveactionfilter', params);
	}

	public saveactionfilterorder(params: any): any {
		return this.httpRequest('saveactionfilterorder', params);
	}

	public removeactionfilter(params) {
		return this.httpRequest('removeactionfilter', params);
	}

	public markunread(params) {
		return this.httpRequest('markunread', params);
	}

	public savecomment(params) {
		return this.httpRequest('savecomment', params);
	}

	public removecomment(params) {
		return this.httpRequest('removecomment', params);
	}

	public savenote(params) {
		return this.httpRequest('savenote', params);
	}

	public removenote(params) {
		return this.httpRequest('removenote', params);
	}

	public savedatareact(params) {
		return this.httpRequest('savedatareact', params);
	}

	public findallpriorityflags(params) {
		return this.httpRequest('findallpriorityflags', params);
	}

	public findGeneric(method: string, params: any): any {
		return this.httpRequest(method, params);
	}

	private httpRequest(method, params): Observable<any> {
		return this.http
		.post(this.configService.getVar('server_url')  + '/action/' + method, params)
		.pipe(retry(this.configService.getHttp().retry));
	}
}
