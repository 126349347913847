import { Model } from './Model';
import { User } from './User';

export class Account extends Model {
  server_url: string = null;
  username: string = null;
  checked = false;
  refresh_token: string = null;
  access_token: string = null;
  user: string = null;
  usr: User;
  link: string = null;
  id = 0;
  name: string = null;
  constructor(
    data?: Account,
    urlCheck?: string,
    idCheck?: number
    ) {
    super();
    if (data) {
      super.deserialize(data);
      this.server_url = this['server_url'];
      this.username = this['__app_username'];
      this.id = this['__app_id'];
      this.name = this['__app_name'];
      this.refresh_token = this['refresh_token'];
      this.access_token = this['access_token'];
      this.usr = new User(JSON.parse(this['user']));
      this.link = this['__app_link'];

      this.checked = false;
      if (
        this['__app_server_url'] === urlCheck
        && this['__app_id'] === idCheck
      ) {
        this.checked = true;
      }
      //console.log(this['__app_server_url'] + ' === ' + localStorageService.get('server_url', '__app') + ' && ' + this['__app_username'] + ' === ' + localStorageService.get('username', '__app'));
      //console.log(this);

      this.cleanAttrs();
    }
  }

  public getUser(): User {
    return this.usr;
  }

  public getServerurl(): string {
    return this.getAttr('server_url');
  }

  public setRefreshtoken(token: string): void {
    this.refresh_token = token;
  }

  public setAccesstoken(token: string): void {
    this.access_token = token;
  }

  public isChecked(): boolean {
    return this.checked;
  }

  public activate(): Array<any> {
    this.username = this.getUser().getUsername();
    this.id = this.getUser().getId();
    this.link = this.getUser().getLink();
    const arr = [
      this.getServerurl(),
      this.getAttr('refresh_token'),
      this.getAttr('access_token'),
      this.getAttr('username'),
      this.getAttr('id'),
      this.getAttr('link'),
      JSON.stringify(this.getUser()),
      this.getServerurl()
    ];
    return arr;
    //localStorage.setItem('server_url', this.getServerurl(), '__app');
    //localStorage.setItem('refresh_token', this.getAttr('refresh_token'), '__app');
    //localStorage.setItem('access_token', this.getAttr('access_token'), '__app');
    //localStorage.setItem('username', this.getAttr('username'), '__app');
    //localStorage.setItem('id', this.getAttr('id'), '__app');
    //localStorage.setItem('link', this.getAttr('link'), '__app');
    //localStorage.setItem('user', JSON.stringify(this.getUser()));
    //localStorage.setItem('server_url', this.getServerurl());
  }

  /**
   * Remove invalid attributes
   */
  private cleanAttrs(): void {
    this.deleteAttr('platform_cordova');
    this.deleteAttr('__app_platform_cordova');
  }
}
