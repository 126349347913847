import {Component, OnInit} from '@angular/core';
import {NavController, NavParams, ModalController} from '@ionic/angular';

@Component({
	selector: 'app-page-user-comment',
	templateUrl: 'user-comment.html',
	styleUrls: ['user-comment.scss']
})
export class UserCommentPage implements OnInit {
	usercomment: any = {idcomment: 0, comment: '', show_in_all_actions: false};

	constructor(public navCtrl: NavController, public navParams: NavParams, public viewCtrl: ModalController) {}

	ngOnInit(): void {
		//console.log('yoyoyyo');
		//console.log(this.navParams);
		this.usercomment = Object.assign(this.usercomment, this.navParams.data.comment);

		if (1 == this.usercomment.show_in_all_actions) {
			this.usercomment.show_in_all_actions = true;
		} else {
			this.usercomment.show_in_all_actions = false;
		}
	}

	save() {
		this.dismiss({comment: this.usercomment});
	}

	dismiss(params: any) {
		this.viewCtrl.dismiss(params);
	}

	btnDismiss(): void {
		this.dismiss({});
	}

	btnDisabled(): boolean {
		if ('' === this.usercomment.comment) {
			return true;
		}
		return false;
	}
}
