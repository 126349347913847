import { Component } from '@angular/core';
import { NavParams } from '@ionic/angular';

import { Toast } from '../../controllers/message-controller';
import { ActionService } from '../../services/action-service';
import { DocumentService } from '../../services/document-service';
import { UserService } from '../../services/user-service';
import { FileService } from '../../services/file/file-service';
import { StorageUtils } from '../../utils/storage-utils';

@Component({
  selector: 'app-page-action-schedule',
  templateUrl: 'action-schedule.html',
  providers: [ActionService, DocumentService, StorageUtils],
  styleUrls: ['action-schedule.scss']
})
export class ActionSchedulePage {

	action: any = {mindatareact: '', maxdatareact: '', datareact: '', pendente: false, pendenteyesno: '', note: ''};
	document = {tituloformated: ''};

	finding = false;

	constructor(	public navParams: NavParams,
					public actionService: ActionService,
					public storageUtils: StorageUtils,
					public documentService: DocumentService,
					public userService: UserService,
					public fileService: FileService,
					public toast: Toast) {

		this.action.mindatareact = (new Date()).toISOString().slice(0, 10);
		this.action.maxdatareact = (new Date(new Date().setFullYear(new Date().getFullYear() + 10))).toISOString().slice(0, 10);

		//console.log('ionViewDidLoad ActionSchedulePage');

		if (this.navParams.get('params')) {
			let params = this.navParams.get('params');

			if (params.action != undefined) {
				this.action = params.action

				if(params.action.document != undefined) {
					this.document = params.action.document
				}

			}
		}


		this.finding = true;

		this.actionService.schedule(this.action).subscribe({
			next: (data: any) => {
				this.finding = false;
				this.action = data;

				if (this.action.pendente) {
					if (this.action.datareact) {
						this.action.datareact = this.action.datareact.slice(0, 10);
					} else {
						this.action.datareact = (new Date()).toISOString().slice(0, 10);
					}
				} else {
					this.action.datareact = (new Date()).toISOString().slice(0, 10);
				}

				if (!this.action.note) {
					this.action.note = '';
				}

				this.action.mindatareact = (new Date()).toISOString().slice(0, 10);
				this.action.maxdatareact = (new Date(new Date().setFullYear(new Date().getFullYear() + 10))).toISOString().slice(0, 10);

				this.action.note = this.action.note + '';
				if ("null" == this.action.note) {
					this.action.note = '';
				}

			},
			error: err => {
			},
			complete: () => {
				this.finding = false;
			}
		});

	}

	ionViewWillLeave() {
		this.save();
	}

	save(): void {

		if (!this.action.pendente) {
			this.action.note = '';
		}

		this.actionService.saveSchedule(this.action).subscribe(
			data => {
			},
			err => {
				if (this.action.pendente) {
					this.toast.show({message: 'An error ocurred while scheduling action', duration: 3000, cssClass: 'error', dismissOnPageChange: false});
				} else {
					this.toast.show({message: 'An error ocurred while unscheduling action', duration: 3000, cssClass: 'error', dismissOnPageChange: false});
				}

			},
			() => {
			}
		);
	}

	download(item: any): any {

		let user = this.userService.getUser();
		if ("0" == StorageUtils.getItem("platform_cordova")) {
			this.documentService.downloadbytoken({iddoc: item.iddoc, chronschemasort: user.defaultchronschemasort});

		} else {
			item = Object.assign(item, {
				emltopdf: true,
				chronschemasort: user.defaultchronschemasort
			});
			this.fileService.download(item);
		}
	}
}

