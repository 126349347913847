export class Model {
  constructor() { }

   public setAttr(attr: string, value: any): void {
    this[attr] = value;
  }

  protected deserialize(data, type: string = 'assign') {
    let keys = [];

    if ('assign' === type) {
      keys = Object.keys(data);
    } else {
      keys = Object.keys(this);
    }

    //this = Object.assign(this, data);
    for (const key of keys) {
      if (data[key]) {
        this[key] = data[key];
      }
    }
  }

  protected getAttr(attr: string): any {
    if (this[attr]) {
      return this[attr];
    }

    return null;
  }

  protected deleteAttr(attr: string): any {
    if (this[attr]) {
      delete this[attr];
    }
  }
}
