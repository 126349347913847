import { Model } from './Model';

export class User extends Model {
  access_token = null;
  username: string = null;
  name: string = null;
  link: string = null;
  avatar = '0';
  id = 0;

  constructor(data?: User) {
    super();

    if (data) {
      super.deserialize(data);
    }
  }

  public getUsername(): string {
    return this.username;
  }

  public getAccountname(): string {
    return this.name;
  }

  public getId(): number {
    return this.id;
  }

  public getLink(): string {
    return this.link;
  }
}
