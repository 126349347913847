import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import { Observable, retry } from 'rxjs';
import { JwtHelperService } from '@auth0/angular-jwt';

import { ConfigService } from './config-service';


@Injectable({
	providedIn: 'root',
  })
export class FolderService {

	constructor(
		public configService: ConfigService,
		public jwtHttp: JwtHelperService,
		public http: HttpClient
	) {
	}

	/**
	 * Find folders to link documentos
	 */
	public find2link(params) {
		return this.httpRequest('find2link', params);
	}

	/**
	 * link documents to folders
	 */

	public link(params) {
		return this.httpRequest('link', params);
	}

	private httpRequest(method, params): Observable<any> {
    	return this.http
			.post(this.configService.getVar('server_url')  + '/folder/' + method, params)
			.pipe(retry(this.configService.getHttp().retry));
	}
}
